//Constants
import InventoryEndPoints from "./constants/inventory.end-points";
import SharedEndPoints from "./constants/shared.end-points";

//Infrastructures
import Http from "./infrastructure/http.infrastructure";

//Libraries
import dayjs from 'dayjs';

//Services
import { getTokenService } from "./authentication.services"

//Utils
import { fileDownloadGenerator } from "../utils/file-download-generator.utils";

export async function getDownloadProductTemplateService() {
    const token = getTokenService();
    try {
        const { data: responseGetDownloadProductTemplate, error } = await Http.get(InventoryEndPoints.GET_DOWNLOAD_PRODUCT_TEMPLATE, null, token, true)
        if (responseGetDownloadProductTemplate && responseGetDownloadProductTemplate instanceof Blob) {
            fileDownloadGenerator(responseGetDownloadProductTemplate, "COSTOS.xlsx")
            return true;
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getDownloadInventoryTemplateService() {
    const token = getTokenService()
    try {
        const { data: responseGetDownloadInventoryTemplate, error } = await Http.get(InventoryEndPoints.GET_DOWNLOAD_INVENTORY_TEMPLATE, null, token, true)
        if (responseGetDownloadInventoryTemplate && responseGetDownloadInventoryTemplate instanceof Blob) {
            fileDownloadGenerator(responseGetDownloadInventoryTemplate, "INVENTARIO.xlsx")
            return true;
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function extractProductsService(excel,date) {
    const token = getTokenService()
    try {
        const { data: responseExtractProducts, error } = await Http.post(InventoryEndPoints.VALIDATE_PRODUCT_FILE, { excel, date }, token, true)
        if (responseExtractProducts && responseExtractProducts.status) {
            return responseExtractProducts.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function extractProductsByStoreService(excel, date) {
    const token = getTokenService()
    try {
        const { data: responseExtractProductsByStore, error } = await Http.post(InventoryEndPoints.VALIDATE_WAREHOUSE_FILE, { excel, date }, token, true)
        if (responseExtractProductsByStore && responseExtractProductsByStore.status) {
            return responseExtractProductsByStore.status;
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getHeadQuartersService(date) {
    try {
        const token = getTokenService()
        const { data: responseGetHeadQuarters, error } = await Http.get(InventoryEndPoints.GET_HEAD_QUARTERS, { date }, token)
        if (responseGetHeadQuarters && responseGetHeadQuarters.status) {
            return responseGetHeadQuarters.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getDownloadInventoryService() {
    const token = getTokenService()
    try {
        const { data: responseGetDownloadInventory, error } = await Http.get(InventoryEndPoints.GET_DOWNLOAD_INVENTORY, null, token, true)
        if (responseGetDownloadInventory && responseGetDownloadInventory instanceof Blob) {
            fileDownloadGenerator(responseGetDownloadInventory, "INVENTARIO_FILE.xlsx")
            return true;
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createTransfersService(data) {
    try {
        const token = getTokenService()
        const { data: responseCreateTransfer, error } = await Http.post(InventoryEndPoints.CREATE_TRANSFER_EXTRACT, {...data} , token, true)
        if (responseCreateTransfer && (responseCreateTransfer.data || responseCreateTransfer.status)) {
            return (responseCreateTransfer.data || responseCreateTransfer.status)
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getExcelTransfersService(data) {
    try {
        const token = getTokenService()
        const { data: responseGetTransfer, error } = await Http.get(InventoryEndPoints.GET_TRANSFER_EXTRACT, data , token)
        if (responseGetTransfer && responseGetTransfer.data) {
            return responseGetTransfer?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getDownloadTransferService() {
    try {
        const token = getTokenService()
        const { data: responseGetDownloadTransfer, error } = await Http.get(InventoryEndPoints.GET_DOWNLOAD_TRANSFER, null, token, true)
        if (responseGetDownloadTransfer && responseGetDownloadTransfer instanceof Blob) {
            fileDownloadGenerator(responseGetDownloadTransfer, "TRASLADOS.xlsx")
            return true;
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getDownloadTemplateTransferService() {
    try {
        const token = getTokenService()
        const { data: responseGetDownloadTransfer, error } = await Http.get(InventoryEndPoints.GET_DOWNLOAD_TEMPLATE_TRANSFER, null, token, true)
        if (responseGetDownloadTransfer && responseGetDownloadTransfer instanceof Blob) {
            fileDownloadGenerator(responseGetDownloadTransfer, "TRASLADOS_TEMPLATE.xlsx")
            return true;
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function extractSalesReportsCloudService(body) {
    const token = getTokenService()
    try {
        const { data: responseExtractSalesReportsCloud, error } = await Http.post(InventoryEndPoints.VALIDATE_SALES_REPORTS_CLOUD, body, token, true)
        if (responseExtractSalesReportsCloud && (responseExtractSalesReportsCloud?.data || responseExtractSalesReportsCloud?.status)) {
            if (responseExtractSalesReportsCloud?.data) {
                return responseExtractSalesReportsCloud?.data?.unregisteredPrefixes?.map(namePrefix => ({ namePrefix })) || [];
            } else if(responseExtractSalesReportsCloud?.data === null) {
                return responseExtractSalesReportsCloud?.data
            }else{
                return responseExtractSalesReportsCloud?.status
            }
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function extractUtilityCloudService(body) {
    const token = getTokenService()
    try {
        const { data: responseExtractUtilityCloud, error } = await Http.post(InventoryEndPoints.VALIDATE_UTILITIES_CLOUD, body, token, true)
        if (responseExtractUtilityCloud && (responseExtractUtilityCloud?.data || responseExtractUtilityCloud?.status)) {
            if (responseExtractUtilityCloud?.data) {
                return responseExtractUtilityCloud?.data?.unregisteredPrefixes?.map(namePrefix => ({ namePrefix })) || [];
            } else if(responseExtractUtilityCloud?.data === null) {
                return responseExtractUtilityCloud?.data
            }else{
                return responseExtractUtilityCloud?.status
            }
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function extractUtilityColdPointService(body) {
    const token = getTokenService()
    try {
        const { data: responseExtractUtilityColdPoint, error } = await Http.post(InventoryEndPoints.VALIDATE_UTILITIES_COLD_POINT, body, token, true)
        if (responseExtractUtilityColdPoint && (responseExtractUtilityColdPoint?.data || responseExtractUtilityColdPoint?.status)) {
            if (responseExtractUtilityColdPoint?.data) {
                return responseExtractUtilityColdPoint?.data?.unregisteredPrefixes?.map(namePrefix => ({ namePrefix })) || [];
            } else if(responseExtractUtilityColdPoint?.data === null) {
                return responseExtractUtilityColdPoint?.data
            }else{
                return responseExtractUtilityColdPoint?.status
            }
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getReportsValuationService(body) {
    const token = getTokenService()
    try {
        const { data: responseGetReportsValuation, error } = await Http.get(InventoryEndPoints.GET_REPORTS_VALUATIONS, body, token)
        if (responseGetReportsValuation && responseGetReportsValuation?.status) {
            return responseGetReportsValuation.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getDownloadUtilitiesService() {
    try {
        const token = getTokenService()
        const { data: responseGetDownloadUtilities, error } = await Http.get(InventoryEndPoints.GET_DOWNLOAD_UTILITIES, null, token, true)
        if (responseGetDownloadUtilities && responseGetDownloadUtilities instanceof Blob) {
            fileDownloadGenerator(responseGetDownloadUtilities, "UTILIDADES.xlsx")
            return true;
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getSalesReportsService() {
    try {
        const token = getTokenService()
        const { data: responseGetDownloadUtilities, error } = await Http.get(InventoryEndPoints.GET_SALES_REPORTS, null, token, true)
        if (responseGetDownloadUtilities && responseGetDownloadUtilities instanceof Blob) {
            fileDownloadGenerator(responseGetDownloadUtilities, "ReportesDiferencialIva.xlsx")
            return true;
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getInvoicingProfitService() {
    try {
        const token = getTokenService()
        const { data: responseGetDownloadUtilities, error } = await Http.get(InventoryEndPoints.GET_INVOICING_PROFIT, null, token, true)
        if (responseGetDownloadUtilities && responseGetDownloadUtilities instanceof Blob) {
            fileDownloadGenerator(responseGetDownloadUtilities, "UtilidadporFacturación.xlsx")
            return true;
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getUtilityReportsPFService() {
    try {
        const token = getTokenService()
        const { data: responseGetDownloadUtilities, error } = await Http.get(InventoryEndPoints.GET_UTILITY_REPORTS_PF, null, token, true)
        if (responseGetDownloadUtilities && responseGetDownloadUtilities instanceof Blob) {
            fileDownloadGenerator(responseGetDownloadUtilities, "ReportesdeUtilidadPF.xlsx")
            return true;
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getDatesInventoryService() {
    try {
        const { data: responseListDate, error } = await Http.get(SharedEndPoints.GET_DATES_INVENTORY)
        if (responseListDate && responseListDate.status) {
            return responseListDate.data.map(list => ({ value: dayjs(list.date).utc().format("YYYY-MM-DD"), label: dayjs(list.date).utc().format("YYYY-MM-DD") }))
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getDatesTransferService() {
    try {
        const { data: responseListDate, error } = await Http.get(SharedEndPoints.GET_DATES_TRANSFER)
        if (responseListDate && responseListDate.status) {
            return responseListDate.data.map(list => ({ 
                value: dayjs(list.date).utc().format("YYYY-MM-DD"), 
                label: dayjs(list.date).utc().format("YYYY-MM-DD") 
            }))
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getDatesUtilitiesService() {
    try {
        const { data: responseListDate, error } = await Http.get(SharedEndPoints.GET_DATES_UTILITIES)
        if (responseListDate && responseListDate.status) {
            return responseListDate.data.map(list => ({ 
                value: dayjs(list.date).utc().format("YYYY-MM-DD"), 
                label: dayjs(list.date).utc().format("YYYY-MM-DD") 
            }))
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};