// Components
import { WalletOplTableComponent } from '../wallet-opl-table/wallet-opl-table.component';

// Libraries
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

// Services
import { getWalletOplService } from '../../../../services/wallet-opl.services';

// Styles
import "./other-wallet-opl.component.scss";

export const OtherWalletOplComponent = () => {
    const { id } = useParams();
    const INITIAL_STATE = {
        listOtherOpl: []
    }
    const [state, setState] = useState(INITIAL_STATE)

    const { listOtherOpl } = state

    useEffect(() => {
        getTableOtherOpl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getTableOtherOpl = async () => {
        try {
            const resp = await getWalletOplService(id)
            setState({ ...state, listOtherOpl: resp })
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    }

    return (
        <div className="other-wallet-opl__content">
            {listOtherOpl.map((other,index)=>{
                return(
                    <WalletOplTableComponent
                        key={index}
                        data={other.data}
                        name={other.name}
                        idExpenseSubcategory={other.id}
                        getTableOtherOpl={()=>getTableOtherOpl()}
                    />
                )
            })}
        </div>
    )
};
