// Libraries
import * as Yup from 'yup';

export const headquarterSchema = Yup.object().shape({
    name: Yup.string().trim()
        .required('Requerido'),
    initialBalance: Yup.number()
        .nullable(),
    address: Yup.string().trim()
        .min(2, 'Debe tener al menos dos caracteres')
        .required('Requerido'),
    store: Yup.array()
        .min(1, 'Debe haber al menos una bodega asignada')
        .of(Yup.string().trim().min(2, 'Cada bodega debe tener al menos dos caracteres'))
        .required('Requerido'),
    userChargeHeadQuarter: Yup.object()
        .required('Requerido'),
    managersSelected: Yup.array()
        .min(1, 'Debe haber al menos un responsable')
        .required('Requerido'),
    accounts: Yup.array(),
    prefix: Yup.array()
        .min(1, 'Debe haber al menos un prefijo asignado')
        .of(Yup.string().trim().min(2, 'Cada prefijo debe tener al menos dos caracteres'))
        .required('Requerido'),
})