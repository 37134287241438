// Assets
import { Assets } from "../../../assets";

// Components
import { ExpensesOplComponent } from "../components/expenses-opl/expenses-opl.component";
import { CreditOplComponent } from "../components/credit-opl/credit-opl.component";
import { OtherOplComponent } from "../components/other-opl/other-opl.component";
import { GeneralAdjustmentOplComponent } from "../components/general-adjustment-opl/general-adjustment-opl.component";
import { ProvisionsOplComponent } from "../components/provisions-opl/provisions-opl.component";

// Components - Shared
import ErrorAlertComponent from "../../../shared/components/alerts/error-alert/error-alert.component";
import SuccessAlertComponent from "../../../shared/components/alerts/success-alert/success-alert.component";

// Libraries
import dayjs from 'dayjs';
import { DatePicker } from "antd";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

// Services
import { generalAdjustmentClosingService } from "../../../services/quotation-opl.services";

// Styles
import "./quotation-opl-details.page.scss";

export const QuotationOplDetailsPage = () => {

    let { state: { selectedMonth, opl } } = useLocation();

    const INITIAL_STATE = {
        hoverIndex: null,
        optionSelected: 0,
        isClosingGeneralAdjustmentState: false,
        selectedMonthSummary: dayjs(selectedMonth)
    };

    const [state, setState] = useState(INITIAL_STATE);

    const {
        hoverIndex,
        optionSelected,
        selectedMonthSummary,
        isClosingGeneralAdjustmentState
    } = state;

    const goToMonth = (amount) => {
        const newDate = dayjs(selectedMonthSummary).add(amount, 'month');
        const currentDate = dayjs();

        if (newDate.isBefore(currentDate, 'month') || newDate.isSame(currentDate, 'month')) {
            setState({
                ...state,
                selectedMonthSummary: newDate
            });
        }
    };

    const tabs = [
        {
            label: 'Cuadre General',
            iconAlt: { active: 'icon_summary', inactive: 'icon_summary_inactive', hover: 'icon_summary_active' },
            iconSrc: { active: Assets.SharedIcons.icon_summary, inactive: Assets.SharedIcons.icon_summary_inactive, hover: Assets.SharedIcons.icon_summary_active },
        },
        {
            label: 'Provisiones',
            iconAlt: { active: 'icon_summary', inactive: 'icon_summary_inactive', hover: 'icon_summary_active' },
            iconSrc: { active: Assets.SharedIcons.icon_summary, inactive: Assets.SharedIcons.icon_summary_inactive, hover: Assets.SharedIcons.icon_summary_active },
        },
        {
            label: 'Gastos',
            iconAlt: { active: 'icon_headquarters', inactive: 'icon_headquarters_inactive', hover: 'icon_headquarters_active' },
            iconSrc: { active: Assets.SharedIcons.icon_headquarters, inactive: Assets.SharedIcons.icon_headquarters_inactive, hover: Assets.SharedIcons.icon_headquarters_active },
        },
        {
            label: 'Créditos',
            iconAlt: { active: 'icon_projections_selected', inactive: 'icon_projections_inactive', hover: 'icon_projections_active' },
            iconSrc: { active: Assets.SharedIcons.icon_projections_selected, inactive: Assets.SharedIcons.icon_projections_inactive, hover: Assets.SharedIcons.icon_projections_active },
        },
        {
            label: 'Otros',
            iconAlt: { active: 'icon_projections_selected', inactive: 'icon_projections_inactive', hover: 'icon_projections_active' },
            iconSrc: { active: Assets.SharedIcons.icon_projections_selected, inactive: Assets.SharedIcons.icon_projections_inactive, hover: Assets.SharedIcons.icon_projections_active },
        }
    ];

    const validatePropsHoverTab = (index, typeIcon, typeAlt) => {
        let alt, src, typeText;
        
        if (hoverIndex === index) {
            alt = typeAlt.hover;
            src = typeIcon.hover;
            typeText = '__hover';
        } else if (optionSelected === index) {
            alt = typeAlt.active;
            src = typeIcon.active;
            typeText = '__active';
        } else {
            alt = typeAlt.inactive;
            src = typeIcon.inactive;
            typeText = '__inactive';
        }

        return { alt, src, typeText };
    };

    const validateData = (index) => {
        setState({ ...state, optionSelected: index })
    };

    const disabledDate = (current) => {
        return current && current.isAfter(dayjs(), 'month');
    };

    const generalAdjustmentClosing = async () => {
        try {
            await generalAdjustmentClosingService(opl?.id, dayjs(selectedMonthSummary)?.format("YYYY-MM-DD"));
            setState({ ...state, isClosingGeneralAdjustmentState: true });
            SuccessAlertComponent(undefined, "El cuadre ha finalizado exitosamente");
        } catch (error) {
            ErrorAlertComponent();
        }
    };

    return (
        <div className='quotation-opl-details__container'>
            <div className='grid-x align-middle justify-content-between quotation-opl-details__content-header'>
                <div className='grid-x align-middle'>
                    <Link
                        to="/oplAdjustment"
                        className="grid-x quotation-opl-details__content-header__link"
                    >
                        Sedes o Licoreras
                        <img
                            alt='icon_arrow'
                            src={Assets.SharedIcons.icon_arrow}
                            className='quotation-opl-details__content-header__link__icon'
                        />
                    </Link>
                    <span className='quotation-opl-details__content-header__link__name'>
                        {opl?.name}
                    </span>
                </div>
                <div className='grid-x align-middle'>
                    <span className='quotation-opl-details__content-header__date__text'>Seleccionar mes:</span>
                    <div className='grid-x align-middle quotation-opl-details__content-header__date__picker'>
                        <button onClick={() => goToMonth(-1)}>
                            <img
                                alt='icon_arrow'
                                src={Assets.SharedIcons.icon_arrow}
                                className='quotation-opl-details__content-header__date__picker__arrow'
                            />
                        </button>
                        <DatePicker
                            picker='month'
                            bordered={false}
                            suffixIcon={null}
                            allowClear={false}
                            inputReadOnly={true}
                            defaultValue={dayjs()}
                            disabledDate={disabledDate}
                            value={selectedMonthSummary}
                            format={(value) => {
                                return `${dayjs(value)?.format('MMMM, YYYY')}`;
                            }}
                            onChange={(date) => setState({ ...state, selectedMonthSummary: date })}
                            className='quotation-opl-details__content-header__date__picker__month'
                        />
                        <button
                            onClick={() => goToMonth(1)}
                            disabled={(dayjs(selectedMonthSummary).add(1, 'month').isAfter(dayjs(), 'month'))}
                            className='quotation-opl-details__content-header__date__picker__button-next'
                        >
                            <img
                                alt={(dayjs(selectedMonthSummary).add(1, 'month').isAfter(dayjs(), 'month')) ? 'icon_arrow_picker_disabled' : 'icon_arrow'}
                                src={(dayjs(selectedMonthSummary).add(1, 'month').isAfter(dayjs(), 'month')) ? Assets.SharedIcons.icon_arrow_picker_disabled : Assets.SharedIcons.icon_arrow}
                            />
                        </button>
                    </div>
                </div>
            </div>
            <div className='grid-x align-middle justify-content-between quotation-opl-details__content-options'>
                <div className='grid-x align-middle justify-content-between quotation-opl-details__content-options__content-buttons'>
                    {tabs.map((tab, index) => {
                        return (
                            <button
                                key={index}
                                onClick={() => validateData(index)}
                                className={`quotation-opl-details__content-options__buttons${optionSelected === index ? '__active' : ''}`}
                            >
                                <img
                                    alt={validatePropsHoverTab(index, tab.iconSrc, tab.iconAlt).alt}
                                    src={validatePropsHoverTab(index, tab.iconSrc, tab.iconAlt).src}
                                    className='quotation-opl-details__content-options__icon-button'
                                />
                                <span className={`quotation-opl-details__content-options__buttons__text${validatePropsHoverTab(index, tab.iconSrc, tab.iconAlt).typeText}`}>
                                    {tab.label}
                                </span>
                            </button>
                        )
                    })}
                </div>
                {optionSelected === 0 &&
                    <button
                        onClick={() => generalAdjustmentClosing()}
                        className='quotation-opl-details__content-options__button-publish'
                    >
                        <img
                            alt='icon_save_disabled'
                            src={Assets.SharedIcons.icon_save_disabled}
                            className='quotation-opl-details__content-options__icon-button'
                        />
                        <span>Finalizar cierre</span>
                    </button>
                }
            </div>
            <div className='quotation-opl-details__content-info'>
                {optionSelected === 0 && 
                    <GeneralAdjustmentOplComponent
                        selectedMonthSummaryProp={selectedMonthSummary}
                        isClosingGeneralAdjustmentProp={isClosingGeneralAdjustmentState}
                        setIsClosingGeneralAdjustmentProp={() => setState({ ...state, isClosingGeneralAdjustmentState: false })}
                    />
                }
                {optionSelected === 1 && <ProvisionsOplComponent selectedMonthSummaryProp={selectedMonthSummary} />}
                {optionSelected === 2 && <ExpensesOplComponent selectedMonthSummaryProp={selectedMonthSummary} />}
                {optionSelected === 3 && <CreditOplComponent selectedMonthSummaryProp={selectedMonthSummary} />}
                {optionSelected === 4 && <OtherOplComponent selectedMonthSummaryProp={selectedMonthSummary} />}
            </div>
        </div>
    )
};
