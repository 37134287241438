// Assets
import { Assets } from "../../../../assets";

//components - Shared
import SuccessAlertComponent from "../../../../shared/components/alerts/success-alert/success-alert.component";
import InformationAlertComponent from "../../../../shared/components/alerts/information-alert/information-alert.component";

// Libraries
import dayjs from 'dayjs';
import { useLocation } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import React, { useEffect, useRef, useState } from "react";
import { DatePicker, Input, AutoComplete } from "antd";

// Services
import { 
    createCreditOplService, deleteQuotationService, getEmployeeCreditOpl,
    getOplCreditService,  getTypeConceptCreditOpl, updateQuotationService 
} from "../../../../services/quotation-opl.services";

// Styles
import "./credit-opl.component.scss";

// Utils
import { dataDefaultECreditsOpl } from "../../../../utils/quotation-opl.utils";

export const CreditOplComponent = ({ selectedMonthSummaryProp }) => {
    
    const { state: locationState } = useLocation();

    const INITIAL_STATE = {
        scrollEnd: false,
        expenses: dataDefaultECreditsOpl,
        expensesOriginal: dataDefaultECreditsOpl,
        optionsConcept: [],
        optionsEmployee : [],
        searchTable: ""
    };

    const [state, setState] = useState(INITIAL_STATE);

    const { expenses, scrollEnd, optionsConcept, optionsEmployee, expensesOriginal, searchTable } = state;

    const formRef = useRef(null);

    useEffect(() => {
        if (formRef.current && scrollEnd) {
            const miDiv = formRef.current;
            miDiv.scrollTop = miDiv.scrollHeight;
        }
    }, [scrollEnd, expenses.length]);

    useEffect(() => {
        if (selectedMonthSummaryProp) {
            getTablesCredits();
            formRef.current.scrollTop = 0;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMonthSummaryProp]);

   const getTablesCredits = async () => {
    try {
        const respService = await getOplCreditService(locationState?.opl?.id, dayjs(selectedMonthSummaryProp)?.format("YYYY-MM"));
        const updatedExpenses = await Promise.all(
            respService.map(async (data) => {
                return {
                    id: data.id,
                    type: data.typeConcept,
                    date: data.date,
                    price: data.price,
                    employee: data.employee,
                    concept: data.concept,
                    disabled: false,
                    isEdit: false,
                };
            })
        );

        const updatedExpensesWithMissingData = INITIAL_STATE.expenses.map((expense, index) => {
            if (index < updatedExpenses.length) {
                return updatedExpenses[index];
            }
            return expense;
        });

        setState((prevState) => ({
            ...prevState,
            expenses: updatedExpensesWithMissingData,
            expensesOriginal: updatedExpensesWithMissingData,
            scrollEnd: false,
            searchTable: ""
        }));
        }catch (error) {
            // TODO: Implement error alert with code error.
        }
    }


  const handleTotal = (data) => {
        let totalPrice = 0;
        data.forEach(object => {
            totalPrice += (parseFloat(object.price) ? parseFloat(object.price) : 0);
        });
        totalPrice = parseFloat(totalPrice.toFixed(2));
        return totalPrice;
    };  

    const handleChange = async (e, index, name) => {
        let value = e && e.target ? e.target.value || '' : e;
        const newArray = JSON.parse(JSON.stringify(expenses));
        let newState={
            ...state, 
            expenses: newArray
        }
        if (name === "type") {
            const options = await optionFilterType(value)
            newState.optionsConcept = options || [];
        }else {
            newArray[index][name] = value}

        if (name === "employee") { 
            const options = await optionFilterEmployee(value)
            newState.optionsEmployee = options || [];
        }else {
            newArray[index][name] = value}

        setState(newState);
    };



    const removeDataExpenses = async (item, index) => {

        try {
            await deleteQuotationService({ id: item.id });
            SuccessAlertComponent(undefined, "Gasto eliminado exitosamente");
            getTablesCredits();
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    };

    const addExpenses = () => {
        const objectInputDisabled = expenses.find(object => object.hasOwnProperty("disabled") && object.disabled === true);

        if (!objectInputDisabled) {
            const newArray = [
                ...expenses,
                {
                    type: "",
                    date: null,
                    employee: "",
                    concept: "",
                    price: "",
                    disabled: false,
                    isEdit: true
                }
            ];
            setState((prevState) => ({
                ...prevState,
                expenses: newArray,
                scrollEnd: true
            }))
        } else {
            const newArray = expenses.map(object => (object === objectInputDisabled ? { ...object, disabled: false, isEdit: true } : object));
            setState((prevState) => ({
                ...prevState,
                expenses: newArray
            }))
        }
    };
    
    const handleCreate = async (item, index) => {   
        try {
            const { type, date, employee, concept, price } = item;
            if ( !price || !concept || !date || !employee || !type ) return InformationAlertComponent(undefined, "Todos los campos son obligatorios");
            await createCreditOplService({
                typeConcept: item.type,
                date: item.date,
                employee: item.employee,
                concept: item.concept,
                price: item.price,
                idHeadQuarter: locationState?.opl?.id,
            });
            SuccessAlertComponent(undefined, "Gasto creado exitosamente");
            getTablesCredits();
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    };


    const handleEdit = async (item, index) => {
        try {
            const { type, date, employee, concept, price } = item;
            if ( !price || !concept || !date || !employee || !type ) return InformationAlertComponent(undefined, "Todos los campos son obligatorios");
            await updateQuotationService(
                item.id,
                {
                    typeConcept: item.type,
                    date: item.date,
                    employee: item.employee,
                    concept: item.concept,
                    price: item.price,
                    idHeadQuarter: locationState?.opl?.id,
                }
            );
            handleChange(false, index, 'isEdit');
            SuccessAlertComponent(undefined, "Gasto actualizado exitosamente");
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    }

    const handleResetItem = async (index,id) => {
        try {
            let copyExpenses = [...expenses];
            if (id) {
                const respService = await getOplCreditService(locationState?.opl?.id, dayjs(selectedMonthSummaryProp)?.format("YYYY-MM"));
                copyExpenses[index]={
                    id: respService[index].id,
                    type: respService[index].typeConcept,
                    date: respService[index].date,
                    employee: respService[index].employee,
                    price: respService[index].price,
                    concept: respService[index].concept,
                    disabled: false,
                    disabledSubCategory: false,
                    isEdit: false
                }
            } else {
                copyExpenses[index]={
                    type: "",
                    date: null,
                    price: "",
                    employee: "",
                    concept: "",
                    disabled: true,
                    disabledSubCategory: true,
                    listOfExpenseSubCategory: [],
                    isEdit: false
                }
            }
            setState((prevState) => ({
                ...prevState,
                expenses: copyExpenses
            }))
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    }
    const optionFilterType = async(filter) => {
        try {
            const listOfCreditsType = await getTypeConceptCreditOpl(filter)
            return listOfCreditsType || [];
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
        
    }
    const getFilterType = async (filter) => {
        try {
            const resp = await optionFilterType(filter);
            setState((prevState)=>({
                ...prevState,
                optionsConcept: resp
            }));
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    }

    const optionFilterEmployee = async(filter) => {
        try {
            const listOfEmployeeCreditOpl= await getEmployeeCreditOpl(filter)
            return listOfEmployeeCreditOpl || [];
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    }
    const getFilterEmployee = async (filter) => {
        try {
            const resp = await optionFilterEmployee(filter);
            setState((prevState)=>({
                ...prevState,
                optionsEmployee: resp
            }));
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    };

    const disabledDate = (current) => {
        return current && current.isAfter(dayjs(), 'day');
    };

    const handlePortfolioFilter = (filter = "") => {
        const filteredData = expensesOriginal?.filter((item) =>
            item?.employee?.toLowerCase().includes(filter?.toLowerCase())
        );

        const updatedExpenses = INITIAL_STATE.expenses.map((expense, index) => {
            if (index < filteredData.length) {
                return filteredData[index];
            }
            return expense;
        });

        setState((prevState) => ({
            ...prevState,
            expenses: updatedExpenses,
            searchTable: filter
        }))
    }

    return (
        <div className='small-6'>
            <div className='credit-opl__payments__container'>
                <div className='credit-opl__payments__content'>
                    <div className='grid-y'>
                        <div className='grid-x credit-opl__payments__content-titles__fist'>
                            <div className='grid-x align-middle small-6'>
                                <img src={Assets.SharedIcons.icon_information} alt="icon_information" />
                                <span className='credit-opl__payments__titles credit-opl__payments__titles__space'>Cartera</span>
                                <Input
                                    onChange={(e) => handlePortfolioFilter(e.target.value, expenses)}
                                    placeholder="Buscar por empleado"
                                    className="credit-opl__input-search"
                                    prefix={
                                        <img
                                            src={Assets.SharedIcons.icon_search_magnifier}
                                            alt="icon_search_magnifier"
                                        />
                                    }
                                    value={searchTable || ""}
                                />
                            </div>
                            <div className='grid-x align-middle small-6 justify-content-end'>
                                <span className='credit-opl__payments__titles'>Total:</span>
                                <div className='grid-x align-middle credit-opl__summary__details'>
                                    <div className='credit-opl__payments__table__content-icon-price'>
                                        <img
                                            alt="icon_income"
                                            src={Assets.SharedIcons.icon_income}
                                            className='credit-opl__summary__price__icon'
                                        />
                                    </div>
                                    <NumericFormat
                                        type='text'
                                        prefix={"$ "}
                                        disabled
                                        placeholder='$ 0'
                                        thousandSeparator=","
                                        className='input-price'
                                        value={handleTotal(expenses)}
                                        thousandsGroupStyle="thousand"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='grid-x credit-opl__payments__content-titles__second'>
                            <span className='grid-x credit-opl__payments__titles small-2'>Tipo</span>
                            <span className='grid-x credit-opl__payments__titles small-2'>Fecha</span>
                            <span className='grid-x credit-opl__payments__titles credit-opl__payments__titles__space--mod small-2'>Empleado</span>
                            <span className='grid-x credit-opl__payments__titles credit-opl__payments__titles__space--mod small-3'>Concepto</span>
                            <span className='grid-x credit-opl__payments__titles credit-opl__payments__titles__space--mod small-3'>Valor</span>
                        </div>
                    </div>
                    <div ref={formRef} className='credit-opl__payments__info'>
                        {expenses?.map((item, index) => {

                            return (
                                <div key={index} className='grid-x justify-content-between credit-opl__payments__table'>
                                    <div className='grid-x small-2'>
                                        <AutoComplete
                                            onSearch={(e) => {
                                                const trimmedValue = e.trimStart();
                                                if (trimmedValue) {
                                                    handleChange(e, index, 'type');
                                                } else {
                                                    handleChange("", index, 'type');
                                                    setState({ ...state, optionsConcept: [] });
                                                }
                                            }}
                                            value={item?.type}
                                            options={optionsConcept}
                                            disabled={(item?.disabled || !item.isEdit)}
                                            onFocus={() => getFilterType(item?.type)}
                                            onSelect={(value) => handleChange(value, index, 'type')}
                                            className="credit-opl__payments__autocomplete"
                                            popupClassName="credit-opl__payments__input-autocomplete"
                                        >
                                            <Input
                                                placeholder='Tipo'
                                                className='credit-opl__payments__input'
                                                prefix={<img src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit} alt="icon_add" />}
                                            />
                                        </AutoComplete>
                                    </div>
                                    <div className='small-2'>
                                        <DatePicker
                                            placeholder='Fecha'
                                            allowClear={false}
                                            style={{ width: "90%" }}
                                            disabledDate={disabledDate}
                                            disabled={item?.disabled || !item?.isEdit}
                                            value={item?.date && dayjs(item?.date).utc()}
                                            onChange={(e) => handleChange(e, index, "date")}
                                            className='credit-opl__payments__date'
                                        />
                                    </div>
                                    <div className='grid-x small-2'>
                                        <AutoComplete
                                            onSearch={(e) => {
                                                    const trimmedValue = e.trimStart();
                                                    if (trimmedValue) {
                                                        handleChange(e, index, 'employee');
                                                    } else {
                                                        handleChange("", index, 'employee');
                                                        setState({ ...state, optionsEmployee: [] });
                                                    }
                                                }}
                                                value={item?.employee}
                                                options={optionsEmployee}
                                                disabled={item?.disabled || !item?.isEdit}
                                                onFocus={() => getFilterEmployee(item?.employee)}
                                                onSelect={(value) => handleChange(value, index, 'employee')}
                                                className="credit-opl__payments__autocomplete"
                                                popupClassName="credit-opl__payments__input-autocomplete"
                                        >
                                            <Input
                                                placeholder='Empleado'
                                                className='credit-opl__payments__input'
                                                prefix={<img src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit} alt="icon_add" />}
                                            />
                                        </AutoComplete>
                                    </div>
                                    <div className='grid-x small-3'>
                                        <Input
                                            placeholder='Concepto'
                                            disabled={item?.disabled || !item?.isEdit}
                                            value={item?.concept}
                                            className='credit-opl__payments__input'
                                            onChange={(e) => handleChange(e, index, "concept")}
                                            prefix={<img src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit} alt="icon_add" />}
                                        />
                                    </div>
                                    <div className='grid-x justify-content-between small-3'>
                                        <div className='grid-x small-7 credit-opl__payments__table__content-inputs'>
                                            <NumericFormat
                                                type='text'
                                                prefix={"$ "}
                                                decimalScale={2}
                                                placeholder='$ 0'
                                                value={item?.price}
                                                thousandSeparator=","
                                                className='input-price'
                                                fixedDecimalScale={true}
                                                thousandsGroupStyle="thousand"
                                                disabled={item.disabled || !item?.isEdit}
                                                onValueChange={({ floatValue }) => handleChange(floatValue, index, 'price')}
                                            />
                                            <div className='credit-opl__payments__table__content-icon-price'>
                                                <img
                                                    alt="icon_add"
                                                    src={(item?.disabled || !item.isEdit) ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                                                />
                                            </div>
                                        </div>
                                        <div className='grid-x small-2 justify-content-end'>
                                            <button
                                                onClick={() => {
                                                    if (item?.id) {
                                                        if (item?.isEdit) {
                                                            handleEdit(item, index);
                                                        } else {
                                                            handleChange(true, index, 'isEdit');
                                                        }
                                                    } else {
                                                        handleCreate(item, index)
                                                    }
                                                }}
                                                className='credit-opl__payments__button-delete'
                                                disabled={item?.disabled}
                                            >
                                                <img
                                                    className='credit-opl__payments__button-delete__icon'
                                                    alt={item?.disabled ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                                                    src={item?.disabled ? Assets.SharedIcons.icon_edit_disabled : (item?.isEdit ? Assets.SharedIcons.icon_save : Assets.SharedIcons.icon_edit)}
                                                />
                                            </button>
                                        </div>
                                        <div className='grid-x small-2 justify-content-end'>
                                            <button
                                                onClick={() => {
                                                    if (item?.isEdit) {
                                                        handleChange(!item?.isEdit, index, 'isEdit')
                                                        handleResetItem(index, item?.id)
                                                    } else {
                                                        removeDataExpenses(item, index)
                                                    }
                                                }}
                                                className='credit-opl__payments__button-delete'
                                                disabled={item?.disabled}
                                            >
                                                <img
                                                    className='credit-opl__payments__button-delete__icon'
                                                    alt={item?.disabled ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                                                    src={item?.disabled ? Assets.SharedIcons.icon_deleted_disabled : (item?.isEdit ? Assets.SharedIcons.icon_deleted_active : Assets.SharedIcons.icon_trash_bin)}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='grid-x'>
                        <div className='credit-opl__payments__line'></div>
                        <button
                            onClick={() => addExpenses()}
                            className='grid-x align-middle credit-opl__payments__type'
                        >
                            <img
                                src={Assets.SharedIcons.icon_income}
                                alt='icon_income'
                            />
                            <span className='credit-opl__payments__type__name'>Agregar créditos</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
