
const SharedEndPoints = {
    GET_ALL_CHARGE: `/api/shared/charge/all`,
    GET_PROVIDERS: `/api/shared/provider/all`,
    GET_ALL_PAYMENT_METHOD_PROVIDERS: `/api/shared/paymentMethodReportCashClosing/all`,
    GET_ALL_INTERNAL_CONSUMPTION_CATEGORY: `/api/shared/domesticConsumptionCategory/all`,
    GET_ALL_INTERNAL_CONSUMPTION_PRODUCT: `/api/shared/domesticConsumptionProduct/`,
    GET_ALL_HEAD_QUARTERS: `/api/shared/headQuarter/all`,
    GET_ALL_EXPENSE_CATEGORY: `/api/shared/expenseCategory/all`,
    GET_EXPENSE_SUB_CATEGORY: `/api/shared/expenseSubcategory/`,
    GET_PAYMENT_METHOD_DAILY_TRANSACTION: `/api/shared/paymentMethodDailyTransaction/`,
    GET_All_INDICATIVE_NUMBERS: `/api/shared/indicativeNumber/all`,
    GET_All_PERMISSIONS: `/api/shared/permission/all`,
    GET_All_USERS_AVAILABLE_HEADQUARTER: `/api/shared/userAvailableHeadquarter/all`,
    GET_CONCEPT: `/api/shared/conceptNotReported/:isCredit`,
    GET_BANK_WHOLESALER: `/api/shared/bankWholesaler`,
    GET_DRIVER: `/api/shared/truckDriver`,
    GET_SELLER: `/api/shared/personPreSale`,
    GET_TYPE_EXPENSES_WHOLESALE: `/api/shared/typeExpenseWholesaler/:id`,
    GET_FINANCIAL_MOVEMENT: `/api/shared/typeFinancialMovement/:id`,
    GET_TYPE_OF_WHOLESALE_SET: `/api/shared/typeStoreWholesaler/:id`,
    GET_OPERATION: `/api/shared/operationOpl/all`,
    GET_DATES_INVENTORY: `/api/shared/inventoryDates`,
    GET_DATES_TRANSFER: `/api/shared/transferDates`,
    GET_DATES_UTILITIES: `/api/shared/utilitiesDates`,
    GET_TYPE_WE_OWE: `/api/shared/typeWeOwe/all`,
    GET_CONSTANTS_PROVISION: `/api/shared/constantsProvisionOpl`,
}

export default SharedEndPoints