import { getTokenService } from "./authentication.services";
import QuotationOPLEndPoints from "./constants/quotation-opl.end-points";
import SharedEndPoints from "./constants/shared.end-points";
import Http from "./infrastructure/http.infrastructure";


export async function getOplService() {
    try {
        const token = getTokenService();
        const { data: getHeadquarterOpl, error } = await Http.get(QuotationOPLEndPoints.GET_ALL_OPL, null , token)
        if (getHeadquarterOpl && getHeadquarterOpl.status) {
            return getHeadquarterOpl.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getOplExpensesService(id, date) {
    try {
        const token = getTokenService();
        const urlWithParams = QuotationOPLEndPoints.GET_OPL_EXPENSES.replace(":id", id).replace(":date", date)
        const { data: getOplExpenses, error } = await Http.get(urlWithParams, null , token)
        if (getOplExpenses && getOplExpenses.status) {
            return getOplExpenses.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function createQuotationService(data) {
    try {
        const token = getTokenService();
        const { data: getOplExpenses, error } = await Http.post(QuotationOPLEndPoints.CREATE_OPL, data , token)
        if (getOplExpenses && getOplExpenses.status) {
            return getOplExpenses.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }

}

export async function updateQuotationService(id, data) {
    try {
        const token = getTokenService();
        const urlWithParams = QuotationOPLEndPoints.UPDATE_OPL_EXPENSES.replace(":id", id)
        const { data: updateOplExpenses, error } = await Http.patch(urlWithParams, data , token)
        if (updateOplExpenses && updateOplExpenses.status) {
            return updateOplExpenses.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }

}

export async function deleteQuotationService(data) {
    try {
        const token = getTokenService();
        const { data: deleteOplExpenses, error } = await Http.delete(QuotationOPLEndPoints.DELETE_OPL_EXPENSES, data , token)
        if (deleteOplExpenses && deleteOplExpenses.status) {
            return deleteOplExpenses.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }

}

export async function getExpenseCategoryQuotationService() {
    try {
        const { data: responseGetAllCategories, error } = await Http.get(SharedEndPoints.GET_ALL_EXPENSE_CATEGORY)
        if (responseGetAllCategories && responseGetAllCategories.status) {
            const response = responseGetAllCategories.data.map((category) => ({
                ...category,
                value: category.id,
                label: category.name.toLowerCase().replace(/\b\w/g, c => c.toUpperCase())
            })
            );
            return response;
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getExpenseSubCategoryQuotationService(idExpenseCategory) {
    try {
        const { data: responseGetExpenseSubCategory, error } = await Http.get(SharedEndPoints.GET_EXPENSE_SUB_CATEGORY, idExpenseCategory)
        if (responseGetExpenseSubCategory && responseGetExpenseSubCategory.status) {
            const response = responseGetExpenseSubCategory.data.map((category) => ({
                ...category,
                value: category.id,
                label: category.name.toLowerCase().replace(/\b\w/g, c => c.toUpperCase())
            })
            );
            return response
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getExpenseTypeQuotationService(filterValue) {
    try {
        const token = getTokenService();
        const { data: responseGetExpenseType, error } = await Http.get(QuotationOPLEndPoints.GET_TYPE_CONCEPT, {filterValue}, token)
        if (responseGetExpenseType && responseGetExpenseType.status) {
            const response = responseGetExpenseType?.data?.map((type) => ({
                value: type?.typeConcept,
                label: type?.typeConcept
            }));
            return response
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createCreditOplService(data) {
    try {
        const token = getTokenService();
        const { data: createCreditOpl, error } = await Http.post(QuotationOPLEndPoints.CREATE_OPL_CREDIT, data, token)
        if (createCreditOpl && createCreditOpl.status) {
            return createCreditOpl.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function createOthersOplService(data) {
    try {
        const token = getTokenService();
        const { data: createCreditOpl, error } = await Http.post(QuotationOPLEndPoints.CREATE_OPL_OTHERS, data, token)
        if (createCreditOpl && createCreditOpl.status) {
            return createCreditOpl.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getOplCreditService(id, date) {
    try {
        const token = getTokenService();
        const urlWithParams = QuotationOPLEndPoints.GET_OPL_CREDIT.replace(":id", id).replace(":date", date)
        const { data: getOplExpenses, error } = await Http.get(urlWithParams, null, token)
        if (getOplExpenses && getOplExpenses.status) {
            return getOplExpenses.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getOplOthersService(id, date) {
    try {
        const token = getTokenService();
        const urlWithParams = QuotationOPLEndPoints.GET_OPL_OTHERS.replace(":id", id).replace(":date", date)
        const { data: getOplOthers, error } = await Http.get(urlWithParams, null, token)
        if (getOplOthers && getOplOthers.status) {
            return getOplOthers.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getTypeConceptCreditOpl(filter) {
    try {
        const token = getTokenService();
        const urlWithParams = QuotationOPLEndPoints.GET_OPL_CREDIT_CONCEPT_TYPES
        const { data: responseGetTypeConceptCreditOpl, error } = await Http.get(urlWithParams, { filterValue: filter }, token)
        if (responseGetTypeConceptCreditOpl && responseGetTypeConceptCreditOpl.status) {
            return responseGetTypeConceptCreditOpl.data.map((item) => ({
                value: item.typeConcept, label: item.typeConcept
            }));
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getEmployeeCreditOpl(filter) {
    try {
        const token = getTokenService();
        const urlWithParams = QuotationOPLEndPoints.GET_OPL_CREDIT_EMPLOYEES
        const { data: responseGetEmployeeCreditOpl, error } = await Http.get(urlWithParams, { filterValue: filter }, token)
        if (responseGetEmployeeCreditOpl && responseGetEmployeeCreditOpl.status) {
            return responseGetEmployeeCreditOpl.data.map((item) => ({
                value: item.employee, label: item.employee
            }));
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getGeneralAdjustmentOplService(id, date) {
    try {
        const token = getTokenService();
        const urlWithParams = QuotationOPLEndPoints.GET_GENERAL_ADJUSTMENT_OPL.replace(":id", id).replace(":date", date)
        const { data: getGeneralAdjustmentOpl, error } = await Http.get(urlWithParams, null, token)
        if (getGeneralAdjustmentOpl && getGeneralAdjustmentOpl.status) {
            return getGeneralAdjustmentOpl.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getTotalizerOfGeneralAdjustmentOplService(idHeadquarter, date) {
    try {
        const token = getTokenService();
        const urlWithParams = QuotationOPLEndPoints.GET_TOTALIZER_OF_GENERAL_ADJUSTMENT_OPL.replace(":idHeadquarter", idHeadquarter).replace(":date", date)
        const { data: responseTotalizerGeneralAdjustmentOpl, error } = await Http.get(urlWithParams, null, token)
        if (responseTotalizerGeneralAdjustmentOpl && responseTotalizerGeneralAdjustmentOpl.status) {
            return responseTotalizerGeneralAdjustmentOpl.data;
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getMonthClosingGeneralAdjustmentOplService(idHeadquarter, date) {
    try {
        const token = getTokenService();
        const urlWithParams = QuotationOPLEndPoints.GET_MONTHS_CLOSING_GENERAL_ADJUSTMENT_OPL.replace(":idHeadquarter", idHeadquarter).replace(":date", date);
        const { data: responseMonthClosingGeneralAdjustmentOpl, error } = await Http.get(urlWithParams, null, token)
        if (responseMonthClosingGeneralAdjustmentOpl && responseMonthClosingGeneralAdjustmentOpl.status) {

            return responseMonthClosingGeneralAdjustmentOpl.data;

        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
};

export async function generalAdjustmentClosingService(idHeadquarter, date) {
    try {
        const token = getTokenService();
        const urlWithParams = QuotationOPLEndPoints.GENERAL_ADJUSTMENT_OPL_CLOSING.replace(":idHeadquarter", idHeadquarter).replace(":date", date)
        const { data: responseOfClosing, error } = await Http.post(urlWithParams, null, token)
        if (responseOfClosing && responseOfClosing.status) {
            return responseOfClosing.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function createCashRegisterOplService(data) {
    try {
        const token = getTokenService();
        const { data: getGeneralAdjustmentOpl, error } = await Http.post(QuotationOPLEndPoints.CREATE_OPL_ClOSING_WE_OWE, data, token)
        if (getGeneralAdjustmentOpl && getGeneralAdjustmentOpl.status) {
            return getGeneralAdjustmentOpl.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function createCashRegisterToOplService(data) {
    try {
        const token = getTokenService();
        const { data: getGeneralAdjustmentOpl, error } = await Http.post(QuotationOPLEndPoints.CREATE_OPL_ClOSING_OWE_US, data, token)
        if (getGeneralAdjustmentOpl && getGeneralAdjustmentOpl.status) {
            return getGeneralAdjustmentOpl.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function deleteCashRegisterOplService(data) {
    try {
        const token = getTokenService();
        const { data: getGeneralAdjustmentOpl, error } = await Http.delete(QuotationOPLEndPoints.DELETE_CASH_CLOSING_OPL, data, token)
        if (getGeneralAdjustmentOpl && getGeneralAdjustmentOpl.status) {
            return getGeneralAdjustmentOpl.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function editCashRegisterOplService(id,data) {
    try {
        const token = getTokenService();
        const urlWithParams = QuotationOPLEndPoints.EDIT_CASH_CLOSING_OPL.replace(":id", id)
        const { data: getGeneralAdjustmentOpl, error } = await Http.patch(urlWithParams, data, token)
        if (getGeneralAdjustmentOpl && getGeneralAdjustmentOpl.status) {
            return getGeneralAdjustmentOpl.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getExpenseOperationQuotationService() {
    try {
        const { data: responseGetAllOperation, error } = await Http.get(SharedEndPoints.GET_OPERATION)
        if (responseGetAllOperation && responseGetAllOperation.status) {
            const response = responseGetAllOperation.data.map((category) => ({
                ...category,
                value: category.id,
                label: category.name.toLowerCase().replace(/\b\w/g, c => c.toUpperCase())
            }));
            return response;
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getProvisionsOplService(id) {
    try {
        const token = getTokenService();
        const urlWithParams = QuotationOPLEndPoints.GET_ALL_PROVISIONS_OPL.replace(":idHeadQuarter", id);
        const { data: responseGetProvisions, error } = await Http.get(urlWithParams, null, token)

        if (responseGetProvisions && responseGetProvisions.status) {

            const updatedResp = responseGetProvisions.data.map(item => ({
                ...item,
                data: item.data.map(entry =>
                    entry?.operation
                        ?
                            {
                                ...entry,
                                operation: {
                                    value: entry.operation.id,
                                    label: entry.operation.name
                                }
                            }
                        :   entry
                )
            }));

            return updatedResp
        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
}

export async function createProvisionOplService(data) {
    try {
        const token = getTokenService();
        const { data: responseCreateProvisions, error } = await Http.post(QuotationOPLEndPoints.CREATE_PROVISION_OPL, data, token)
        if (responseCreateProvisions && responseCreateProvisions.status) {
            return responseCreateProvisions.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateProvisionOplService(id, data) {
    try {
        const token = getTokenService();
        const urlWithParams = QuotationOPLEndPoints.UPDATE_PROVISION_OPL.replace(":id", id)
        const { data: updateProvisionOpl, error } = await Http.patch(urlWithParams, data , token)
        if (updateProvisionOpl && updateProvisionOpl.status) {
            return updateProvisionOpl.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function deleteProvisionOplService(id) {
    try {
        const token = getTokenService();
        const { data: deleteProvisionOpl, error } = await Http.delete(QuotationOPLEndPoints.DELETE_PROVISION_OPL, id, token)
        if (deleteProvisionOpl && deleteProvisionOpl.status) {
            return deleteProvisionOpl.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};