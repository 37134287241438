// Assets
import { Assets } from "../../../../assets";

// Components - Shared
import SuccessAlertComponent from "../../../../shared/components/alerts/success-alert/success-alert.component";
import InformationAlertComponent from "../../../../shared/components/alerts/information-alert/information-alert.component";
import ErrorAlertComponent from "../../../../shared/components/alerts/error-alert/error-alert.component";

// Libraries
import dayjs from 'dayjs';
import { AutoComplete, DatePicker, Input, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useLocation } from "react-router-dom";

// Services
import { 
    createQuotationService, deleteQuotationService, getOplExpensesService, 
    updateQuotationService, getExpenseCategoryQuotationService, getExpenseSubCategoryQuotationService, 
    getExpenseTypeQuotationService,
    getExpenseOperationQuotationService
} from "../../../../services/quotation-opl.services";

// Styles
import "./expenses-opl.component.scss"

// Utils
import { dataDefaultExpensesOpl } from "../../../../utils/quotation-opl.utils";

export const ExpensesOplComponent = ({ selectedMonthSummaryProp }) => {

    const { state: locationState } = useLocation();

    const INITIAL_STATE = {
        scrollEnd: false,
        expenses: dataDefaultExpensesOpl,
        listOfExpenseCategory: [],
        optionsType: [],
        operationSelector: []
    };

    const [state, setState] = useState(INITIAL_STATE);

    const { 
        expenses, scrollEnd, listOfExpenseCategory, 
        optionsType, operationSelector
    } = state;

    const formRef = useRef(null);

    useEffect(() => {
        if (formRef.current && scrollEnd) {
            const miDiv = formRef.current;
            miDiv.scrollTop = miDiv.scrollHeight;
        }
    }, [scrollEnd, expenses.length]);

    useEffect(() => {
        getAllServices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedMonthSummaryProp) {
            getTableExpenses();
            formRef.current.scrollTop = 0;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMonthSummaryProp]);

    const getAllServices = async () => {
        try {
            const responseServices = await Promise.allSettled([
                getExpenseCategoryQuotationService(),
                getExpenseOperationQuotationService()
            ])
            let respServices = {}
            let errors = false

            for (let index = 0; index < responseServices.length; index++) {
                const elementResponse = responseServices[index];
                if (elementResponse.status === "fulfilled") {
                    switch (index) {
                        case 0:
                            respServices.listOfExpenseCategory = elementResponse?.value
                        break;
                        case 1:
                            respServices.operationSelector = elementResponse?.value
                        break;
                        default:
                            break;
                    }
                } else {
                    errors = true
                }
            }

            if (errors) {
                ErrorAlertComponent(undefined, "Error al cargar los servicios")
            }
            
            setState((prevState) => ({
                ...prevState,
                ...respServices
            }))
        } catch (error) {
            ErrorAlertComponent(undefined, "Error al cargar los servicios")
        }
    };

    const getTableExpenses = async() => {
        try {
            const respService = await getOplExpensesService(locationState?.opl?.id, dayjs(selectedMonthSummaryProp)?.format("YYYY-MM"));
            let arr1 = [...INITIAL_STATE.expenses];
            let dataMod = [...respService]; 
            let arr2 = dataMod.map(async (data) => {
                const respListSubCategories = await getListOfSubCategories(data?.expenseSubcategoryCashExpenseOpl?.expenseCategoryExpenseSubcategory?.id);
                const listOfExpenseSubCategory = await respListSubCategories;
                return{
                    id: data.id,
                    type: data.typeConcept,
                    date: data.date,
                    price: data.price,
                    concept: data.concept,
                    disabled: false,
                    category: {
                        label: data?.expenseSubcategoryCashExpenseOpl?.expenseCategoryExpenseSubcategory?.name?.toLowerCase()?.replace(/\b\w/g, c => c?.toUpperCase()),
                        value: data?.expenseSubcategoryCashExpenseOpl?.expenseCategoryExpenseSubcategory?.id,
                    },
                    subCategory: {
                        label: data?.expenseSubcategoryCashExpenseOpl?.name?.toLowerCase()?.replace(/\b\w/g, c => c?.toUpperCase()),
                        value: data?.expenseSubcategoryCashExpenseOpl?.id,
                    },
                    disabledSubCategory: false,
                    listOfExpenseSubCategory,
                    isEdit: false,
                    operation: {
                        label: data?.operationOPLCashExpenseOpl?.label,
                        value: data?.operationOPLCashExpenseOpl?.id
                    }
                }
            });
            Promise.all(arr2).then((resolvedArr2) => {
                const minLen = Math.min(arr1.length, resolvedArr2.length);

                for (let i = 0; i < minLen; i++) {
                    if (arr1[i].id !== resolvedArr2[i].id) {
                        arr1[i] = resolvedArr2[i];
                    }
                }

                if (arr1.length < resolvedArr2.length) {
                    for (let i = minLen; i < resolvedArr2.length; i++) {
                        arr1.push(resolvedArr2[i]);
                    }
                }

                setState((prevState) => ({
                    ...prevState,
                    expenses: arr1,
                    scrollEnd: false
                }));
            });
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    };

    const handleTotal = (data) => {
        let totalPrice = 0;
        data.forEach(object => {
          totalPrice += (parseFloat(object.price) ? parseFloat(object.price) : 0);
        });
        totalPrice = parseFloat(totalPrice.toFixed(2));
        return totalPrice;
    };

    const handleChange = async (e, index, name) => {
        let value = e && e.target ? e.target.value || '' : e;
        const newArray = JSON.parse(JSON.stringify(expenses));

        if ((name === "category") || (name === "subCategory") || (name === "operation")) {
            newArray[index][name] = {
                label: value.label,
                value: value.value
            }
            if (name === "category") {
                try {
                    const listOfExpenseSubCategory = await getListOfSubCategories(value.value);
                    newArray[index].listOfExpenseSubCategory = listOfExpenseSubCategory;
                    newArray[index].disabledSubCategory = false;
                    newArray[index].subCategory = { label: "", value: "" }
                } catch (error) {
                    // TODO: Implement error alert with code error.
                }
            }
        } else {
            newArray[index][name] = value
        }

        let newState = {
            ...state,
            expenses: newArray
        }

        if (name === "type") {
            const options = await optionsFilterType(value);
            newState.optionsType = options || [];
        }
    
        setState(newState);
    };

    const getListOfSubCategories = async (idExpenseCategory) => {
        try {
            return await getExpenseSubCategoryQuotationService({ idExpenseCategory });
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    };

    const removeDataExpenses = async (item) => {
        try {
            await deleteQuotationService({id:item.id});
            SuccessAlertComponent(undefined, "Gasto eliminado exitosamente");
            getTableExpenses();
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    };

    const addExpenses = () => {
        const objectInputDisabled = expenses.find(object => object.hasOwnProperty("disabled") && object.disabled === true);

        if (!objectInputDisabled) {
            const newArray = [
                ...expenses,
                {
                    type: "",
                    date: null,
                    price: "",
                    concept: "",
                    disabled: false,
                    category: {
                        label: "",
                        value: "",
                    },
                    subCategory: {
                        label: "",
                        value: "",
                    },
                    disabledSubCategory: true,
                    listOfExpenseSubCategory: [],
                    isEdit: true,
                    operation: {
                        label: "",
                        value: "",
                    },
                }
            ];
            setState((prevState) => ({
                ...prevState,
                expenses: newArray,
                scrollEnd: true
            }))
        } else {
            const newArray = expenses.map(object => (object === objectInputDisabled ? { ...object, disabled: false, isEdit: true } : object));
            setState((prevState) => ({
                ...prevState,
                expenses: newArray
            }))
        }
    };

    const handleCreate = async (item) => {
        try {
            const { price, concept, date, subCategory, type , operation } = item;
            if ( !price || !concept || !date || !subCategory?.value || !type || !operation?.value ) return InformationAlertComponent(undefined, "Todos los campos son obligatorios");
            await createQuotationService({
                price: item?.price,
                concept: item?.concept,
                date: item?.date,
                idExpenseSubcategory: item?.subCategory?.value,
                idHeadQuarter: locationState?.opl?.id,
                typeConcept: item?.type,
                idOperationOPL: item?.operation?.value
            });
            SuccessAlertComponent(undefined, "Gasto creado exitosamente");
            getTableExpenses();
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    };

    const handleEdit = async (item, index) => {
        try {
            const { price, concept, date, subCategory, type , operation } = item;
            if ( !price || !concept || !date || !subCategory?.value || !type || !operation?.value ) return InformationAlertComponent(undefined, "Todos los campos son obligatorios");
            await updateQuotationService(
                item.id,
                {
                    price: item.price,
                    concept: item.concept,
                    date: item.date,
                    idExpenseSubcategory: item.subCategory.value,
                    typeConcept: item.type,
                    idOperationOPL: item?.operation?.value
                }
            );
            handleChange(false, index, 'isEdit');
            SuccessAlertComponent(undefined, "Gasto actualizado exitosamente");
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    }

    const handleResetItem = async (index,id) => {
        try {
            let copyExpenses = [...expenses];
            if (id) {
                const respService = await getOplExpensesService(locationState?.opl?.id, dayjs(selectedMonthSummaryProp)?.format("YYYY-MM"));
                const respListSubCategories = await getListOfSubCategories(respService[index]?.expenseSubcategoryCashExpenseOpl?.expenseCategoryExpenseSubcategory?.id);
                const listOfExpenseSubCategory = await respListSubCategories;
                copyExpenses[index]={
                    id: respService[index].id,
                    type: respService[index].typeConcept,
                    date: respService[index].date,
                    price: respService[index].price,
                    concept: respService[index].concept,
                    disabled: false,
                    category: {
                        label: respService[index]?.expenseSubcategoryCashExpenseOpl?.expenseCategoryExpenseSubcategory?.name?.toLowerCase()?.replace(/\b\w/g, c => c?.toUpperCase()),
                        value: respService[index]?.expenseSubcategoryCashExpenseOpl?.expenseCategoryExpenseSubcategory?.id,
                    },
                    subCategory: {
                        label: respService[index]?.expenseSubcategoryCashExpenseOpl?.name?.toLowerCase()?.replace(/\b\w/g, c => c?.toUpperCase()),
                        value: respService[index]?.expenseSubcategoryCashExpenseOpl?.id,
                    },
                    disabledSubCategory: false,
                    listOfExpenseSubCategory,
                    isEdit: false,
                    operation: {
                        label: respService[index]?.operationOPLCashExpenseOpl?.name,
                        value: respService[index]?.operationOPLCashExpenseOpl?.id
                    }
                }
            } else {
                copyExpenses[index]={
                    type: "",
                    date: null,
                    price: "",
                    concept: "",
                    disabled: true,
                    category: {
                        label: "",
                        value: "",
                    },
                    subCategory: {
                        label: "",
                        value: "",
                    },
                    disabledSubCategory: true,
                    listOfExpenseSubCategory: [],
                    isEdit: false,
                    operation: {
                        label: "",
                        value: "",
                    }
                }
            }
            setState((prevState) => ({
                ...prevState,
                expenses: copyExpenses
            }))
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    }

    const optionsFilterType = async(filter) => {
        try {
            const listOfExpenseType = await getExpenseTypeQuotationService(filter);
            return listOfExpenseType || [];
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    }

    const getFilterType = async(filter) => {
        try {
            const options = await optionsFilterType(filter);
            setState((prevState) => ({
                ...prevState,
                optionsType: options
            }))
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    };

    const disabledDate = (current) => {
        return current && current.isAfter(dayjs(), 'day');
    };

    return (
        <div className='small-6'>
            <div className='expenses-opl__payments__container'>
                <div className='expenses-opl__payments__content'>
                    <div className='grid-y'>
                        <div className='grid-x expenses-opl__payments__content-titles__fist'>
                            <div className='grid-x align-middle small-6'>
                                <img src={Assets.SharedIcons.icon_information} alt="icon_information" />
                                <span className='expenses-opl__payments__titles expenses-opl__payments__titles__space'>Gastos</span>
                            </div>
                            <div className='grid-x align-middle small-6 justify-content-end'>
                                <span className='expenses-opl__payments__titles'>Total:</span>
                                <div className='grid-x align-middle expenses-opl__summary__details'>
                                    <div className='expenses-opl__payments__table__content-icon-price'>
                                        <img
                                            alt="icon_egress"
                                            src={Assets.SharedIcons.icon_egress}
                                            className='expenses-opl__summary__price__icon'
                                        />
                                    </div>
                                    <NumericFormat
                                        type='text'
                                        prefix={"$ "}
                                        disabled
                                        placeholder='$ 0'
                                        thousandSeparator=","
                                        className='input-price'
                                        value={handleTotal(expenses)}
                                        thousandsGroupStyle="thousand"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='grid-x expenses-opl__payments__content-titles__second'>
                            <span className='grid-x expenses-opl__payments__titles expenses-opl__payments__table__content'>Tipo</span>
                            <span className='grid-x expenses-opl__payments__titles expenses-opl__payments__table__content'>Fecha</span>
                            <span className='grid-x expenses-opl__payments__titles expenses-opl__payments__table__content'>Operación</span>
                            <span className='grid-x expenses-opl__payments__titles expenses-opl__payments__titles__space--mod expenses-opl__payments__table__content'>Categoría</span>
                            <span className='grid-x expenses-opl__payments__titles expenses-opl__payments__titles__space--mod expenses-opl__payments__table__content'>Subcategoría</span>
                            <span className='grid-x expenses-opl__payments__titles expenses-opl__payments__titles__space--mod expenses-opl__payments__table__content'>Concepto</span>
                            <span className='grid-x expenses-opl__payments__titles expenses-opl__payments__titles__space--mod expenses-opl__payments__table__content__buttons'>Valor</span>
                        </div>
                    </div>
                    <div ref={formRef} className='expenses-opl__payments__info'>
                        {expenses?.map((item, index) => {
                            return (
                                <div key={index} className='grid-x justify-content-between expenses-opl__payments__table'>
                                    <div className='grid-x expenses-opl__payments__table__content'>
                                        <AutoComplete
                                            onSearch={(e) => {
                                                const trimmedValue = e.trimStart();
                                                if (trimmedValue) {
                                                    handleChange(e, index, 'type')
                                                } else {
                                                    handleChange("", index, 'type');
                                                }
                                            }}
                                            options={optionsType}
                                            onSelect={(value) => handleChange(value, index, 'type')}
                                            disabled={(item?.disabled || !item.isEdit)}
                                            value={item?.type}
                                            onFocus={() => getFilterType(item?.type)}
                                            className="expenses-opl__payments__autocomplete"
                                            popupClassName="expenses-opl__payments__input-autocomplete"
                                        >
                                            <Input
                                                placeholder='Tipo'
                                                className='expenses-opl__payments__input'
                                                prefix={<img src={(item?.disabled || !item.isEdit) ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit} alt="icon_add" />}
                                            />
                                        </AutoComplete>
                                    </div>
                                    <div className='expenses-opl__payments__table__content'>
                                        <DatePicker
                                            style={{ width: "90%" }}
                                            disabledDate={disabledDate}
                                            disabled={(item?.disabled || !item.isEdit)}
                                            placeholder='Fecha'
                                            value={item?.date && dayjs(item?.date).utc()}
                                            onChange={(e) => handleChange(dayjs(e).format("YYYY-MM-DD"), index, "date")}
                                            className='expenses-opl__payments__date'
                                            suffixIcon={<img src={(item?.disabled || !item.isEdit) ? Assets.SharedIcons.icon_calendar_disabled : Assets.SharedIcons.icon_calendar} alt="icon_calendar" />}
                                            allowClear={false}
                                        />
                                    </div>
                                    <div className='expenses-opl__payments__table__content'>
                                        <Select
                                            optionLabelProp="label"
                                            placeholder='Operación'
                                            disabled={(item?.disabled || !item.isEdit)}
                                            popupMatchSelectWidth={false}
                                            options={operationSelector}
                                            className='expenses-opl__payments__select'
                                            popupClassName='expenses-opl__payments__select__popup'
                                            value={item?.operation?.value ? item?.operation : undefined}
                                            onChange={(e, event) => handleChange(event, index, 'operation')}
                                            suffixIcon={
                                                <div className='expenses-opl__payments__select__icon'>
                                                    <img
                                                        alt={(item?.disabled || !item.isEdit) ? "icon_disabled_arrow" : "icon_blue_arrow"}
                                                        src={(item?.disabled || !item.isEdit) ? Assets.SharedIcons.icon_disabled_arrow : Assets.SharedIcons.icon_blue_arrow}
                                                    />
                                                </div>
                                            }
                                        />
                                    </div>
                                    <div className='expenses-opl__payments__table__content'>
                                        <Select
                                            optionLabelProp="label"
                                            placeholder='Categoría'
                                            disabled={(item?.disabled || !item.isEdit)}
                                            popupMatchSelectWidth={false}
                                            options={listOfExpenseCategory}
                                            className='expenses-opl__payments__select'
                                            popupClassName='expenses-opl__payments__select__popup'
                                            value={item?.category?.value ? item?.category : undefined}
                                            onChange={(e, event) => handleChange(event, index, 'category')}
                                            suffixIcon={
                                                <div className='expenses-opl__payments__select__icon'>
                                                    <img
                                                        alt={(item?.disabled || !item.isEdit) ? "icon_disabled_arrow" : "icon_blue_arrow"}
                                                        src={(item?.disabled || !item.isEdit) ? Assets.SharedIcons.icon_disabled_arrow : Assets.SharedIcons.icon_blue_arrow}
                                                    />
                                                </div>
                                            }
                                        />
                                    </div>
                                    <div className='grid-x expenses-opl__payments__table__content'>
                                        <Select
                                            optionLabelProp="label"
                                            placeholder='Subcategoría'
                                            popupMatchSelectWidth={false}
                                            disabled={(item.disabledSubCategory || !item.isEdit)}
                                            options={item?.listOfExpenseSubCategory}
                                            className='expenses-opl__payments__select'
                                            popupClassName='expenses-opl__payments__select__popup'
                                            value={item?.subCategory?.value ? item?.subCategory : undefined}
                                            onChange={(e, event) => handleChange(event, index, 'subCategory')}
                                            suffixIcon={
                                                <div className='expenses-opl__payments__select__icon'>
                                                    <img
                                                        alt={(item.disabledSubCategory || !item.isEdit) ? "icon_disabled_arrow" : "icon_blue_arrow"}
                                                        src={(item.disabledSubCategory || !item.isEdit) ? Assets.SharedIcons.icon_disabled_arrow : Assets.SharedIcons.icon_blue_arrow}
                                                    />
                                                </div>
                                            }
                                        />
                                    </div>
                                    <div className='grid-x expenses-opl__payments__table__content'>
                                        <Input
                                            placeholder='Concepto'
                                            disabled={(item?.disabled || !item.isEdit)}
                                            value={item?.concept}
                                            className='expenses-opl__payments__input'
                                            onChange={(e) => handleChange(e, index, "concept")}
                                            prefix={<img src={(item?.disabled || !item.isEdit) ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit} alt="icon_add" />}
                                        />
                                    </div>
                                    <div className='grid-x justify-content-between expenses-opl__payments__table__content__buttons'>
                                        <div className='grid-x small-7 expenses-opl__payments__table__content-inputs'>
                                            <NumericFormat
                                                type='text'
                                                prefix={"$ "}
                                                decimalScale={2}
                                                placeholder='$ 0'
                                                value={item?.price}
                                                thousandSeparator=","
                                                className='input-price'
                                                fixedDecimalScale={true}
                                                thousandsGroupStyle="thousand"
                                                disabled={(item?.disabled || !item.isEdit)}
                                                onValueChange={({ floatValue }) => handleChange(floatValue, index, 'price')}
                                            />
                                            <div className='expenses-opl__payments__table__content-icon-price'>
                                                <img
                                                    alt="icon_add"
                                                    src={(item?.disabled || !item.isEdit) ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                                                />
                                            </div>
                                        </div>
                                        <div className='grid-x small-2 justify-content-end'>
                                            <button
                                                onClick={() => {
                                                    if (item?.id) {
                                                        if (item.isEdit) {
                                                            handleEdit(item, index);
                                                        } else {
                                                            handleChange(true, index, 'isEdit');
                                                        }
                                                    } else {
                                                        handleCreate(item,index)
                                                    }
                                                }}
                                                className='expenses-opl__payments__button-delete'
                                                disabled={item?.disabled}
                                            >
                                                <img
                                                    className='expenses-opl__payments__button-delete__icon'
                                                    alt={item?.disabled ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                                                    src={item?.disabled ? Assets.SharedIcons.icon_edit_disabled : (item?.isEdit ? Assets.SharedIcons.icon_save : Assets.SharedIcons.icon_edit)}
                                                />
                                            </button>
                                        </div>
                                        <div className='grid-x small-2 justify-content-end'>
                                            <button
                                                onClick={() =>{
                                                    if (item?.isEdit) {
                                                        handleChange(!item?.isEdit, index, 'isEdit')
                                                        handleResetItem(index, item?.id)
                                                    } else {
                                                        removeDataExpenses(item)
                                                    }
                                                }}
                                                className='expenses-opl__payments__button-delete'
                                                disabled={item?.disabled}
                                            >
                                                <img
                                                    className='expenses-opl__payments__button-delete__icon'
                                                    alt={item?.disabled ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                                                    src={item?.disabled ? Assets.SharedIcons.icon_deleted_disabled : (item?.isEdit ? Assets.SharedIcons.icon_deleted_active : Assets.SharedIcons.icon_trash_bin)}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='grid-x'>
                        <div className='expenses-opl__payments__line'></div>
                        <button
                            onClick={() => addExpenses()}
                            className='grid-x align-middle expenses-opl__payments__type expenses-opl__payments__type--mod'
                        >
                            <img
                                src={Assets.SharedIcons.icon_egress}
                                alt='icon_egress'
                            />
                            <span className='expenses-opl__payments__type__name'>Agregar gasto</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
};
