//Actions
import { setStateInventoryReducer } from '../../../../storage/reducers/inventory/inventory.actions';

// Components
import FileUploadToUtilitiesComponent from './components/file-upload-to-utilities/file-upload-to-utilities.component';
import ListOfTnsValuationsComponent from './components/list-of-tns-valuations/list-of-tns-valuations.component';
import ErrorAlertComponent from '../../../../shared/components/alerts/error-alert/error-alert.component';

// Libraries
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// Services
import { getDatesUtilitiesService, getReportsValuationService } from '../../../../services/inventory.services';

// Styles
import './utilities.component.scss';

const UtilitiesComponent = (props) => {

  const {
    // Props
    isUpdated,
    isUpdateStateReports,
    dateSelected,
    //Actions
    setStateInventoryReducer
  } = props;

  const INITIAL_STATE = {
    isLoading: false,
    isLoadingView: true,
    isListOfTnsValuations: false,
    cloudValuationInfo: {},
    coldPointValuationInfo: {},
    isFirstLoad: true
  };

  const [state, setState] = useState(INITIAL_STATE);

  const {
    isLoading,
    isLoadingView,
    isListOfTnsValuations,
    cloudValuationInfo,
    coldPointValuationInfo,
    isFirstLoad
  } = state;

  useEffect(() => {
    if (isFirstLoad) {
      getListOfTnsValuations(true);
    } else {
      getListOfTnsValuations(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSelected]);

  const getListOfTnsValuations = async (isGetInitial) => {
    try {
      isGetInitial && setState((prevState) => ({ ...prevState, isLoadingView: true }));
      let stateReportsDefault = {};
      if (!isGetInitial) {
        const respService = await getDatesUtilitiesService();
        if (respService?.length) {
          stateReportsDefault.lastUpdate = respService;
          if (!dateSelected?.value) {
            stateReportsDefault.lastUpdateSelected = respService[0];
          }
        }
      }
      const { ppAndCloudTableData } = await getReportsValuationService({date: dateSelected?.value ? dateSelected?.value : stateReportsDefault?.lastUpdateSelected?.value});
      if (ppAndCloudTableData?.cloud?.data?.length && ppAndCloudTableData?.pp?.data?.length) {
        if (isGetInitial) {
          setTimeout(() => {
            setState((prevState) => ({
              ...prevState,
              isLoadingView: false,
              isListOfTnsValuations: true,
              cloudValuationInfo: ppAndCloudTableData?.cloud,
              coldPointValuationInfo: ppAndCloudTableData?.pp,
              isFirstLoad: false
            }));
            isUpdateStateReports({isUtilities: true, isUpdated: false});
          }, 500);
        } else {
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            isListOfTnsValuations: true,
            cloudValuationInfo: ppAndCloudTableData?.cloud,
            coldPointValuationInfo: ppAndCloudTableData?.pp,
            isFirstLoad:false
          }));
          isUpdateStateReports({...stateReportsDefault, isUtilities: true, isUpdated: false});
        }
      } else {
        setTimeout(() => {
          setState((prevState) => ({ ...prevState, isLoadingView: false, isListOfTnsValuations: false, isLoading: false, isFirstLoad: false }));
        }, 500);
      }
      setStateInventoryReducer('utilitiesDataExcelDownload', ppAndCloudTableData)
    } catch (error) {
      ErrorAlertComponent();
    }
  };
  
  return (
    <>
      {isLoadingView
        ?
          (<div className='grid-y align-center-middle utilities__content-loading-view'>
            <Spin size='large' />
            <span className='utilities__content-loading-view__text'>Cargando...</span>
          </div>)
        :
          (<div className='utilities__container'>
          {isLoading &&
            (<>
              <div className='utilities__spinner__container' />
              <Spin size='large' className='utilities__spinner' />
            </>)
          }
          {isListOfTnsValuations && !isUpdated
            ?
              (<ListOfTnsValuationsComponent
                cloudValuationInfo={cloudValuationInfo}
                coldPointValuationInfo={coldPointValuationInfo}
              />)
            :
              (<FileUploadToUtilitiesComponent
                getListOfTnsValuations={() => getListOfTnsValuations(false)}
                updateStateUtilities={(newState) => setState((prevState) => ({ ...prevState, ...newState}))}
              />)
          }
          </div>)
      }
    </>
  )
};

const mapStateToPropsActions = {
  setStateInventoryReducer
};

export default connect(null, mapStateToPropsActions)(UtilitiesComponent);