// Assets
import { Assets } from "../../../../assets";

// Components - Shared
import SuccessAlertComponent from "../../../../shared/components/alerts/success-alert/success-alert.component";
import InformationAlertComponent from "../../../../shared/components/alerts/information-alert/information-alert.component";
import ErrorAlertComponent from "../../../../shared/components/alerts/error-alert/error-alert.component";

// Libraries
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import { DatePicker, Input } from "antd";
import { useLocation } from "react-router-dom";

// Services
import { createCashRegisterOplService, deleteCashRegisterOplService, editCashRegisterOplService } from "../../../../services/quotation-opl.services";

// Styles
import "./owe-us-opl.component.scss";

// Utils
import { dataDefaultTableOpl } from "../../../../utils/quotation-opl.utils";

export const OweUsOplComponent = (props) => {

    const { 
        data,
        date,
        handleChangeGet
    } = props

    const { state: locationState } = useLocation();
    const INITIAL_STATE = { 
        scrollEnd: false, 
        expenses: dataDefaultTableOpl 
    };
    const [state, setState] = useState(INITIAL_STATE);
    const { expenses, scrollEnd } = state;

    const formRef = useRef(null);

    useEffect(() => {
        if (formRef.current && scrollEnd){
            formRef.current.scrollTop = formRef.current.scrollHeight
        }
    }, [scrollEnd, expenses.length]);

    useEffect(() => {
        if (data) {
            try {
                let expenseOPL = [...INITIAL_STATE.expenses];
                let oplExpenseList = data.map(async (value) => ({ 
                    ...value, 
                    disabled: false, 
                    isEdit: false 
                }));
                Promise.all(oplExpenseList).then((expenseList) => {
                    const minLen = Math.min(expenseOPL.length, expenseList.length);
    
                    for (let i = 0; i < minLen; i++){ 
                        if (expenseOPL[i].id !== expenseList[i].id){ 
                            expenseOPL[i] = expenseList[i]
                        };
                    }

                    if (expenseOPL.length < expenseList.length){ 
                        for (let i = minLen; i < expenseList.length; i++){
                            expenseOPL.push(expenseList[i])
                        };
                    }
                    setState((prevState) => ({ 
                        ...prevState, 
                        expenses: expenseOPL, 
                        scrollEnd: false 
                    }));
                });
            } catch (error) {
                ErrorAlertComponent(undefined, "Error al visualizar los registro de OPL")
            }
        } else {
            setState(INITIAL_STATE)
        }
        formRef.current.scrollTop = 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleTotal = (data) => {
        let totalPrice = 0;
        data.forEach(object => totalPrice += (parseFloat(object.price) ? parseFloat(object.price) : 0));
        return parseFloat(totalPrice.toFixed(2));
    };

    const handleChange = async (e, index, name) => {
        let value = e && e.target ? e.target.value || '' : e;
        const newArray = JSON.parse(JSON.stringify(expenses));
        newArray[index][name] = value
        setState({ ...state, expenses: newArray })
    };

    const handleEdit = async (item, index) => {
        try {
            const validateInfoNonEmpty = Object.values({
                price: item.price, 
                date: item.date, 
                concept: item.concept
            }).every(value =>  value !== null && value !== undefined && value !== "");
            if(validateInfoNonEmpty) {
                handleChange(false, index, 'isEdit')
                await editCashRegisterOplService(item.id, { date: dayjs(item.date).format('YYYY-MM-DD'), concept: item.concept, price: item.price });
                handleChangeGet()
                SuccessAlertComponent(undefined, "Registro actualizado exitosamente");
            } else {
                handleChange(true, index, 'isEdit')
                InformationAlertComponent(undefined, "Error al editar el registro, los datos deben ser completados")
            }
        } catch (error) {
            ErrorAlertComponent(undefined, "Error al editar el registro")
        }
    }

    const handleCreate = async (item, index) => {
        try {
            const validateInfoNonEmpty = Object.values({
                price: item.price, 
                date: item.date, 
                concept: item.concept
            }).every(value =>  value !== null && value !== undefined && value !== "");
            if(validateInfoNonEmpty) {
                await createCashRegisterOplService({
                    date: item.date,
                    concept: item.concept,
                    price: item.price,
                    idHeadquarter: locationState?.opl?.id,
                })
                handleChangeGet()
                SuccessAlertComponent(undefined, "Registro creado exitosamente");
            } else{  
                InformationAlertComponent(undefined, "Error al crear el registro, los datos deben ser completados")
            }
        } catch (error) {
            ErrorAlertComponent(undefined, "Error al crear el registro")
        }
    };

    const removeDataExpenses = async (item, index) => {
        const initialItem = { price: "", concept: "", disabled: true };
        const newArray = JSON.parse(JSON.stringify(expenses));
        newArray[index] = initialItem;
        try {
            if (item?.id) {
                await deleteCashRegisterOplService({id:item.id, date: date.format('YYYY-MM-01')});
            }
            SuccessAlertComponent(undefined, "Registro eliminado exitosamente");
            newArray.sort((a, b) => (a.disabled === b.disabled) ? 0 : a.disabled ? 1 : -1);
            setState({ ...state, expenses: newArray });
            handleChangeGet()
        } catch (error) {
            ErrorAlertComponent(undefined, "Error al eliminar el registro")
        }
    };

    const addExpenses = () => {
        const objectInputDisabled = expenses.find(object => object.hasOwnProperty("disabled") && object.disabled === true);
        if (!objectInputDisabled) {
            const newArray = [ ...expenses, { date: null, price: "", concept: "", isEdit: true, disabled: false } ];
            setState((prevState) => ({ ...prevState, expenses: newArray, scrollEnd: true }))
        } else {
            const newArray = expenses.map(object => (object === objectInputDisabled ? { ...object, disabled: false, isEdit: true } : object));
            setState((prevState) => ({ ...prevState, expenses: newArray }))
        }
    };

    const disabledDate = (current) => {
        return current && current.isAfter(dayjs(), 'day');
    };

    return (
        <div className='owe-us-opl__payments__container'>
            <div className='owe-us-opl__payments__content'>
                <div className='grid-y'>
                    <div className='grid-x owe-us-opl__payments__content-titles__fist'>
                        <div className='grid-x align-middle small-6'>
                            <img src={Assets.SharedIcons.icon_information} alt="icon_information" />
                            <span 
                                className='owe-us-opl__payments__titles owe-us-opl__payments__titles__space'
                            >
                                Nos deben
                            </span>
                        </div>
                        <div className='grid-x align-middle small-6 justify-content-end'>
                            <span className='owe-us-opl__payments__titles'>Total:</span>
                            <div className='grid-x align-middle owe-us-opl__summary__details'>
                                <div className='owe-us-opl__payments__table__content-icon-price'>
                                    <img
                                        alt="icon_income"
                                        src={Assets.SharedIcons.icon_income}
                                        className='owe-us-opl__summary__price__icon'
                                    />
                                </div>
                                <NumericFormat
                                    type='text'
                                    prefix={"$ "}
                                    disabled
                                    placeholder='$ 0'
                                    thousandSeparator=","
                                    className='input-price'
                                    value={handleTotal(expenses)}
                                    thousandsGroupStyle="thousand"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='grid-x justify-content-between owe-us-opl__payments__content-titles__second'>
                        <span className='grid-x owe-us-opl__payments__titles owe-us-opl__payments__titles__space--mod small-3'>Fecha</span>
                        <span className='grid-x owe-us-opl__payments__titles owe-us-opl__payments__titles__space--mod small-4'>Concepto</span>
                        <span className='grid-x owe-us-opl__payments__titles owe-us-opl__payments__titles__space--mod small-4'>Valor</span>
                    </div>
                </div>
                <div ref={formRef} className='owe-us-opl__payments__info'>
                    {expenses?.map(
                        (item, index) => {
                            return (
                                <div key={index} className='grid-x justify-content-between owe-us-opl__payments__table'>
                                    <div className='small-3'>
                                        <DatePicker
                                            style={{ width: "90%" }}
                                            disabled={(item?.disabled || !item.isEdit)}
                                            placeholder='Fecha'
                                            value={item?.date && dayjs(item?.date, "YYYY-MM-DD")}
                                            onChange={(e) => handleChange(e.format('YYYY-MM-DD'), index, "date")}
                                            format="YYYY-MM-DD"
                                            className='credit-opl__payments__date'
                                            allowClear={false}
                                            disabledDate={(current)=> disabledDate(current)}
                                        />
                                    </div>
                                    <div className='grid-x small-4'>
                                        <Input
                                            placeholder='Concepto'
                                            disabled={(item?.disabled || !item.isEdit)}
                                            value={item?.concept}
                                            className='owe-us-opl__payments__input'
                                            onChange={(e) => handleChange(e, index, "concept")}
                                            prefix={<img src={item?.disabled || !item.isEdit ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit} alt="icon_add" />}
                                        />
                                    </div>
                                    <div className='grid-x justify-content-between small-5'>
                                        <div className='grid-x small-7 owe-us-opl__payments__table__content-inputs'>
                                            <NumericFormat
                                                type='text'
                                                prefix={"$ "}
                                                placeholder='$ 0'
                                                value={item?.price}
                                                thousandSeparator=","
                                                className='input-price'
                                                decimalScale={2}
                                                disabled={(item?.disabled || !item.isEdit)}
                                                thousandsGroupStyle="thousand"
                                                onValueChange={({ floatValue }) => handleChange(floatValue, index, 'price')}
                                            />
                                            <div className='owe-us-opl__payments__table__content-icon-price'>
                                                <img
                                                    alt="icon_add"
                                                    src={item?.disabled || !item.isEdit ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                                                />
                                            </div>
                                        </div>
                                        <div className='grid-x small-2 justify-content-end'>
                                            <button
                                                onClick={() => {
                                                    if (item?.id) {
                                                        handleChange(!item?.isEdit, index, 'isEdit')
                                                        if (item?.isEdit){ 
                                                            handleEdit(item, index)
                                                        }
                                                    } else {
                                                        handleCreate(item, index)
                                                    }
                                                }}
                                                className='credit-opl__payments__button-delete'
                                                disabled={item?.disabled}
                                            >
                                                <img
                                                    className='owe-us-opl__payments__button-delete__icon'
                                                    alt={item?.disabled ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                                                    src={item?.disabled ? Assets.SharedIcons.icon_edit_disabled : (item?.isEdit ? Assets.SharedIcons.icon_save : Assets.SharedIcons.icon_edit)}
                                                />
                                            </button>
                                        </div>
                                        <div className='grid-x small-2 justify-content-end'>
                                            <button
                                                onClick={() =>{ 
                                                    if (item?.isEdit){
                                                        handleChange(!item?.isEdit, index, 'isEdit')
                                                    }else{
                                                        removeDataExpenses(item, index)
                                                    }
                                                }}
                                                className='owe-us-opl__payments__button-delete'
                                                disabled={item?.disabled}
                                            >
                                                <img
                                                    className='owe-us-opl__payments__button-delete__icon'
                                                    alt={item?.disabled ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                                                    src={item?.disabled ? Assets.SharedIcons.icon_deleted_disabled : (item?.isEdit ? Assets.SharedIcons.icon_deleted_active : Assets.SharedIcons.icon_trash_bin)}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    )}
                </div>
                <div className='grid-x'>
                    <div className='owe-us-opl__payments__line'></div>
                    <button
                        onClick={() => addExpenses()}
                        className='grid-x align-middle owe-us-opl__payments__type'
                    >
                        <img
                            src={Assets.SharedIcons.icon_income}
                            alt='icon_income'
                        />
                        <span className='owe-us-opl__payments__type__name'>Agregar Nos deben</span>
                    </button>
                </div>
            </div>
        </div>
    )
};
