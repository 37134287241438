
import * as ExcelJS from 'exceljs';

export const formattedNumber = (inputNumber) => {

  if (Number.isNaN(inputNumber) || inputNumber === undefined || inputNumber === null) {
    return "$ 0"
  }

  if (Number.isInteger(Number(inputNumber)) && !inputNumber.toString().includes(".")) {
    return `$ ${parseFloat(inputNumber).toLocaleString()}`
  }

  const formattedNumber = parseFloat(inputNumber).toLocaleString(undefined, { maximumFractionDigits: 2 })

  if (inputNumber === `${Math.floor(inputNumber)}`) {
    return `$ ${formattedNumber.replace(/\.\d+$/, "")}`;
  } else if (formattedNumber.includes(".")) {
    return `$ ${formattedNumber}`;
  } else {
    return `$ ${(isNaN(parseFloat(formattedNumber)) ? "0" : formattedNumber) + "."}`;
  }

}

// TODO: number formatting with correction to integers
export const formattedNumberInteger = (inputNumber) => {

  if (Number.isNaN(inputNumber) || inputNumber === undefined || inputNumber === null) {
    return "$ 0"
  }

  if (Number.isInteger(Number(inputNumber)) && !inputNumber.toString().includes(".")) {
    return `$ ${parseInt(inputNumber).toLocaleString()}`
  }

  const formattedNumber = parseFloat(inputNumber).toLocaleString(undefined, { maximumFractionDigits: 2 })
  
  if (inputNumber === `${Math.floor(inputNumber)}`) {
    return `$ ${formattedNumber.replace(/\.\d+$/, "")}`;
  } else if (formattedNumber.includes(".")) {
    return `$ ${formattedNumber}`;
  } else {
    return `$ ${isNaN(parseFloat(formattedNumber)) ? "0" : formattedNumber}`;
  }
};

function addHeaders(worksheet, headers) {
  const headerRow = worksheet.addRow(headers);
  headerRow.eachCell((cell, colIndex) => {
    cell.font = { name: 'Arial', size: 12, bold: true };
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.border = {
      top: { style: 'thin', color: { argb: '000000' } },
      bottom: { style: 'thin', color: { argb: '000000' } },
      left: { style: 'thin', color: { argb: '000000' } },
      right: { style: 'thin', color: { argb: '000000' } }
    };
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '226FFF' },
      bgColor: { argb: '226FFF' },
    };

    worksheet.getColumn(colIndex).width = 30;
  });
}

export function fileXlsxGeneratorUtils(reportsInventory, title = '', columns) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(title);

  switch (title) {
    case 'INVENTARIO':
      addHeaders(worksheet, columns);
      const combinedInventoryData = [
        ...reportsInventory?.structuredByHeadQuartes,
        { headQuarterName: 'Total Inventario', storesData: [], totalInInventory: reportsInventory?.totalInInventory }
      ];
      combinedInventoryData?.forEach((report, index) => {
        let totalInventario = 0;
        report.storesData?.forEach(store => {
          totalInventario += store.value;
        });
        const rowData = [
          report.headQuarterName,
          report.storesData?.map(store => store.storeName).join(', '),
          `$ ${(totalInventario ? totalInventario : 0).toLocaleString('es-CO')}`
        ];
        const row = worksheet.addRow(rowData);
        const fillColor = index % 2 === 0 ? 'D2E4FE' : 'FFFFFFFF';
        row.eachCell(cell => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: fillColor },
          };
        });
        if (report.headQuarterName === 'Total Inventario') row.font = { bold: true };
      });
      break;

    case 'TRASLADOS':
      addHeaders(worksheet, columns);
      const combinedData = [
        {
          ...reportsInventory.mainHeadquarterTransfer,
        },
        ...reportsInventory.transfersOrganized?.map(item => ({
          ...item,
        })),
        {
          name: 'Totales generales',
          ...reportsInventory?.totalTransfers
        }
      ];
      combinedData?.forEach((transfer, index) => {
        const rowData = [
          transfer.name,
          `$ ${(transfer.arrival ? transfer.arrival : 0).toLocaleString('es-CO')}` ,
          `$ ${(transfer.exit ? transfer.exit : 0).toLocaleString('es-CO')}`,
        ];
        const row = worksheet.addRow(rowData);
        const fillColor = index % 2 === 0 ? 'D2E4FE' : 'FFFFFFFF';
        row.eachCell(cell => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: fillColor },
          };
        });
        if (transfer.name === 'Totales generales') row.font = { bold: true };
      });
      break;
    
    case "UTILIDADES":
      const { cloud, pp } = columns;
      const cloudData = reportsInventory.cloud?.data;
      const combinedCloudData = [
        ...cloudData,
        {
          headquarter: 'Totales generales',
          ...reportsInventory.cloud?.total
        }
      ];
      const cloudTitleRow = worksheet.addRow(['VALORIZACIÓN DE TNS NUBE']); 
      cloudTitleRow.font = { bold: true };  
      cloudTitleRow.alignment = { horizontal: 'center' }; 
      cloudTitleRow.height = 20;
      worksheet.mergeCells(1, 1, 1, 4);
  
      addHeaders(worksheet, cloud);

      combinedCloudData?.forEach((data, index) => {
        const rowData = [
          data.headquarter,
          `$ ${(data.tnsCloudUtility ? data.tnsCloudUtility : 0).toLocaleString('es-CO')}`,
          `$ ${(data.tnsCloudDifferential ? data.tnsCloudDifferential : 0).toLocaleString(`es-CO`)}`,
          `$ ${(data.tnsCloudSale ? data.tnsCloudSale : 0).toLocaleString('es-CO')}`,
        ];
        const row = worksheet.addRow(rowData);
        const fillColor = index % 2 === 0 ? 'D2E4FE' : 'FFFFFFFF';
        row.eachCell(cell => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: fillColor },
          };
        });
        if (data.headquarter === 'Totales generales') row.font = { bold: false };
      });


      const emptyRowsCount = 5;
      const numberOfColumns = 5;
      for (let i = 0; i < emptyRowsCount; i++) {
        const emptyRow = worksheet.addRow(new Array(numberOfColumns).fill(''));
        emptyRow.height = 20; 
        emptyRow.eachCell(cell => {
          cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };
        });
      }

      const ppData = reportsInventory.pp?.data;
      const combinedPPData = [
        ...ppData,
        {
          headquarter: 'Totales generales',
          ...reportsInventory.pp?.total
        }
      ]
      const ppTitleRow = worksheet.addRow(['VALORIZACIÓN DE TNS PUNTO FRÍO']); 
      ppTitleRow.font = { bold: true };  
      ppTitleRow.alignment = { horizontal: 'center' }; 
      ppTitleRow.height = 20;

      const rowNumberPp = ppTitleRow.number;
      const columnCountPp = worksheet.columns.length;
      worksheet.mergeCells(`A${rowNumberPp}:${String.fromCharCode(64 + columnCountPp)}${rowNumberPp}`);

      addHeaders(worksheet, pp);

      combinedPPData?.forEach((data, index) => {
        const rowData = [
          data.headquarter,
          `$ ${(data.tnsPPUtility ? data.tnsPPUtility : 0).toLocaleString('es-CO')}`,
          `$ ${(data.tnsPPSale ? data.tnsPPSale : 0).toLocaleString('es-CO')}`,
        ];
        const row = worksheet.addRow(rowData);
        const fillColor = index % 2 === 0 ? 'D2E4FE' : 'FFFFFFFF';
        row.eachCell(cell => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: fillColor },
          };
        });
        if (data.headquarter === 'Totales generales') row.font = { bold: false };
      });
      break;

    default:
      break;
  }

  workbook.xlsx.writeBuffer().then(buffer => {
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `Archivo_de_${title}.xlsx`;
    a.click();
    URL.revokeObjectURL(url);
  });
};