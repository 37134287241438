// Assets
import { Assets } from '../../../../../../assets';

// Components
import FileUploadComponent from '../../../file-upload/file-upload.component';
import ErrorAlertComponent from '../../../../../../shared/components/alerts/error-alert/error-alert.component';
import InformationAlertComponent from '../../../../../../shared/components/alerts/information-alert/information-alert.component';
import SuccessAlertComponent from '../../../../../../shared/components/alerts/success-alert/success-alert.component';

// Libraries
import React, { useState } from 'react';
import { Checkbox, DatePicker, Modal } from 'antd';
import dayjs from 'dayjs';

// Services
import {
  extractProductsByStoreService,
  extractProductsService,
  getDownloadInventoryTemplateService,
  getDownloadProductTemplateService
} from '../../../../../../services/inventory.services';

// Styles
import './file-upload-to-Inventory.component.scss';

const FileUploadToInventoryComponent = (props) => {

  const {
    // Props
    getHeadQuarters,
    updateStateGeneralInventory,
    dateActuality
  } = props;

  const INITIAL_STATE = {
    listOfSites: [],
    checkedItems: [],
    isDiscardAll: false,
    excelCostFile: null,
    excelInventoryFile: null,
    isViewModalOfSites: false,
    statusProgressCost: 'normal',
    statusProgressInventory: 'normal',
    dateSelected: dayjs()
  };

  const [state, setState] = useState(INITIAL_STATE);

  const {
    listOfSites, isDiscardAll, dateSelected,
    isViewModalOfSites, excelCostFile, excelInventoryFile,
    statusProgressCost, statusProgressInventory, checkedItems
  } = state;

  const downloadProductTemplate = async () => {
    try {
      await getDownloadProductTemplateService();
    } catch (error) {
      ErrorAlertComponent();
    }
  };

  const downloadInventoryTemplate = async () => {
    try {
      await getDownloadInventoryTemplateService();
    } catch (error) {
      ErrorAlertComponent();
    }
  };

  const validateFileProcess = async () => {
    try {
      updateStateGeneralInventory({ isLoading: true });
      excelCostFile?.originFileObj && await extractProductsService(excelCostFile?.originFileObj, dayjs(dateSelected).format('YYYY-MM-DD'));
      excelInventoryFile?.originFileObj && await extractProductsByStoreService(excelInventoryFile?.originFileObj, dayjs(dateSelected).format('YYYY-MM-DD'));
      SuccessAlertComponent(undefined, 'Se han cargado los archivos exitosamente');
      if (excelInventoryFile?.originFileObj) {
        getHeadQuarters();
      }else{
        if (dateActuality) {
          getHeadQuarters();
        }else{
          updateStateGeneralInventory({ isLoading: false });
          setState(INITIAL_STATE);
        }
      }
    } catch (error) {
      setTimeout(() => {
        updateStateGeneralInventory({ isLoading: false });
        if (error.code === 'INVY_016' || error.code === 'INVY_017') {
          InformationAlertComponent('Oops!', `No existen registros en el archivo de ${error.code === 'INVY_016' ? excelCostFile?.name : excelInventoryFile?.name}`);
        } else {
          ErrorAlertComponent();
        }
      }, 500);
    }
  };

  const handleCheckboxChange = (value, target, headquarter) => {

    if (target === 'isDiscardAll') {
      setState((prevState) => ({
        ...prevState,
        isDiscardAll: value,
        checkedItems: value ? listOfSites : []
      }));
    };

    if (target === 'isDiscard') {
      const isChecked = checkedItems.includes(headquarter);
      setState((prevState) => ({
        ...prevState,
        isDiscardAll: false,
        checkedItems: isChecked ? checkedItems.filter(item => item !== headquarter) : [...checkedItems, headquarter]
      }));
    };
  };

  const isItemChecked = (headquarter) => checkedItems.includes(headquarter);

  const validateDiscardAll = (isDiscardAll) => checkedItems.length === listOfSites.length || isDiscardAll;

  const closeModal = () => {
    setState((prevState) => ({
      ...prevState,
      checkedItems: [],
      isDiscardAll: false,
      isViewModalOfSites: false,
    }));
  };

  const endAndSave = async () => {
    const headquarterToAvoid = checkedItems.map(item => item.headquarter).join(',');
    try {
      setState((prevState) => ({ ...prevState, isViewModalOfSites: false }));
      updateStateGeneralInventory({ isLoading: true });
      const response = await extractProductsByStoreService(excelInventoryFile.originFileObj, headquarterToAvoid);
      if (response) {
        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            checkedItems: [],
            isDiscardAll: false,
          }));
          getHeadQuarters();
        }, 2000);
      }
    } catch (error) {
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          listOfSites: [],
          checkedItems: [],
          isDiscardAll: false,
          isViewModalOfSites: false,
        }));
        updateStateGeneralInventory({ isLoading: false });
        ErrorAlertComponent();
      }, 500);
    }
  };

  const updateState = (newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }))
  };

  const disabledDate = (current) => {
    return current && current.isAfter(dayjs(), 'day');
};

  return (
    <div className='grid-y align-center-middle file-upload-to-inventory__container'>
      <span className='text-center file-upload-to-inventory__description'>
        Bienvenido al submódulo de <strong>inventario general</strong>, aún no tenemos datos para mostrarte, ayúdanos adjuntando los archivos base
      </span>
      <div className='grid-x align-center file-upload-to-inventory__box'>
        <span className='text-center file-upload-to-inventory__box__text'>
          Adjunta los archivos en formato .xlsx con un peso máximo de 100 MB cada uno. Si no encuentras el formato de los archivos descargados aquí, selecciona la fecha del inventario general.
        </span>
        <div className='grid-x align-center-middle small-12'>
          <DatePicker
            defaultValue={dayjs()}
            placeholder='Fecha'
            allowClear={false}
            disabledDate={(current)=>disabledDate(current)}
            value={dateSelected}
            onChange={(date) => 
              setState((prevState) => (
                { 
                  ...prevState, 
                  dateSelected: date 
                }
              ))
            }
            className='file-upload-to-inventory__date'
          />
        </div>
        <div className='grid-x justify-content-between file-upload-to-inventory__box__container'>
          <div className='grid-x align-center file-upload-to-inventory__box__container__button__content'>
            <label className='file-upload-to-inventory__box__container__text'>
              Descarga aquí la plantilla
            </label>
            <button
              onClick={() => downloadProductTemplate()}
              className='file-upload-to-inventory__box__container__button'
            >
              <img
                src={Assets.SharedIcons.icon_download_white}
                alt='icon_download_white'
                className='file-upload-to-inventory__box__button__icon'
              />
              <span>
                costos.xlsx
              </span>
            </button>
          </div>
          <div className='grid-x align-center file-upload-to-inventory__box__container__button__content'>
            <label className='file-upload-to-inventory__box__container__text'>
              Descarga aquí la plantilla
            </label>
            <button
              onClick={() => downloadInventoryTemplate()}
              className='file-upload-to-inventory__box__container__button'
            >
              <img
                src={Assets.SharedIcons.icon_download_white}
                alt='icon_download_white'
                className='file-upload-to-inventory__box__button__icon'
              />
              <span>
                inventario.xlsx
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className='grid-x justify-content-between file-upload-to-inventory__attach'>
        <FileUploadComponent
          nameDocument="costos"
          typeFile={'costs'}
          isDisabledFile={false}
          excelFile={excelCostFile}
          setUpdateState={updateState}
          statusProgress={statusProgressCost}
        />
        <FileUploadComponent
          nameDocument="inventario"
          typeFile={'inventory'}
          setUpdateState={updateState}
          excelFile={excelInventoryFile}
          isDisabledFile={false}
          statusProgress={statusProgressInventory}
        />
      </div>
      <div className='grid-x align-center-middle file-upload-to-inventory__button-create-inventory__content'>
        <button
          onClick={() => validateFileProcess()}
          className='file-upload-to-inventory__button-create-inventory'
          disabled={statusProgressCost === 'done' || statusProgressInventory === 'done' ? false : true}
        >
          <span className='inventory-control__button-add'>
            Crear nuevo inventario
          </span>
        </button>
      </div>
      {isViewModalOfSites &&
        (<Modal
          centered
          width={500}
          destroyOnClose
          closeIcon={null}
          open={isViewModalOfSites}
          onCancel={() => closeModal()}
          footer={
            <div className='grid-x justify-content-center file-upload-to-inventory__modal__content-footer'>
              <button
                key="cancel"
                onClick={() => closeModal()}
                className='file-upload-to-inventory__modal__button__cancel'
              >
                <span>Cancelar</span>
              </button>
              <button
                key="accept"
                onClick={() => endAndSave()}
                disabled={!checkedItems?.length}
                className='file-upload-to-inventory__modal__button__confirm'
              >
                <span>Finalizar y guardar</span>
              </button>
            </div>
          }
        >
          <div className='grid-x align-center-middle file-upload-to-inventory__modal__content'>
            <div className='grid-x align-center-middle file-upload-to-inventory__modal__content-title'>
              <img
                alt="icon_warning"
                src={Assets.SharedIcons.icon_warning}
              />
              <span className='file-upload-to-inventory__modal__title'>Sedes no identificadas</span>
            </div>
            <span className='file-upload-to-inventory__modal__text'>
              A continuación te compartimos el listado de las sedes que no han sido creadas desde el panel administrativo.
            </span>
            <div className='grid-x align-center-middle file-upload-to-inventory__modal__content-titles'>
              <span className='file-upload-to-inventory__modal__name-site'>
                Nombre de la sede
              </span>
              <div className='grid-x align-center-middle justify-content-end file-upload-to-inventory__modal__discard__content'>
                <span className='file-upload-to-inventory__modal__discard'>
                  Descartar todo
                </span>
                <div className='file-upload-to-inventory__modal__discard__checkbox'>
                  <Checkbox
                    checked={validateDiscardAll(isDiscardAll)}
                    onChange={(e) => handleCheckboxChange(e?.target?.checked, 'isDiscardAll')}
                  />
                </div>
              </div>
            </div>
            <div className='file-upload-to-inventory__modal__info__content'>
              {listOfSites.map((site, index) => {
                return (
                  <div key={index} className='grid-x align-center-middle file-upload-to-inventory__modal__info__column'>
                    <div className='file-upload-to-inventory__modal__info__site__content'>
                      <img
                        alt="icon_home_site"
                        src={Assets.SharedIcons.icon_home_site}
                      />
                      <span className='file-upload-to-inventory__modal__info__site__text'>
                        {site.headquarter}
                      </span>
                    </div>
                    <div className='grid-x align-center-middle justify-content-end file-upload-to-inventory__modal__info__discard__content'>
                      <span className='file-upload-to-inventory__modal__info__discard__text'>Descartar</span>
                      <div className='file-upload-to-inventory__modal__info__discard__checkbox'>
                        <Checkbox
                          checked={isItemChecked(site)}
                          onChange={(e) => handleCheckboxChange(e?.target?.checked, 'isDiscard', site)}
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </Modal>)
      }
    </div>
  )
}

export default FileUploadToInventoryComponent;