//Assets
import { Assets } from "../../../../../assets";

//Components
import ErrorAlertComponent from "../../../../../shared/components/alerts/error-alert/error-alert.component";
import SuccessAlertComponent from "../../../../../shared/components/alerts/success-alert/success-alert.component";
import InformationAlertComponent from "../../../../../shared/components/alerts/information-alert/information-alert.component";

//Libraries
import React, { useEffect, useState } from "react";
import { AutoComplete, Checkbox, Input, Select } from "antd";

//Services
import { getExpenseSubCategoryService } from "../../../../../services/shared.services";
import { 
    createCategoryService, createSubCategoryService, 
    updateCategoryService, updateSubCategoryService, 
    getCategoryExpenseService, getExpenseSubCategoryOplService, 
    subCategoryOplService,
    deleteSubCategoryOplService,
    createRuleProvisionOplService,
    updateRuleProvisionOplService,
    getTypesProvisionService,
    getOperationProvisionService,
    getConstantProvisionService
} from "../../../../../services/administration-panel.services";

//Styles
import "./expense-account.component.scss"
import { optionsSubCategoriesOpl } from "../../../../../utils/administration-panel.utils";

export const ExpenseAccountComponent = () => {

    const INITIAL_STATE = {
        //Category
        listOfCategories: [],
        selectedIdCategory: "",
        isAddCategory: false,
        isEditing: false,
        nameOfCategory: "",
        //Sub-category
        listOfSubCategories: [],
        selectedIdSubCategory: "",
        isAddSubCategory: false,
        isEditingSubCategory: false,
        nameOfSubCategory: "",

        listOfCategoriesOpl: [],
        listOfSubCategoriesOpl: "",
        selectedIdCategoryOpl: "",
        selectedIdSubCategoryOpl: "",
        subCategoryOplSelected: null,
        isEditingSubCategoryOpl: false,
        isAddSubCategoryOpl: false,

        //Provisions
        listOfCategoriesProvisions: [],
        listOfRulesProvisions: [],
        listOfTypes: [],
        listOfOperations: [],
        listOfConstants: [],
        listOptionAutoComplete: [],
        listOptionAutoCompleteCopy: [],
        selectedIdCategoryProvisions: '',
        selectedIdRuleProvisions: '',
        isAddRuleProvisions: false,
        isEditingRuleProvisions: false,
        ruleProvisionsSelected: null
    };

    const [state, setState] = useState(INITIAL_STATE);

    const {
        //Category
        listOfCategories,
        selectedIdCategory,
        isAddCategory,
        isEditing,
        nameOfCategory,
        //Sub-category
        listOfSubCategories,
        selectedIdSubCategory,
        isAddSubCategory,
        isEditingSubCategory,
        nameOfSubCategory,
        listOfCategoriesOpl,
        listOfSubCategoriesOpl,
        selectedIdCategoryOpl,
        selectedIdSubCategoryOpl,
        subCategoryOplSelected,
        isEditingSubCategoryOpl,
        isAddSubCategoryOpl,

        //Provisions
        listOfCategoriesProvisions,
        selectedIdCategoryProvisions,
        listOfRulesProvisions,
        isAddRuleProvisions,
        isEditingRuleProvisions,
        ruleProvisionsSelected,
        listOfTypes,
        listOfOperations,
        listOfConstants,
        selectedIdRuleProvisions,
        listOptionAutoComplete,
        listOptionAutoCompleteCopy
    } = state;

    useEffect(() => {
        getListOfCategories();
    }, [])

    const getListOfCategories = async () => {
        try {
            const responseServices = await Promise.allSettled([
                getCategoryExpenseService(),
                getTypesProvisionService(),
                getOperationProvisionService(),
                getConstantProvisionService()
            ]);

            let respServices = {};
            let errors = false;

            for (let index = 0; index < responseServices.length; index++) {
                const elementResponse = responseServices[index];
                if (elementResponse.status === "fulfilled") {
                    switch (index) {
                        case 0:
                            respServices.listOfCategories = elementResponse?.value?.expenseCategory;
                            respServices.listOfCategoriesOpl = elementResponse?.value?.expenseCategoryOpl;
                            respServices.listOfCategoriesProvisions = elementResponse?.value?.expenseCategoryProvision;
                            break;
                        case 1:
                            respServices.listOfTypes = elementResponse?.value
                            break;
                        case 2:
                            respServices.listOfOperations = elementResponse?.value;
                            break;
                        case 3:
                            respServices.listOfConstants = elementResponse?.value;
                            break;
                        default:
                            break;
                    }
                } else {
                    errors = true
                }
            };

            if (errors) {
                ErrorAlertComponent()
            };

            setState((prevState) => ({
                ...prevState,
                ...respServices
            }));
        } catch (error) {
            ErrorAlertComponent();
        }
    };

    const getListOfSubCategory = async (idExpenseCategory) => {
        try {
            let respListOfSubcategory = await getExpenseSubCategoryService({ idExpenseCategory });
            setState((prevState) => ({
                ...prevState,
                listOfSubCategories: respListOfSubcategory,
                selectedIdCategory: idExpenseCategory
            }))
        } catch (error) {
            ErrorAlertComponent();
        }
    };

    const getListOfSubCategoryOpl = async (idExpenseCategory) => {
        try {
            let respListOfSubcategory = await getExpenseSubCategoryOplService({ idExpenseCategory });
            setState((prevState) => ({
                ...prevState,
                listOfSubCategoriesOpl: respListOfSubcategory,
                selectedIdCategoryOpl: idExpenseCategory
            }))
        } catch (error) {
            ErrorAlertComponent();
        }
    };

    const onChangeField = (data, target) => {
        let value = data && data.target ? data.target.value || '' : data;

        if (target === "nameOfCategory") {
            setState((prevState) => ({
                ...prevState,
                nameOfCategory: value
            }))
        } else {
            setState((prevState) => ({
                ...prevState,
                nameOfSubCategory: value
            }))

        }
    };

    const onClickButtonsCategories = async (target) => {
        if (target === "add") {
            setState((prevState) => ({
                ...prevState,
                isAddCategory: true,
                isEditing: false,
                isAddSubCategory: false
            }))
        }

        if (target === "cancel" || target === "accept") {
            setState((prevState) => ({
                ...prevState,
                isAddCategory: false,
                nameOfCategory: ""
            }))

            if (target === "accept") {
                setState((prevState) => ({
                    ...prevState,
                    isEditing: false
                }))
                createAndUpdateNewCategoryService(nameOfCategory);
            }
        }
    };

    const onClickButtonsSubCategories = async (target) => {
        if (target === "addSubCategory") {
            setState((prevState) => ({
                ...prevState,
                isAddSubCategory: true,
                isEditingSubCategory: false,
                isAddCategory: false
            }))
        }

        if (target === "cancelSubCategory" || target === "acceptSubCategory") {
            setState((prevState) => ({
                ...prevState,
                isAddSubCategory: false,
                nameOfSubCategory: ""
            }))

            if (target === "acceptSubCategory") {
                setState((prevState) => ({
                    ...prevState,
                    isEditingSubCategory: false
                }))
                createAndUpdateNewSubCategoryService(nameOfSubCategory)
            }
        }
    };

    const onClickButtonsSubCategoriesOpl = async (target) => {
        if (target === "addSubCategory") {
            setState((prevState) => ({
                ...prevState,
                isAddSubCategoryOpl: true,
                isEditingSubCategoryOpl: false,
            }))
        }

        if (target === "cancelSubCategory" || target === "acceptSubCategory") {
            setState((prevState) => ({
                ...prevState,
                isAddSubCategoryOpl: false,
                subCategoryOplSelected: null
            }))

            if (target === "acceptSubCategory") {
                setState((prevState) => ({
                    ...prevState,
                    isEditingSubCategoryOpl: false
                }))
                createAndUpdateNewSubCategoryServiceOpl(subCategoryOplSelected)
            }
        }
    };

    const createAndUpdateNewCategoryService = async (name) => {
        try {
            const nameExist = listOfCategories.some(category => category.name.toLowerCase() === name.toLowerCase())
            const nameLength = name.length <= 1
            if (isEditing) {
                if (nameExist) {
                    InformationAlertComponent(undefined, "El nombre de la categoría ya existe");
                    return;
                } else if (nameLength) {
                    InformationAlertComponent(undefined, "El nombre de la categoría debe tener al menos 2 caracteres")
                    return;
                }
                await updateCategoryService({ name, id: selectedIdCategory })
                getListOfCategories();
                SuccessAlertComponent(undefined, "Se ha actualizado la categoría satisfactoriamente");
            } else {
                if (nameExist) {
                    InformationAlertComponent(undefined, "El nombre de la categoría ya existe");
                    return;
                } else if (nameLength) {
                    InformationAlertComponent(undefined, "El nombre de la categoría debe tener al menos 2 caracteres")
                    return;
                }
                await createCategoryService({ name })
                getListOfCategories();
                SuccessAlertComponent(undefined, "Se ha creado la categoría satisfactoriamente");
            }
        } catch (error) {
            ErrorAlertComponent();
        }
    };

    const createAndUpdateNewSubCategoryService = async (name) => {
        try {
            const nameExist = listOfSubCategories.some(category => category.name.toLowerCase() === name.toLowerCase())
            const nameLength = name.length <= 1
            if (isEditingSubCategory) {
                if (nameExist) {
                    InformationAlertComponent(undefined, "El nombre de la Sub-categoría ya existe");
                    return;
                } else if (nameLength) {
                    InformationAlertComponent(undefined, "El nombre de la Sub-categoría debe tener al menos 2 caracteres")
                    return;
                }
                await updateSubCategoryService({ name, id: selectedIdSubCategory })
                getListOfSubCategory(selectedIdCategory);
                SuccessAlertComponent(undefined, "Se ha actualizado la Sub-categoría satisfactoriamente");
            } else {
                if (nameExist) {
                    InformationAlertComponent(undefined, "El nombre de la Sub-categoría ya existe");
                    return;
                } else if (nameLength) {
                    InformationAlertComponent(undefined, "El nombre de la Sub-categoría debe tener al menos 2 caracteres")
                    return;
                }
                await createSubCategoryService({ name, idExpenseCategory: selectedIdCategory })
                getListOfSubCategory(selectedIdCategory);
                SuccessAlertComponent(undefined, "Se ha creado la Sub-categoría satisfactoriamente");
            }
        } catch (error) {
            ErrorAlertComponent();
        }
    };

    const createAndUpdateNewSubCategoryServiceOpl = async (itemSelected) => {
        try {
            const nameExist = listOfSubCategoriesOpl.filter(item => item?.id !== itemSelected?.id).some(category => category.name.toLowerCase() === itemSelected?.name?.toLowerCase())
            const nameLength = itemSelected?.name.length <= 1
            if (itemSelected.hasOwnProperty("id")) {
                if (nameExist) {
                    InformationAlertComponent(undefined, "El nombre de la Sub-categoría ya existe");
                    return;
                } else if (nameLength) {
                    InformationAlertComponent(undefined, "El nombre de la Sub-categoría debe tener al menos 2 caracteres")
                    return;
                }
                await updateSubCategoryService({ 
                    name: itemSelected?.name, 
                    id: selectedIdSubCategoryOpl,
                    typeFunctionality: itemSelected?.typeFunctionality ? 1 : 0
                })

                if (itemSelected?.isWeOwe === null) {
                    await deleteSubCategoryOplService({id:selectedIdSubCategoryOpl})
                }else{
                    await subCategoryOplService({
                        isWeOwe: itemSelected?.isWeOwe ? true : false, 
                        idExpenseSubcategory: selectedIdSubCategoryOpl
                    })
                }

                getListOfSubCategoryOpl(selectedIdCategoryOpl);
                SuccessAlertComponent(undefined, "Se ha actualizado la Sub-categoría satisfactoriamente");
            } else {
                if (nameExist) {
                    InformationAlertComponent(undefined, "El nombre de la Sub-categoría ya existe");
                    return;
                } else if (nameLength) {
                    InformationAlertComponent(undefined, "El nombre de la Sub-categoría debe tener al menos 2 caracteres")
                    return;
                }
                const respId = await createSubCategoryService({
                    name: itemSelected?.name, 
                    idExpenseCategory: selectedIdCategoryOpl,
                    typeFunctionality: itemSelected?.typeFunctionality ? 1 : 0
                })

                if (!itemSelected.hasOwnProperty("isWeOwe") || itemSelected?.isWeOwe === null) {
                    await deleteSubCategoryOplService({id:respId?.id})
                }else{
                    await subCategoryOplService({
                        isWeOwe: itemSelected?.isWeOwe ? true : false, 
                        idExpenseSubcategory: respId?.id
                    })
                }

                getListOfSubCategoryOpl(selectedIdCategoryOpl);
                SuccessAlertComponent(undefined, "Se ha creado la Sub-categoría satisfactoriamente");
            }
        } catch (error) {
            ErrorAlertComponent();
        }
    };

    const getListOfRulesProvisions = async (idExpenseCategory) => {
        try {
            let respListOfRulesProvisions = await getExpenseSubCategoryOplService({ idExpenseCategory });
            const transformedProvisions = respListOfRulesProvisions.map((item) => ({
                ...item,
                category: {
                    id: item?.id,
                    value: item?.name,
                    label: item?.name,

                },
                constant: {
                    value: item?.constant?.id || 0,
                    label: item?.constant?.name || "N/A",
                },
                operation: {
                    value: item?.operation?.id || 0,
                    label: item?.operation?.name || "N/A",
                },
                typeTable: {
                    value: item.typeTable ? item.typeTable : '',
                    label: (item.typeTable ? item.typeTable : '' ).toString(),
                },
            }));

            const listOfAutocomplete = respListOfRulesProvisions.filter((item, index, self) => index === self.findIndex(itemSelf => itemSelf.id === item.id)).map(item => ({
                id: item?.id,
                value: item?.name,
                label: item?.name,
            }));
            
            setState((prevState) => ({
                ...prevState,
                listOptionAutoComplete: listOfAutocomplete,
                listOptionAutoCompleteCopy: listOfAutocomplete,
                listOfRulesProvisions: transformedProvisions,
                selectedIdCategoryProvisions: idExpenseCategory
            }));
        } catch (error) {
            ErrorAlertComponent();
        }
    };

    const createAndUpdateNewRuleProvisionServiceOpl = async (itemSelected) => {
        try {
            if (itemSelected?.hasOwnProperty("id")) {

                await updateSubCategoryService({
                    name: itemSelected?.category?.label || itemSelected?.category,
                    id: selectedIdRuleProvisions,
                    typeFunctionality: 0
                });

                await subCategoryOplService({
                    isWeOwe: true,
                    idExpenseSubcategory: selectedIdRuleProvisions,
                    typeTable: itemSelected?.typeTable?.value
                });

                await updateRuleProvisionOplService({
                    id: itemSelected?.idConstantOperationExpense,
                    idOperationOPL: itemSelected?.operation?.value || null,
                    idConstant: itemSelected?.constant?.value || null,
                    idExpenseSubcategory: selectedIdRuleProvisions
                });

                SuccessAlertComponent(undefined, "Se ha actualizado la regla de provisión satisfactoriamente");
            } else {

                let idSubcategory = itemSelected?.category?.id || '';

                if (typeof itemSelected?.category === 'string') {
                    const existingItem = listOptionAutoCompleteCopy.find(option =>
                        option.label.toLowerCase() === itemSelected.category.toLowerCase()
                    );

                    if (!existingItem) {
                        const { id } = await createSubCategoryService({
                            name: itemSelected.category,
                            idExpenseCategory: selectedIdCategoryProvisions,
                            typeFunctionality: 0
                        });
    
                        idSubcategory = id;
                    } else {
                        idSubcategory = existingItem.id;
                    }
                };
                
                await subCategoryOplService({
                    isWeOwe: true,
                    idExpenseSubcategory: idSubcategory,
                    typeTable: itemSelected?.typeTable?.value
                });

                await createRuleProvisionOplService({
                    idOperationOPL: itemSelected?.operation?.value || null,
                    idConstant: itemSelected?.constant?.value || null,
                    idExpenseSubcategory: idSubcategory
                });

                SuccessAlertComponent(undefined, "Se ha creado la regla de provisión satisfactoriamente");
            };

            setState((prevState) => ({
                ...prevState,
                isAddRuleProvisions: false,
                ruleProvisionsSelected: null,
                isEditingRuleProvisions: false,
            }));

            getListOfRulesProvisions(selectedIdCategoryProvisions);
        } catch (error) {
            if (error.code === 'ADPN015' || error.code === 'ADPN014') {
                ErrorAlertComponent(undefined, 'La constante en esta catageoria ya ha sido asignada');
            } else {
                ErrorAlertComponent();
            }
        }
    };

    const filterCategoryProvisions = (filterText) => {        
        const filteredOptions = listOptionAutoComplete.filter(option =>
            option.label.toLowerCase().includes(filterText.toLowerCase())
        );

        setState((prevState) => ({
            ...prevState,
            listOptionAutoComplete: (filteredOptions?.length) ? filteredOptions : listOptionAutoCompleteCopy,
        }));
    };

    return (
        <>
            <div className="expense-account__container">
                <div className="expense-account__content-box-category">
                    <div className="expense-account__content-title">
                        <img
                            src={Assets.SharedIcons.icon_liquor_blue}
                            alt="icon_sites"
                        />
                        <span className="expense-account__title">
                            Categorías
                        </span>
                    </div>
                    <div className="expense-account__list-titles__content">
                        <span className="expense-account__list-titles__title-name__title-category">
                            Nombre de la categoría
                        </span>
                    </div>
                    <div className="expense-account__content-info">
                        {listOfCategories?.map((item, index) => {
                            return (
                                <div key={index} className={`grid-x expense-account__info-row${selectedIdCategory === item.id ? '__selected' : ''}`}>
                                    <div className="expense-account__info-row__content-title">
                                        <span className="expense-account__info-row__text-site">
                                            {item?.name}
                                        </span>
                                    </div>
                                    <div className="expense-account__info-row__content-edit">
                                        <button
                                            className="expense-account__info-row__content-edit__edit"
                                            onClick={() => {
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    isAddCategory: true,
                                                    isAddSubCategory: false,
                                                    isEditing: true,
                                                    nameOfCategory: item.name,
                                                    selectedIdCategory: item.id
                                                }))
                                                getListOfSubCategory(item.id)
                                            }}
                                        >
                                            <img
                                                src={Assets.SharedIcons.icon_edit}
                                                alt="icon_edit"
                                            />
                                        </button>
                                    </div>
                                    <div className="expense-account__info-row__content-icon">
                                        <button
                                            className="expense-account__info-row__content-icon__icon"
                                            onClick={() => { 
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    isAddCategory: false,
                                                    nameOfCategory: ""
                                                }))
                                                getListOfSubCategory(item.id) 
                                            }}
                                        >
                                            <img
                                                src={selectedIdCategory === item.id ? Assets.SharedIcons.icon_arrow : Assets.SharedIcons.icon_blue_arrow}
                                                alt={selectedIdCategory === item.id ? "icon_arrow" : "icon_blue_arrow"}
                                            />
                                        </button>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {isAddCategory ? (
                        <div className="expense-account__content-input-add">
                            <Input
                                value={nameOfCategory}
                                placeholder="Nombre de la categoría"
                                className="expense-account__content-input-add__input"
                                onChange={(value) => onChangeField(value, "nameOfCategory")}
                            />
                            <div className="expense-account__content-input-add__content-button">
                                <button
                                    onClick={() => { onClickButtonsCategories("cancel") }}
                                    className="expense-account__content-input-add__button-cancel"
                                >
                                    Cancelar
                                </button>
                                <button
                                    onClick={() => { onClickButtonsCategories("accept") }}
                                    className="expense-account__content-input-add__button-accept"
                                >
                                    {isEditing ? "Editar" : "Crear"}
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="grid-x align-middle expense-account__content-button-add">
                            <button
                                onClick={() => { onClickButtonsCategories("add") }}
                            >
                                <span className="expense-account__title-name-add">
                                    Agregar nueva categoría
                                </span>
                                <img
                                    src={Assets.SharedIcons.icon_add_plus}
                                    alt="icon_add_plus"
                                />
                            </button>
                        </div>
                    )}
                </div>
                <div className="expense-account__content-box-subCategory">
                    <div className="expense-account__content-title">
                        <img
                            src={Assets.SharedIcons.icon_liquor_blue}
                            alt="icon_sites"
                        />
                        <span className="expense-account__title">
                            Sub-categorías
                        </span>
                    </div>
                    <div className="expense-account__list-titles__content">
                        <span className="expense-account__list-titles__title-name__title-subcategory">
                            Nombre de la sub-categoría
                        </span>
                        <div className="expense-account__list-titles__content-check">
                            <span className="expense-account__list-titles__title-name__title-subcategory">
                                Check 1
                            </span>
                            <span className="expense-account__list-titles__title-name__title-subcategory">
                                Check 2
                            </span>
                            <span className="expense-account__list-titles__title-name__title-subcategory">
                                Check 3
                            </span>
                        </div>
                    </div>
                    <div className="expense-account__content-info">
                        {!selectedIdCategory ? (
                            <>
                                <span className="expense-account__span-selected">
                                    Selecciona una categoría
                                </span>
                            </>
                        ) : (
                            <>
                                {listOfSubCategories?.map((item, index) => {
                                    return (
                                        <div key={index} className="grid-x expense-account__info-row">
                                            <div className="expense-account__info-row__content-title-subcategory">
                                                <span className="expense-account__info-row__text-site">
                                                    {item.name}
                                                </span>
                                            </div>
                                            <div className="expense-account__info-row__content-check-subcategory">
                                                <Checkbox
                                                    checked=""
                                                    onChange={(e) => {}}
                                                />
                                                <Checkbox
                                                    checked=""
                                                    onChange={(e) => {}}
                                                />
                                                <Checkbox
                                                    checked=""
                                                    onChange={(e) => {}}
                                                />
                                            </div>
                                            <div className="expense-account__info-row__content-edit">
                                                <button
                                                    className="expense-account__info-row__content-edit__edit"
                                                    onClick={() => {
                                                        setState((prevState) => ({
                                                            ...prevState,
                                                            isAddSubCategory: true,
                                                            isAddCategory: false,
                                                            isEditingSubCategory: true,
                                                            nameOfSubCategory: item.name,
                                                            selectedIdSubCategory: item.id
                                                        }))
                                                    }}
                                                >
                                                    <img
                                                        src={Assets.SharedIcons.icon_edit}
                                                        alt="icon_edit"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        )}
                    </div>
                    {isAddSubCategory ? (
                        <div className="expense-account__content-input-add-subCategory">
                            <Input
                                value={nameOfSubCategory}
                                placeholder="Nombre de la sub-categoría"
                                className="expense-account__content-input-add-subCategory__input"
                                onChange={(value) => onChangeField(value, "nameOfSubCategory")}
                            />
                            <div className="expense-account__content-input-add-subCategory__content-button">
                                <button
                                    onClick={() => { onClickButtonsSubCategories("cancelSubCategory")}}
                                    className="expense-account__content-input-add-subCategory__button-cancel"
                                >
                                    Cancelar
                                </button>
                                <button
                                    onClick={() => { onClickButtonsSubCategories("acceptSubCategory")}}
                                    className="expense-account__content-input-add-subCategory__button-accept"
                                >
                                    {isEditingSubCategory ? "Editar" : "Crear"}
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="grid-x align-middle expense-account__content-button-add">
                            <button
                                disabled={selectedIdCategory ? false : true}
                                onClick={() => { onClickButtonsSubCategories("addSubCategory")}}
                            >
                                <span className={`${selectedIdCategory ? 'expense-account__title-name-add' : 'expense-account__title-name-add-disabled'}`}>
                                    Agregar nueva Sub-categoría
                                </span>
                                <img
                                    src={selectedIdCategory ? Assets.SharedIcons.icon_add_plus : Assets.SharedIcons.icon_add}
                                    alt="icon_add_plus"
                                />
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <div className="expense-account__container">
                <div className="expense-account__content-box-category">
                    <div className="expense-account__content-title">
                        <img
                            src={Assets.SharedIcons.icon_liquor_blue}
                            alt="icon_sites"
                        />
                        <span className="expense-account__title">
                            Categorías Opl
                        </span>
                    </div>
                    <div className="expense-account__list-titles__content">
                        <span className="expense-account__list-titles__title-name__title-category">
                            Nombre de la categoría
                        </span>
                    </div>
                    <div className="expense-account__content-info">
                        {listOfCategoriesOpl?.map((item, index) => {
                            return (
                                <div key={index} className={`grid-x justify-content-between expense-account__info-row${selectedIdCategoryOpl === item.id ? '__selected' : ''}`}>
                                    <div className="expense-account__info-row__content-title">
                                        <span className="expense-account__info-row__text-site">
                                            {item?.name}
                                        </span>
                                    </div>
                                    <div className="expense-account__info-row__content-icon">
                                        <button
                                            className="expense-account__info-row__content-icon__icon"
                                            onClick={() => {
                                                onClickButtonsSubCategoriesOpl("cancelSubCategory")
                                                getListOfSubCategoryOpl(item.id)
                                            }}
                                        >
                                            <img
                                                src={selectedIdCategoryOpl === item.id ? Assets.SharedIcons.icon_arrow : Assets.SharedIcons.icon_blue_arrow}
                                                alt={selectedIdCategoryOpl === item.id ? "icon_arrow" : "icon_blue_arrow"}
                                            />
                                        </button>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="expense-account__content-box-subCategory">
                    <div className="expense-account__content-title">
                        <img
                            src={Assets.SharedIcons.icon_liquor_blue}
                            alt="icon_sites"
                        />
                        <span className="expense-account__title">
                            Sub-categorías
                        </span>
                    </div>
                    <div className="expense-account__info-row__opl__content">
                        <span className="expense-account__info-row__opl__title expense-account__info-row__opl">
                            Nombre de la sub-categoría
                        </span>
                        <span className="expense-account__info-row__opl__title expense-account__info-row__opl text-center">
                            Debemos o Nos deben
                        </span>
                        <span className="expense-account__info-row__opl__title expense-account__info-row__opl text-center">
                            Operación
                        </span>
                    </div>
                    <div className="expense-account__content-info">
                        {!listOfSubCategoriesOpl ? (
                            <>
                                <span className="expense-account__span-selected">
                                    Selecciona una categoría
                                </span>
                            </>
                        ) : (
                            <>
                                {listOfSubCategoriesOpl?.map((item, index) => {
                                    return (
                                        <div key={index} className="grid-x expense-account__info-row">
                                            <div className="expense-account__info-row__opl">
                                                <span className="expense-account__info-row__text-site">
                                                    {item?.name}
                                                </span>
                                            </div>
                                            <div className="expense-account__info-row__opl grid-x align-center-middle">
                                                <span className="expense-account__info-row__text-site">
                                                    {item?.isWeOwe !== null ? optionsSubCategoriesOpl[item?.isWeOwe].label : "N/A"}
                                                </span>
                                            </div>
                                            <div className="grid-x align-center-middle expense-account__info-row__opl">
                                                <Checkbox
                                                    disabled
                                                    checked={item?.typeFunctionality}
                                                />
                                            </div>
                                            <div className="expense-account__info-row__content-edit">
                                                <button
                                                    className="expense-account__info-row__content-edit__edit"
                                                    onClick={() => {
                                                        setState((prevState) => ({
                                                            ...prevState,
                                                            isAddSubCategoryOpl: true,
                                                            isEditingSubCategoryOpl: true,
                                                            subCategoryOplSelected: item,
                                                            selectedIdSubCategoryOpl: item.id
                                                        }))
                                                    }}
                                                >
                                                    <img
                                                        src={Assets.SharedIcons.icon_edit}
                                                        alt="icon_edit"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        )}
                    </div>
                    {isAddSubCategoryOpl ? (
                        <div className="expense-account__content-input-add-subCategory">
                            <Input
                                value={subCategoryOplSelected?.name}
                                placeholder="Nombre de la sub-categoría"
                                className="expense-account__info-row__opl__input"
                                onChange={(e) => 
                                    setState((prevState) => ({
                                        ...prevState,
                                        subCategoryOplSelected: {
                                            ...prevState.subCategoryOplSelected,
                                            name: e?.target?.value
                                        }
                                    }))
                                }
                            />
                            <Select
                                defaultValue={optionsSubCategoriesOpl[2]}
                                optionLabelProp="label"
                                popupMatchSelectWidth={false}
                                options={optionsSubCategoriesOpl}
                                className='expense-account__info-row__opl__select'
                                popupClassName='expenses-opl__payments__select__popup'
                                suffixIcon={
                                    <div className='expenses-opl__payments__select__icon'>
                                        <img
                                            alt="icon_blue_arrow"
                                            src={Assets.SharedIcons.icon_blue_arrow}
                                        />
                                    </div>
                                }
                                onChange={(value) => {
                                    setState((prevState) => ({
                                        ...prevState,
                                        subCategoryOplSelected: {
                                            ...prevState.subCategoryOplSelected,
                                            isWeOwe: value === 2 ? null : value
                                        }
                                    }))
                                }}
                                value={subCategoryOplSelected?.isWeOwe !== null ? optionsSubCategoriesOpl[subCategoryOplSelected?.isWeOwe] : optionsSubCategoriesOpl[2]}
                            />
                            <div className="expense-account__info-row__opl__check">
                                <Checkbox
                                    checked={subCategoryOplSelected?.typeFunctionality}
                                    onChange={(e) => {
                                        setState((prevState) => ({
                                            ...prevState,
                                            subCategoryOplSelected: {
                                                ...prevState.subCategoryOplSelected,
                                                typeFunctionality: e.target.checked
                                            }
                                        }))
                                    }}
                                />
                            </div>
                            <div className="expense-account__info-row__opl__buttons">
                                <button
                                    onClick={() => { onClickButtonsSubCategoriesOpl("cancelSubCategory") }}
                                    className="expense-account__info-row__opl__button-cancel"
                                >
                                    <img
                                        className='expense-account__info-row__opl__icon'
                                        src={Assets.SharedIcons.icon_close_blue}
                                        alt="icon_close_blue"
                                    />
                                </button>
                                <button
                                    onClick={() => { onClickButtonsSubCategoriesOpl("acceptSubCategory") }}
                                    className="expense-account__info-row__opl__button-accept"
                                >
                                    <img
                                        className='expense-account__info-row__opl__icon'
                                        alt={isEditingSubCategoryOpl ? 'icon_save_disabled' : 'icon_add_white'}
                                        src={isEditingSubCategoryOpl ? Assets.SharedIcons.icon_save_disabled : Assets.SharedIcons.icon_add_white}
                                    />
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="grid-x align-middle expense-account__content-button-add">
                            <button
                                disabled={listOfSubCategoriesOpl ? false : true}
                                onClick={() => { onClickButtonsSubCategoriesOpl("addSubCategory") }}
                            >
                                <span className={`${listOfSubCategoriesOpl ? 'expense-account__title-name-add' : 'expense-account__title-name-add-disabled'}`}>
                                    Agregar nueva Sub-categoría
                                </span>
                                <img
                                    src={listOfSubCategoriesOpl ? Assets.SharedIcons.icon_add_plus : Assets.SharedIcons.icon_add}
                                    alt="icon_add_plus"
                                />
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <div className="expense-account__container">
                <div className="expense-account__content-box-category">
                    <div className="expense-account__content-title">
                        <img
                            alt="icon_sites"
                            src={Assets.SharedIcons.icon_liquor_blue}
                        />
                        <span className="expense-account__title">
                            Provisiones Opl
                        </span>
                    </div>
                    <div className="expense-account__list-titles__content">
                        <span className="expense-account__list-titles__title-name__title-category">
                            Nombre de la categoría
                        </span>
                    </div>
                    <div className="expense-account__content-info">
                        {listOfCategoriesProvisions?.map((item, index) => {
                            return (
                                <div key={index} className={`grid-x justify-content-between expense-account__info-row${selectedIdCategoryProvisions === item.id ? '__selected' : ''}`}>
                                    <div className="expense-account__info-row__content-title">
                                        <span className="expense-account__info-row__text-site">
                                            {item?.name}
                                        </span>
                                    </div>
                                    <div className="expense-account__info-row__content-icon">
                                        <button
                                            className="expense-account__info-row__content-icon__icon"
                                            onClick={() => getListOfRulesProvisions(item.id)}
                                        >
                                            <img
                                                src={selectedIdCategoryProvisions === item.id ? Assets.SharedIcons.icon_arrow : Assets.SharedIcons.icon_blue_arrow}
                                                alt={selectedIdCategoryProvisions === item.id ? "icon_arrow" : "icon_blue_arrow"}
                                            />
                                        </button>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="expense-account__rules-provisions__box-rules-provisions">
                    <div className="expense-account__rules-provisions__content-title">
                        <img
                            src={Assets.SharedIcons.icon_liquor_blue}
                            alt="icon_sites"
                        />
                        <span className="expense-account__rules-provisions__title">
                            Reglas de provisiones
                        </span>
                    </div>
                    <div className="expense-account__rules-provisions__content-titles-box">
                        <div className="expense-account__rules-provisions__content-category">
                            <span className="expense-account__rules-provisions__title-items">
                                Nombre de la categoria
                            </span>
                        </div>
                        <div className="expense-account__rules-provisions__content-operation">
                            <span className="expense-account__rules-provisions__title-items">
                                operación
                            </span>
                        </div>
                        <div className="expense-account__rules-provisions__content-constants">
                            <span className="expense-account__rules-provisions__title-items">
                                Constantes
                            </span>
                        </div>
                        <div className="expense-account__rules-provisions__content-type">
                            <span className="expense-account__rules-provisions__title-items">
                                Tipo
                            </span>
                        </div>
                    </div>
                    <div className="expense-account__content-info">      
                        {!listOfRulesProvisions.length
                            ? 
                                (<span className="expense-account__span-selected">
                                    Selecciona una categoría
                                </span>) 
                            : 
                                (<>
                                    {listOfRulesProvisions?.map((item, index) => {
                                        return (
                                            <div key={index} className="grid-x expense-account__rules-provisions__content-row-items">
                                                <div className="expense-account__rules-provisions__content-category">
                                                    <span className="expense-account__rules-provisions__text-items-rows">
                                                        {item?.category?.label}
                                                    </span>
                                                </div>
                                                <div className="expense-account__rules-provisions__content-operation">
                                                    <span className="expense-account__rules-provisions__text-items-rows">
                                                        {item?.operation?.label !== null ? item?.operation?.label : "N/A"}
                                                    </span>
                                                </div>
                                                <div className="expense-account__rules-provisions__content-constants">
                                                    <span className="expense-account__rules-provisions__text-items-rows">
                                                        {item?.constant?.label !== null ? item?.constant?.label : "N/A"}
                                                    </span>
                                                </div>
                                                <div className="expense-account__rules-provisions__content-type">
                                                    <span className="expense-account__rules-provisions__text-items-rows">
                                                        {item?.typeTable?.label}
                                                    </span>
                                                </div>
                                                <div className="expense-account__rules-provisions__content-edit">
                                                    <button
                                                        className="expense-account__rules-provisions__content-edit__edit"
                                                        onClick={() => {
                                                            setState((prevState) => ({
                                                                ...prevState,
                                                                isAddRuleProvisions: true,
                                                                isEditingRuleProvisions: true,
                                                                ruleProvisionsSelected: item,
                                                                selectedIdRuleProvisions: item.id
                                                            }))
                                                        }}
                                                    >
                                                        <img
                                                            alt="icon_edit"
                                                            src={Assets.SharedIcons.icon_edit}
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>)
                        }
                    </div>
                    {isAddRuleProvisions
                        ?
                            (<div className="expense-account__rules-provisions__content-row-add-rules-provisions">
                                <div className="expense-account__rules-provisions__content-category expense-account__rules-provisions__padding--first">
                                    <AutoComplete
                                        options={listOptionAutoComplete}
                                        value={ruleProvisionsSelected?.category}
                                        onSearch={(e) => {
                                            const trimmedValue = e.trimStart();
                                            if (trimmedValue) {
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    ruleProvisionsSelected: {
                                                        ...prevState.ruleProvisionsSelected,
                                                        category: trimmedValue
                                                    }
                                                }));
                                                filterCategoryProvisions(trimmedValue);
                                            } else {
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    ruleProvisionsSelected: {
                                                        ...prevState.ruleProvisionsSelected,
                                                        category: ""
                                                    }
                                                }));
                                                filterCategoryProvisions("");
                                            }
                                        }}
                                        onSelect={(value, option) => {
                                            setState((prevState) => ({
                                                ...prevState,
                                                ruleProvisionsSelected: {
                                                    ...prevState.ruleProvisionsSelected,
                                                    category: option
                                                }
                                            }))
                                        }}
                                        className='expense-account__rules-provisions__autocomplete'
                                        popupClassName='expense-account__rules-provisions__input-autocomplete'
                                        onFocus={() => setState((prevState) => ({...prevState,listOptionAutoComplete: listOptionAutoCompleteCopy}))}
                                    >
                                        <Input
                                            placeholder={'Nombre de la categoría'}
                                            className='expense-account__rules-provisions__input'
                                        />
                                    </AutoComplete>
                                </div>
                                <div className="expense-account__rules-provisions__content-operation expense-account__rules-provisions__padding">
                                    <Select
                                        optionLabelProp="label"
                                        placeholder="Operación"
                                        options={listOfOperations}
                                        popupMatchSelectWidth={false}
                                        value={ruleProvisionsSelected?.operation}
                                        className='expense-account__rules-provisions__select'
                                        popupClassName='expenses-opl__payments__select__popup'
                                        onChange={(e, value) => {
                                            setState((prevState) => ({
                                                ...prevState,
                                                ruleProvisionsSelected: {
                                                    ...prevState.ruleProvisionsSelected,
                                                    operation: value
                                                }
                                            }))
                                        }}
                                        suffixIcon={
                                            <div className='expenses-opl__payments__select__icon'>
                                                <img
                                                    alt="icon_blue_arrow"
                                                    src={Assets.SharedIcons.icon_blue_arrow}
                                                />
                                            </div>
                                        }
                                    />
                                </div>
                                <div className="expense-account__rules-provisions__content-constants expense-account__rules-provisions__padding">
                                    <Select
                                        optionLabelProp="label"
                                        placeholder="Constantes"
                                        options={listOfConstants}
                                        popupMatchSelectWidth={false}
                                        value={ruleProvisionsSelected?.constant}
                                        className='expense-account__rules-provisions__select'
                                        popupClassName='expenses-opl__payments__select__popup'
                                        onChange={(e, value) => {
                                            setState((prevState) => ({
                                                ...prevState,
                                                ruleProvisionsSelected: {
                                                    ...prevState.ruleProvisionsSelected,
                                                    constant: value
                                                }
                                            }))
                                        }}
                                        suffixIcon={
                                            <div className='expenses-opl__payments__select__icon'>
                                                <img
                                                    alt="icon_blue_arrow"
                                                    src={Assets.SharedIcons.icon_blue_arrow}
                                                />
                                            </div>
                                        }
                                    />
                                </div>
                                <div className="expense-account__rules-provisions__content-type expense-account__rules-provisions__padding">
                                    <Select
                                        placeholder="Tipo"
                                        options={listOfTypes}
                                        optionLabelProp="label"
                                        popupMatchSelectWidth={false}
                                        value={ruleProvisionsSelected?.typeTable}
                                        className='expense-account__rules-provisions__select'
                                        popupClassName='expenses-opl__payments__select__popup'
                                        onChange={(e, value) => {
                                            setState((prevState) => ({
                                                ...prevState,
                                                ruleProvisionsSelected: {
                                                    ...prevState.ruleProvisionsSelected,
                                                    typeTable: value
                                                }
                                            }))
                                        }}
                                        suffixIcon={
                                            <div className='expenses-opl__payments__select__icon'>
                                                <img
                                                    alt="icon_blue_arrow"
                                                    src={Assets.SharedIcons.icon_blue_arrow}
                                                />
                                            </div>
                                        }
                                    />
                                </div>
                                <div className="expense-account__rules-provisions__buttons">
                                    <button
                                        onClick={() => {
                                            setState((prevState) => ({
                                                ...prevState,
                                                isAddRuleProvisions: false,
                                                ruleProvisionsSelected: null
                                            }))
                                        }}
                                        className="expense-account__rules-provisions__button-cancel"
                                    >
                                        <img
                                            alt="icon_close_blue"
                                            src={Assets.SharedIcons.icon_close_blue}
                                            className='expense-account__rules-provisions__icon"'
                                        />
                                    </button>
                                    <button
                                        className="expense-account__rules-provisions__button-accept"
                                        onClick={() => createAndUpdateNewRuleProvisionServiceOpl(ruleProvisionsSelected)}
                                    >
                                        <img
                                            className='expense-account__rules-provisions__icon"'
                                            alt={isEditingRuleProvisions ? 'icon_save_disabled' : 'icon_add_white'}
                                            src={isEditingRuleProvisions ? Assets.SharedIcons.icon_save_disabled : Assets.SharedIcons.icon_add_white}
                                        />
                                    </button>
                                </div>
                            </div>)
                        :
                            (<div className="grid-x align-middle expense-account__content-button-add">
                                <button
                                    onClick={() => {
                                        setState((prevState) => ({
                                            ...prevState,
                                            isAddRuleProvisions: true,
                                            isEditingRuleProvisions: false
                                        }))
                                    }}
                                    disabled={listOfRulesProvisions.length ? false : true}
                                >
                                    <span className={`${listOfRulesProvisions.length ? 'expense-account__title-name-add' : 'expense-account__title-name-add-disabled'}`}>
                                        Agregar nueva regla
                                    </span>
                                    <img
                                        alt="icon_add_plus"
                                        src={listOfRulesProvisions.length ? Assets.SharedIcons.icon_add_plus : Assets.SharedIcons.icon_add}
                                    />
                                </button>
                            </div>)
                    }
                </div>
            </div>
        </>
    )
}