//Assets
import { Assets } from "../../../../assets";

//Libraries
import { useEffect, useState } from 'react';
import { Input, Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';

//Services
import { getAllWalletOplService } from "../../../../services/wallet-opl.services";

//Styles
import "./table-opl.component.scss";

//Utils
import { formattedNumber } from "../../../../utils/shared.utils";

export const TableOplComponent = (props) => {

  const {
    dateSelected
  } = props;

  const history = useNavigate();
  
  const INITIAL_STATE = {
    headquartersTable: [],
    filterInput: "",
  }

  const [state, setState] = useState(INITIAL_STATE)
  const { headquartersTable, filterInput } = state;

  const [checkedItems, setCheckedItems] = useState([]);

  useEffect(() => {
    getOplWallet(dateSelected);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSelected])

  const getOplWallet = async (date) => {
    try {
      const resp = await getAllWalletOplService(dayjs(date).format("YYYY-MM-DD"), {value: ""});
      setState({ ...state, headquartersTable: resp[0]?.wallets || [] })
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  }

  const handlePortfolioFilter = async(date,value) => {
    try {
      const resp = await getAllWalletOplService(dayjs(date).format("YYYY-MM-DD"), { value }); 
      setState((prevState) => ({
        ...prevState,
        filterInput: value,
        headquartersTable: resp[0]?.wallets || [],
      }))
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  };

  const handleCheckboxChange = (index) => {
    setCheckedItems((prevItems) => {
      const isChecked = prevItems.includes(index);
      return isChecked
        ? prevItems.filter((itemIndex) => itemIndex !== index)
        : [...prevItems, index];
    });
  };

  const isItemChecked = (index) => checkedItems.includes(index);

  
  const detailRoute = (idHeadquarter) => {
    history(`/walletOpl/${idHeadquarter}`, {
      state: {
        dateSelected: dayjs(dateSelected).format("YYYY-MM-DD")
      },
    });
  }

  return (
    <div className="grid-x table-opl__container-headquarters">
      <div className="grid-x small-12 table-opl__content-search">
        <span className="table-opl__content-search__title">
          OPL
        </span>
        <Input
          onChange={(e) => handlePortfolioFilter(dateSelected,e?.target?.value)}
          placeholder="Buscar por sede o negocio"
          className="table-opl__content-search__input"
          prefix={
            <img
              src={Assets.SharedIcons.icon_search_magnifier}
              alt="icon_search_magnifier"
            />
          }
          value={filterInput}
        />
      </div>
      <div className="grid-x small-12 table-opl__content-result">
        <div className="grid-x align-middle table-opl__content-result__container-title">
          <div className="grid-x justify-content-end table-opl__content-result__title__content--mod">
            <span className="table-opl__content-result__title--mod">
              Nombre de la sede o negocio
            </span>
          </div>
          <span className="table-opl__content-result__title__content table-opl__content-result__title">
            Estado
          </span>
          <div className="grid-x table-opl__content-result__title__content">
            <span className="table-opl__content-result__title">
              Total de Gastos
            </span>
            <img
              src={Assets.SharedIcons.icon_falling_graph}
              alt="icon_falling_graph"
            />
          </div>
          <div className="grid-x table-opl__content-result__title__content">
            <span className="table-opl__content-result__title">
              Total de Abonos
            </span>
            <img
              src={Assets.SharedIcons.icon_income_graph}
              alt="icon_income_graph"
            />
          </div>
          <span className="table-opl__content-result__title__content table-opl__content-result__title">
            Total a la fecha
          </span>
        </div>
        <div className="table-opl__content-result__content-info">
          {headquartersTable.map((headquarters, index) => {
            return (
              <div
                key={index}
                className={`grid-x align-middle table-opl__content-result__content-info__information-row${isItemChecked(index) ? "__selected" : ""}`}
              >
                <div className="grid-x table-opl__content-result__content-info__content-fragment--mod">
                  <div className="grid-x align-middle justify-content-center table-opl__content-result__content-info__content-checkbox">
                    <Checkbox
                      checked={isItemChecked(index)}
                      onChange={() => handleCheckboxChange(index)}
                    />
                  </div>
                  <div className="grid-x align-middle table-opl__content-result__content-info__content-location">
                    <span className="table-opl__content-result__content-info__description">{headquarters?.name}</span>
                  </div>
                </div>

                <div className="grid-x table-opl__content-result__content-info__content-status table-opl__content-result__content-info__content-fragment">
                  <div
                    className={`grid-x align-middle justify-content-center table-opl__content-result__content-info__box-status${headquarters?.isActive ? "__active" : "__inactive"}`}
                  >
                    <div
                      className={`table-opl__content-result__content-info__box-status__pin${headquarters?.isActive ? "__active" : "__inactive"}`}
                    />
                    <span>{headquarters?.isActive ? "Activo" : "Inactivo"}</span>
                  </div>
                </div>

                <div className="grid-x table-opl__content-result__content-info__content-fragment">
                  <span className='table-opl__content-result__content-info__description'>{formattedNumber(headquarters?.cashOutflow)}</span>
                </div>
                <div className="grid-x table-opl__content-result__content-info__content-fragment">
                  <span className='table-opl__content-result__content-info__description'>{formattedNumber(headquarters?.cashReceived)}</span>
                </div>
                <div className="grid-x table-opl__content-result__content-info__content-fragment">
                  <span className="table-opl__content-result__content-info__description">{formattedNumber(headquarters?.cashOutflow - headquarters?.cashReceived)}</span>
                </div>
                <div className="grid-x justify-content-end table-opl__content-result__content-info__content-fragment--mod-second">
                  <button
                    className='grid-x align-middle table-opl__content-result__content-info__button' onClick={() => detailRoute(headquarters.id)}>
                    <span>Ver Cartera</span>
                    <img src={Assets.SharedIcons.icon_arrow} alt="icon_arrow" />
                  </button>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
};