//Icons
import icon_publish from './icons/icon_publish.svg'
import icon_publish_disabled from './icons/icon_publish_disabled.svg'
import icon_save from './icons/icon_save.svg'
import icon_result from './icons/icon_result.svg'
import icon_income from './icons/icon_income.svg'
import icon_egress from './icons/icon_egress.svg'
import icon_filter from './icons/icon_filter.svg'
import icon_filter_hover from './icons/icon_filter_hover.svg'
import icon_add from './icons/icon_add.svg'
import icon_add_white from './icons/icon_add_white.svg'
import icon_white_arrow from './icons/icon_white_arrow.svg'
import icon_blue_arrow from './icons/icon_blue_arrow.svg'
import icon_disabled_arrow from './icons/icon_disabled_arrow.svg'
import icon_arrow from './icons/icon_arrow.svg'
import icon_edit from './icons/icon_edit.svg'
import icon_edit_disabled from './icons/icon_edit_disabled.svg'
import icon_cash_closing from './icons/icon_cash_closing.svg'
import icon_information from './icons/icon_information.svg'
import icon_income_blue from './icons/icon_income_blue.svg'
import icon_logo from './icons/icon_logo.png'
import icon_logo_text from './icons/icon_logo_text.svg'
import icon_hour from './icons/icon_hour.svg'
import icon_hour_disabled from './icons/icon_hour_disabled.svg'
import icon_income_graph from './icons/icon_income_graph.svg'
import icon_income_graph_disabled from './icons/icon_income_graph_disabled.svg'
import icon_construction_robot from './icons/icon_construction_robot.svg'
import icon_construction_machine from './icons/icon_construction_machine.svg'
import icon_liquor from './icons/icon_liquor.svg'
import icon_save_hover from './icons/icon_save_hover.svg'
import icon_save_disabled from './icons/icon_save_disabled.svg'
import icon_close from './icons/icon_close.svg'
import icon_report from './icons/icon_report.svg'
import icon_search from './icons/icon_search.svg'
import icon_deleted_active from './icons/icon_deleted_active.svg'
import icon_deleted_disabled from './icons/icon_deleted_disabled.svg'
import icon_arrow_picker_disabled from './icons/icon_arrow_picker_disabled.svg'
import icon_search_magnifier from './icons/icon_search_magnifier.svg'
import icon_falling_graph from './icons/icon_falling_graph.svg'
import icon_gray_arrow from './icons/icon_gray_arrow.svg'
import icon_liquor_blue from './icons/icon_liquor_blue.svg'
import icon_blue_arrow_up from './icons/icon_blue_arrow_up.svg'
import icon_blue_arrow_down from './icons/icon_blue_arrow_down.svg'
import icon_close_blue from './icons/icon_close_blue.svg'
import icon_calendar from './icons/icon_calendar.svg'
import icon_calendar_disabled from './icons/icon_calendar_disabled.svg'
import icon_download_white from './icons/icon_download_white.svg'
import icon_upload from './icons/icon_upload.svg'
import icon_document_excel from './icons/icon_document_excel.svg'
import icon_document_excel_active from './icons/icon_document_excel_active.svg'
import icon_warning from './icons/icon_warning.svg'
import icon_home_site from './icons/icon_home_site.svg'
import icon_download_blue from './icons/icon_download_blue.svg'
import icon_download_blue_hover from './icons/icon_download_blue_hover.svg'
import icon_update from './icons/icon_update.svg'
import icon_inventory_illustration from './icons/icon_inventory_illustration.svg'
import icon_summary from './icons/icon_summary.svg'
import icon_summary_active from './icons/icon_summary_active.svg'
import icon_summary_inactive from './icons/icon_summary_inactive.svg'
import icon_projections_selected from './icons/icon_projections.svg'
import icon_projections_active from './icons/icon_projections_active.svg'
import icon_projections_inactive from './icons/icon_projections_inactive.svg'
import icon_liquor_active from './icons/icon_liquor_active.svg'
import icon_document from "./icons/icon_document.svg"
import icon_phone from './icons/icon_phone.svg'
import icon_upload_disabled from './icons/icon_upload_disabled.svg'
import icon_email_solid from './icons/icon_email_solid.svg'
import icon_key from './icons/icon_key.svg'
import icon_key_active from './icons/icon_key_active.svg'
import icon_key_disabled from './icons/icon_key_disabled.svg'
import icon_trash_bin from './icons/icon_trash_bin.svg'
import icon_add_plus from './icons/icon_add_plus.svg'
import transfers_selected from './icons/transfers_selected.svg'
import transfers_selected_active from './icons/transfers_selected_active.svg'
import transfers_selected_inactive from './icons/transfers_selected_inactive.svg'
import icon_spreadsheet_active from './icons/icon_spreadsheet_active.svg'
import icon_spreadsheet_inactive from './icons/icon_spreadsheet_inactive.svg'
import icon_spreadsheet_hover from './icons/icon_spreadsheet_hover.svg'
import icon_invoice from './icons/icon_invoice.svg'
import icon_invoice_active from './icons/icon_invoice_active.svg'
import icon_invoice_inactive from './icons/icon_invoice_inactive.svg'
import icon_card from './icons/icon_card.svg'
import icon_card_active from './icons/icon_card_active.svg'
import icon_card_inactive from './icons/icon_card_inactive.svg'

//Navbar
import icon_notification from './navbar/icon_notification.svg'
import icon_user from './navbar/icon_user.svg'
import icon_dashboard from './navbar/icon_dashboard.svg'
import icon_money from './navbar/icon_money.svg'
import icon_folder from './navbar/icon_folder.svg'
import icon_folder_active from './navbar/icon_folder_active.svg'
import icon_folder_inactive from './navbar/icon_folder_inactive.svg'
import icon_close_box from './navbar/icon_close_box.svg'
import icon_payroll from './navbar/icon_payroll.svg'
import icon_inventory_control from './navbar/icon_inventory_control.svg'
import icon_inventory_control_active from './navbar/icon_inventory_control_active.svg'
import icon_inventory_control_inactive from './navbar/icon_inventory_control_inactive.svg'
import icon_south_bolivar from './navbar/icon_south_bolivar.svg'
import icon_truck from './navbar/icon_truck.svg'
import icon_adjust from './navbar/icon_adjust.svg'
import icon_log_out from './navbar/icon_log_out.svg'
import icon_bank from './navbar/icon_bank.svg'
import icon_bank_active from './navbar/icon_bank_active.svg'
import icon_bank_inactive from './navbar/icon_bank_inactive.svg'
import icon_authorized_management from "./navbar/icon_authorized_management.svg"
import icon_authorized_management_active from "./navbar/icon_authorized_management_active.svg"
import icon_authorized_management_inactive from "./navbar/icon_authorized_management_inactive.svg"
import icon_headquarters from './navbar/icon_headquarters.svg'
import icon_headquarters_active from './navbar/icon_headquarters_active.svg'
import icon_headquarters_inactive from './navbar/icon_headquarters_inactive.svg'
import icon_opl from './navbar/icon_folder.svg'

//Alerts
import icon_alert_error from './alerts/icon_alert_error.svg'
import icon_alert_success from './alerts/icon_alert_success.svg'
import icon_alert_info from './alerts/icon_alert_info.svg'

export const SharedIcons = {
    icon_notification,
    icon_user,
    icon_dashboard,
    icon_money,
    icon_folder,
    icon_close_box,
    icon_payroll,
    icon_inventory_control,
    icon_inventory_control_active,
    icon_inventory_control_inactive,
    icon_south_bolivar,
    icon_truck,
    icon_adjust,
    icon_log_out,
    icon_publish,
    icon_save,
    icon_result,
    icon_egress,
    icon_income,
    icon_filter,
    icon_add,
    icon_white_arrow,
    icon_arrow,
    icon_edit,
    icon_cash_closing,
    icon_information,
    icon_income_blue,
    icon_logo,
    icon_logo_text,
    icon_blue_arrow,
    icon_disabled_arrow,
    icon_hour,
    icon_hour_disabled,
    icon_income_graph,
    icon_construction_robot,
    icon_construction_machine,
    icon_liquor,
    icon_save_hover,
    icon_close,
    icon_report,
    icon_save_disabled,
    icon_publish_disabled,
    icon_edit_disabled,
    icon_income_graph_disabled,
    icon_search,
    icon_deleted_active,
    icon_deleted_disabled,
    icon_arrow_picker_disabled,
    icon_search_magnifier,
    icon_falling_graph,
    icon_bank,
    icon_add_white,
    icon_filter_hover,
    icon_gray_arrow,
    icon_liquor_blue,
    icon_blue_arrow_up,
    icon_blue_arrow_down,
    icon_close_blue,
    icon_calendar,
    icon_calendar_disabled,
    icon_alert_error,
    icon_alert_success,
    icon_download_white,
    icon_upload,
    icon_document_excel,
    icon_alert_info,
    icon_document_excel_active,
    icon_warning,
    icon_home_site,
    icon_download_blue,
    icon_download_blue_hover,
    icon_update,
    icon_inventory_illustration,
    icon_authorized_management,
    icon_headquarters,
    icon_summary,
    icon_summary_active,
    icon_summary_inactive,
    icon_projections_selected,
    icon_projections_active,
    icon_projections_inactive,
    icon_headquarters_inactive,
    icon_liquor_active,
    icon_document,
    icon_phone,
    icon_headquarters_active,
    icon_upload_disabled,
    icon_email_solid,
    icon_key,
    icon_key_active,
    icon_key_disabled,
    icon_trash_bin,
    icon_add_plus,
    transfers_selected,
    transfers_selected_active,
    transfers_selected_inactive,
    icon_spreadsheet_active,
    icon_spreadsheet_inactive,
    icon_spreadsheet_hover,
    icon_folder_active,
    icon_folder_inactive,
    icon_invoice,
    icon_invoice_active,
    icon_invoice_inactive,
    icon_bank_active,
    icon_bank_inactive,
    icon_authorized_management_active,
    icon_authorized_management_inactive,
    icon_card,
    icon_card_active,
    icon_card_inactive,
    icon_opl
}