// Actions
import { setStateQuotationOplReducer } from "../../../../storage/reducers/quotation-opl/quotation-opl.actions";

// Assets
import { Assets } from "../../../../assets";

// Components - Shared
import ErrorAlertComponent from '../../../../shared/components/alerts/error-alert/error-alert.component';

// Components
import { OweUsOplComponent } from "../owe-us-opl/owe-us-opl.component";
import { WeOweToOplComponent } from "../we-owe-to-opl/we-owe-to-opl.component";

// Libraries
import dayjs from 'dayjs';
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

// Services
import {
    getGeneralAdjustmentOplService,
    getMonthClosingGeneralAdjustmentOplService,
    getTotalizerOfGeneralAdjustmentOplService
} from '../../../../services/quotation-opl.services';

// Styles
import "./general-adjustment-opl.component.scss";

const GeneralAdjustmentOpl = (props) => {

    const {
        // Variables - Props
        selectedMonthSummaryProp,
        isClosingGeneralAdjustmentProp,

        // Functions - Props
        setIsClosingGeneralAdjustmentProp = () => null,

        // Variables - Redux
        isViewTotalizerReducer,

        // Functions - Redux
        setStateQuotationOplReducer
    } = props;

    const { state: locationState } = useLocation();

    const INITIAL_STATE = {
        totalizersState: undefined,
        generalAdjustmentOplState: undefined,
        totalWeUs: 0,
        totalWeOwe: 0,
        weUsAdditional: [],
        weOweAdditional: [],
        monthClosingsState: [],
    };

    const [state, setState] = useState(INITIAL_STATE);

    const { 
        generalAdjustmentOplState, totalWeUs, totalWeOwe,
        weOweAdditional, weUsAdditional, totalizersState,
        monthClosingsState
    } = state;

    useEffect(() => {
        if (selectedMonthSummaryProp) {
            getInfoGeneralAdjustmentOpl();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMonthSummaryProp, isClosingGeneralAdjustmentProp]);

    useEffect(() => {
        getTotalizersAndMonthClosing();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isClosingGeneralAdjustmentProp, selectedMonthSummaryProp]);

    const getInfoGeneralAdjustmentOpl = async () => {
        try {
            const responseGeneralAdjustmentOpl = await getGeneralAdjustmentOplService(locationState?.opl?.id, dayjs(selectedMonthSummaryProp)?.format("YYYY-MM"));
            const totalWeUsData = responseGeneralAdjustmentOpl?.weOweUs?.data?.reduce((total, item) => total + Number(item.value), 0);
            const totalWeOweData = responseGeneralAdjustmentOpl?.theyOweUs?.data?.reduce((total, item) => total + Number(item.value), 0);
            const weUsAdditionalData = responseGeneralAdjustmentOpl?.weOweUs?.additional?.reduce((total, item) => total + Number(item.price), 0);
            const weOweAdditionalData = responseGeneralAdjustmentOpl?.theyOweUs?.additional?.reduce((total, item) => total + Number(item.price), 0);
            const weUsAdditional = responseGeneralAdjustmentOpl?.weOweUs?.additional;
            const weOweAdditional = responseGeneralAdjustmentOpl?.theyOweUs?.additional;
            const getMonthClosing = await getMonthClosingGeneralAdjustmentOplService(locationState?.opl?.id, dayjs(selectedMonthSummaryProp)?.format("YYYY-MM-DD"))
            const totalMonthClosing = getMonthClosing?.months ? getMonthClosing?.months?.reduce((total, item) => total + Number(item.value), 0) : 0;

            setState((prevState) => ({
                ...prevState,
                generalAdjustmentOplState: responseGeneralAdjustmentOpl,
                totalWeUs: totalWeUsData + weUsAdditionalData,
                totalWeOwe: totalWeOweData + weOweAdditionalData + totalMonthClosing,
                weUsAdditional,
                weOweAdditional
            }))
        } catch (error) {
            ErrorAlertComponent()
        }
    };

    const getTotalizersAndMonthClosing = async () => {
        try {
            const responseServices = await Promise.allSettled([
                getTotalizerOfGeneralAdjustmentOplService(locationState?.opl?.id, dayjs(selectedMonthSummaryProp)?.format("YYYY-MM-DD")),
                getMonthClosingGeneralAdjustmentOplService(locationState?.opl?.id, dayjs(selectedMonthSummaryProp)?.format("YYYY-MM-DD"))
            ]);

            let respServices = {};
            let errors = false;

            for (let index = 0; index < responseServices.length; index++) {
                const elementResponse = responseServices[index];
                if (elementResponse.status === "fulfilled") {
                    switch (index) {
                        case 0:
                            respServices.totalizersState = elementResponse?.value;
                            setStateQuotationOplReducer('isViewTotalizerReducer', elementResponse?.value?.isPublished)
                            break;
                        case 1:
                            respServices.monthClosingsState = elementResponse?.value
                            break;
                        default:
                            break;
                    }
                } else {
                    errors = true
                }
            };

            if (errors) {
                ErrorAlertComponent()
            };

            setState((prevState) => ({
                ...prevState,
                ...respServices
            }));
        } catch (error) {
            ErrorAlertComponent()
        } finally {
            setIsClosingGeneralAdjustmentProp();
        }
    };

    const handleChangeGet = async() => {
        await getInfoGeneralAdjustmentOpl()
    }

    const ObservationList = ({ data }) => (
        <div className='general-adjustment-opl__summary__observations__container'>
            {data.map((item, index) => (
                <div 
                    key={index}
                    className='general-adjustment-opl__summary__observations__item-row' 
                >
                    <div className='general-adjustment-opl__summary__observations__item-title__content'>
                        <span className='general-adjustment-opl__summary__observations__item-title'>
                            {item.name}
                        </span>
                    </div>
                    <div className='general-adjustment-opl__summary__observations__item-price'>
                        <NumericFormat
                            disabled
                            type='text'
                            prefix={"$ "}
                            placeholder='$ 0'
                            decimalScale={2}
                            value={item.total}
                            thousandSeparator=","
                            className='input-price'
                            fixedDecimalScale={true}
                            thousandsGroupStyle="thousand"
                        />
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <div className='grid-x small-12 general-adjustment-opl__content-tables'>
            <div className="grid-x justify-content-between general-adjustment-opl__summary">
                <div className='grid-x justify-content-between general-adjustment-opl__summary__box'>
                    <div className='general-adjustment-opl__summary__box__sub-box'>
                        <span className='general-adjustment-opl__summary__text'>Nos Deben</span>
                        <div className='grid-x general-adjustment-opl__payments__table__content-inputs--mod'>
                            <NumericFormat
                                type='text'
                                prefix={"$ "}
                                placeholder='$ 0'
                                value={totalWeUs}
                                thousandSeparator=","
                                className='input-price'
                                thousandsGroupStyle="thousand"
                                disabled
                            />
                            <div className='general-adjustment-opl__payments__table__content-icon-price'>
                                <img
                                    className="general-adjustment-opl__summary__price__icon"
                                    alt="icon_income"
                                    src={Assets.SharedIcons.icon_income}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='general-adjustment-opl__summary__box__sub-box'>
                        <span className='general-adjustment-opl__summary__text'>Debemos</span>
                        <div className='grid-x general-adjustment-opl__payments__table__content-inputs--mod'>
                            <NumericFormat
                                type='text'
                                prefix={"$ "}
                                placeholder='$ 0'
                                thousandSeparator=","
                                value={totalWeOwe}
                                className='input-price'
                                thousandsGroupStyle="thousand"
                                disabled
                            />
                            <div className='general-adjustment-opl__payments__table__content-icon-price'>
                                <img
                                    className="general-adjustment-opl__summary__price__icon"
                                    alt="icon_egress"
                                    src={Assets.SharedIcons.icon_egress}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='general-adjustment-opl__summary__box__sub-box'>
                        <span className='general-adjustment-opl__summary__text'>Sobrante/Faltante</span>
                        <div className='grid-x general-adjustment-opl__payments__table__content-inputs--mod'>
                            <NumericFormat
                                type='text'
                                prefix={"$ "}
                                value={totalWeUs - totalWeOwe}
                                placeholder='$ 0'
                                thousandSeparator=","
                                className='input-price'
                                thousandsGroupStyle="thousand"
                                disabled
                            />
                            <div className='general-adjustment-opl__payments__table__content-icon-price'>
                                <img
                                    alt="icon_add"
                                    src={Assets.SharedIcons.icon_add}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {isViewTotalizerReducer
                    ?
                        (<div className="grid-x justify-content-between general-adjustment-opl__summary__box">
                            <ObservationList data={totalizersState?.structureExpense || []}/>
                            <ObservationList data={totalizersState?.structureUtility || []} />
                            <div className='general-adjustment-opl__summary__observations__container--mod'>
                                <div className='general-adjustment-opl__summary__observations__item-price-total'>
                                    <NumericFormat
                                        disabled
                                        type='text'
                                        prefix={"$ "}
                                        placeholder='$ 0'
                                        decimalScale={2}
                                        thousandSeparator=","
                                        className='input-price'
                                        fixedDecimalScale={true}
                                        thousandsGroupStyle="thousand"
                                        value={totalizersState?.structureUtility?.[0]?.total - totalizersState?.structureExpense?.[0]?.total}
                                    />
                                </div>
                            </div>
                        </div>)
                    :   null
                }
            </div>
            <div className='grid-x small-6 align-content-start'>
                <div className='grid-x align-center-middle general-adjustment-opl__content-title-table'>
                    <span className='general-adjustment-opl__title-table'>
                        {generalAdjustmentOplState?.weOweUs?.name || 'NOS DEBEN A NOSOTROS'}
                    </span>
                </div>
                <div className='general-adjustment-opl__box-container'>
                    {generalAdjustmentOplState?.weOweUs?.data.map((item, index) => (
                        <div
                            key={index}
                            className='grid-x align-middle justify-content-between general-adjustment-opl__content-row'
                        >
                            <div className='general-adjustment-opl__content-row__title__content'>
                                <span className='general-adjustment-opl__content-row__title'>
                                    {item.name.toLowerCase()}
                                </span>
                            </div>
                            <div className='general-adjustment-opl__content-row__content-value'>
                                <NumericFormat
                                    disabled
                                    type='text'
                                    prefix={"$ "}
                                    placeholder='$ 0'
                                    decimalScale={2}
                                    thousandSeparator=","
                                    className='input-price'
                                    fixedDecimalScale={true}
                                    value={item?.value || 0}
                                    thousandsGroupStyle="thousand"
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <OweUsOplComponent
                    data={weUsAdditional}
                    date={selectedMonthSummaryProp}
                    handleChangeGet={()=>handleChangeGet()}
                />
            </div>
            <div className='grid-x small-6 align-right align-content-start'>
                <div className='grid-x align-center-middle general-adjustment-opl__content-title-table'>
                    <span className='general-adjustment-opl__title-table'>
                        {generalAdjustmentOplState?.theyOweUs?.name || 'CRÉDITOS O DEBEMOS'}
                    </span>
                </div>
                <div className='general-adjustment-opl__box-container'>
                    <div className='general-adjustment-opl__container-scroll'>
                    {generalAdjustmentOplState?.theyOweUs?.data.map((item, index) => (
                        <div key={index} className='grid-x align-middle justify-content-between general-adjustment-opl__content-row'>
                            <div className='general-adjustment-opl__content-row__title__content'>
                                <span className='general-adjustment-opl__content-row__title'>
                                    {item.name.toLowerCase()}
                                </span>
                            </div>
                            <div className='general-adjustment-opl__content-row__content-value'>
                                <NumericFormat
                                    disabled
                                    type='text'
                                    prefix={"$ "}
                                    placeholder='$ 0'
                                    decimalScale={2}
                                    thousandSeparator=","
                                    className='input-price'
                                    fixedDecimalScale={true}
                                    value={item?.value || 0}
                                    thousandsGroupStyle="thousand"
                                />
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
                <WeOweToOplComponent
                    data={weOweAdditional}
                    date={selectedMonthSummaryProp}
                    handleChangeGet={()=>handleChangeGet()}
                />
                <div className='general-adjustment-opl__box-container'>
                    <div className='general-adjustment-opl__title-header-box--content'>
                        <img
                            alt="icon_information"
                            src={Assets.SharedIcons.icon_information}
                        />
                        <span className='general-adjustment-opl__title-header-box'>
                            {monthClosingsState?.name}
                        </span>
                    </div>
                    <div className='general-adjustment-opl__container-list-month'>
                        {monthClosingsState?.months?.map((item, index) => (
                            <div key={index} className='grid-x align-middle justify-content-between general-adjustment-opl__content-row'>
                                <div className='general-adjustment-opl__content-row__title__content'>
                                    <span className='general-adjustment-opl__content-row__title'>
                                        {item?.name}
                                    </span>
                                </div>
                                <div className='general-adjustment-opl__content-row__content-value'>
                                    <NumericFormat
                                        disabled
                                        type='text'
                                        prefix={"$ "}
                                        placeholder='$ 0'
                                        decimalScale={2}
                                        thousandSeparator=","
                                        className='input-price'
                                        fixedDecimalScale={true}
                                        value={item?.value || 0}
                                        thousandsGroupStyle="thousand"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = ({ QuotationOplReducer }) => {
    const { isViewTotalizerReducer } = QuotationOplReducer;

    return {
        isViewTotalizerReducer
    };
};

const mapStateToPropsActions = {
    setStateQuotationOplReducer
};

export const GeneralAdjustmentOplComponent = connect(mapStateToProps, mapStateToPropsActions)(GeneralAdjustmentOpl);