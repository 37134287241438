// Assets
import { Assets } from "../../../assets";

// Components - Shared
import NoInformationResultsComponent from "../../../shared/components/no-information-results/no-information-results.component";
import ErrorAlertComponent from "../../../shared/components/alerts/error-alert/error-alert.component";

// Libraries
import dayjs from 'dayjs';
import { Checkbox, DatePicker, Input, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

// Services
import { getOplService } from "../../../services/quotation-opl.services";

// Styles
import "./quotation-opl.page.scss";

export const QuotationOplPage = () => {

  const history = useNavigate();

  const INITIAL_STATE = {
    isLoading: true,
    selectedMonth: dayjs(),
    checkedItems: [],
    headquartersList: [],
    headquartersListCopy: []
  }

  const [state, setState] = useState(INITIAL_STATE)

  const {
    isLoading,
    selectedMonth,
    isHoverName,
    checkedItems,
    headquartersList,
    headquartersListCopy
  } = state;

  useEffect(() => {
    getAllHeadquarters();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAllHeadquarters = async () => {
    try {
      setState((prevState) => ({...prevState, isLoading: true}))
      const getHeadquarters = await getOplService()
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        headquartersList: getHeadquarters,
        headquartersListCopy: getHeadquarters
      }));
    } catch (error) {
      ErrorAlertComponent();
      setState((prevState) => ({...prevState, isLoading: false}))
    }
  }

  const goToMonth = (month) => {
    const newDate = dayjs(selectedMonth).add(month, 'month');
    const currentDate = dayjs();

    if (newDate.isBefore(currentDate, 'month') || newDate.isSame(currentDate, 'month')) {
      setState((prevState) => ({
        ...prevState,
        selectedMonth: newDate
      }))
    }
  };

  const filterHeadquarterName = (data) => {
    let value = data && data.target ? data.target.value || '' : data;
    const filteredList = value.trim() !== ''
      ? headquartersListCopy.filter(headquarter => headquarter.name.toLowerCase().includes(value.toLowerCase()))
      : headquartersListCopy;
    
    setState({
      ...state,
      headquartersList: filteredList
    });
    optionFilter('name', filteredList)
  };

  const isSortedAscendingByAttribute = (list, attributeName) => {
    return list.every((item, index) => index === 0 || item[attributeName] >= list[index - 1][attributeName]);
  }

  const optionFilter = (nameFilter, headquarters) => {
    let sortedHeadQuarters = [...headquarters];
    const isSorted = isSortedAscendingByAttribute(headquarters, nameFilter);
    const sortOrder = isSorted ? 1 : -1;
    if(nameFilter === 'name'){
      sortedHeadQuarters.sort((a, b) => (sortOrder * (b.name.localeCompare(a.name)) ));
    }else{
      sortedHeadQuarters.sort((a, b) => (nameFilter ? sortOrder * (b[nameFilter] - a[nameFilter]) : 0));
    }
    
    setState((prevState) => ({
      ...prevState,
      headquartersList: sortedHeadQuarters,
    }));
  };

  const handleCheckboxChange = (index) => {

    const prevItem = checkedItems.includes(index) ? checkedItems.filter(itemIndex => itemIndex !== index) : [...checkedItems, index];

    setState((prevState) => ({
      ...prevState,
      checkedItems: prevItem
    }))
  };

  const isItemChecked = (index) => checkedItems.includes(index);

  const viewAndEditSummary = async (headquarter) => {
    try {
      history(`/oplAdjustment/${headquarter?.id}`, { state: { selectedMonth: dayjs(selectedMonth).format('YYYY-MM-DD'), opl: headquarter } });
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  }

  return (
    <div className='quotation-opl__container'>
      <div className='grid-x align-content-end justify-content-between quotation-opl__content-search'>
        <div className='grid-x align-middle quotation-opl__container-search'>
          <span className='quotation-opl__title'>Cuadre de OPL</span>
          <Input
            className='quotation-opl__content-search__input'
            onChange={(value) => filterHeadquarterName(value)}
            placeholder='Buscar sede'
            prefix={<img src={Assets.SharedIcons.icon_search_magnifier} alt="icon_search_magnifier" />}
          />
        </div>
        <div className='grid-x align-right quotation-opl__content-date-picker'>
          <div className='grid-x align-middle justify-content-between quotation-opl__content-date-picker__date'>
            <button onClick={() => goToMonth(-1)}>
              <img
                alt='icon_arrow'
                src={Assets.SharedIcons.icon_arrow}
                className='quotation-opl__content-date-picker__arrow'
              />
            </button>
            <DatePicker
              picker='month'
              bordered={false}
              suffixIcon={null}
              allowClear={false}
              inputReadOnly={true}
              value={selectedMonth}
              defaultValue={dayjs()}
              disabledDate={(current) => current.isAfter(dayjs(), 'month')}
              onChange={(date) => setState((prevState) => ({ ...prevState, selectedMonth: date }))}
              className='quotation-opl__content-date-picker__month'
              format={(value) => {
                return `${dayjs(value)?.format('MMMM, YYYY')}`;
              }}
            />
            <button
              onClick={() => goToMonth(1)}
              className='quotation-opl__content-date-picker__button-next'
              disabled={(dayjs(selectedMonth).add(1, 'month').isAfter(dayjs(), 'month'))}
            >
              <img
                alt={(dayjs(selectedMonth).add(1, 'month').isAfter(dayjs(), 'month')) ? 'icon_arrow_picker_disabled' : 'icon_arrow'}
                src={(dayjs(selectedMonth).add(1, 'month').isAfter(dayjs(), 'month')) ? Assets.SharedIcons.icon_arrow_picker_disabled : Assets.SharedIcons.icon_arrow}
              />
            </button>
          </div>
        </div>
      </div>
      <div className="grid-x align-center-middle">
        <div className='grid-x quotation-opl__container-headquarters'>
          <div className='grid-x small-12 quotation-opl__content-result'>
            <div className='grid-x align-middle quotation-opl__content-result__container-title'>
              <div className='grid-x justify-content-end quotation-opl__content-result__title__content'>
                <button
                  onClick={() => optionFilter('name', headquartersList)}
                  onMouseOut={() => setState({ ...state, isHoverName: false })}
                  className='grid-x align-middle quotation-opl__content-search__select-headquarter__content-selected'
                >
                  <img
                    alt={isHoverName ? "icon_filter_hover" : "icon_filter"}
                    src={isHoverName ? Assets.SharedIcons.icon_blue_arrow_down : Assets.SharedIcons.icon_blue_arrow}
                    className={`grid-x align-middle quotation-opl__content-search__select-headquarter__filter-icon${isSortedAscendingByAttribute(headquartersList, 'name') ? '__active' : ''}`}
                  />
                  <span className={`quotation-opl__content-search__select-headquarter__text${isHoverName ? '__hover' : ''}`}>
                    Nombre de la OPL
                  </span>
                </button>
              </div>
              <div className='grid-x quotation-opl__content-result__title__content--mod'>
                <span className='quotation-opl__content-result__title'>Acciones</span>
              </div>
            </div>
            <div className='quotation-opl__content-result__content-info'>
              {isLoading
                ? (<Spin size='large' className='quotation-opl__spinner' />)
                : headquartersList?.length
                  ? (headquartersList?.map((headquarters, index) => {
                    return (
                      <div key={index} className={`grid-x align-middle quotation-opl__content-result__content-info__information-row${isItemChecked(index) ? '__selected' : ''}`}>
                        <div className='grid-x quotation-opl__content-result__content-info__content-fragment'>
                          <div className='grid-x align-middle justify-content-center quotation-opl__content-result__content-info__content-checkbox'>
                            <div className='quotation-opl__content-result__content-info__checkbox'>
                              <Checkbox checked={isItemChecked(index)} onChange={() => handleCheckboxChange(index)} />
                            </div>
                          </div>
                          <div className='grid-x align-middle quotation-opl__content-result__content-info__content-location'>
                            <span className='quotation-opl__content-result__content-info__description'>
                              {headquarters?.name}
                            </span>
                          </div>
                        </div>
                        <div className='grid-x quotation-opl__content-result__content-info__content-fragment--mod'>
                          <button
                            className='grid-x align-middle quotation-opl__content-result__content-info__button'
                            onClick={() => viewAndEditSummary(headquarters)}
                          >
                            <span>Ver y editar resumen</span>
                            <img src={Assets.SharedIcons.icon_arrow} alt="icon_arrow" />
                          </button>
                        </div>
                      </div>
                    )
                  }))
                  : (<NoInformationResultsComponent />)
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
