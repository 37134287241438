//Components
import WeekSelectorComponent from "../../components/week-selector/week-selector.component";
import { TableOplComponent } from "../components/table-opl/table-opl.component";

//Libraries
import { useState } from "react";
import dayjs from 'dayjs';

//Styles
import "./opl-wallet.page.scss";

export const WalletOplPage = () => {

  const INITIAL_STATE = {
    dateSelected: dayjs(),
    daySelected: dayjs(),
  };

  const [state, setState] = useState(INITIAL_STATE);
  const { dateSelected, daySelected } = state;

  const handleDate = (date) => {
    setState({
      ...state,
      dateSelected: date,
    });
  };

  const selectDay = (day) => {
    setState({
      ...state,
      daySelected: day,
    });
  };

  return (
    <div className="opl-wallet">
      <WeekSelectorComponent
        handleDate={(date) => handleDate(date)}
        previousDate={() => handleDate(dateSelected.subtract(1, "year"))}
        nextDate={() => handleDate(dateSelected.add(1, "year"))}
        previousWeek={() => handleDate(dateSelected.subtract(1, "week"))}
        nextWeek={() => handleDate(dateSelected.add(1, "week"))}
        selectDay={(date) => selectDay(date)}
        dateSelected={dateSelected}
        daySelected={daySelected}
        typeDate="año"
      />
      <TableOplComponent
        dateSelected={daySelected}
      />
    </div>
  )
};
