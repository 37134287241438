export const dataDefaultExpensesOpl = [
    {
        type: "",
        date: null,
        price: "",
        concept: "",
        disabled: true,
        category: {
            label: "",
            value: "",
        },
        subCategory: {
            label: "",
            value: "",
        },
        disabledSubCategory: true,
        listOfExpenseSubCategory: [],
        isEdit: false,
        operation: {
            label: "",
            value: "",
        },
    },
    {
        type: "",
        date: null,
        price: "",
        concept: "",
        disabled: true,
        category: {
            label: "",
            value: "",
        },
        subCategory: {
            label: "",
            value: "",
        },
        disabledSubCategory: true,
        listOfExpenseSubCategory: [],
        isEdit: false,
        operation: {
            label: "",
            value: "",
        },
    },
    {
        type: "",
        date: null,
        price: "",
        concept: "",
        disabled: true,
        category: {
            label: "",
            value: "",
        },
        subCategory: {
            label: "",
            value: "",
        },
        disabledSubCategory: true,
        listOfExpenseSubCategory: [],
        isEdit: false,
        operation: {
            label: "",
            value: "",
        },
    },
    {
        type: "",
        date: null,
        price: "",
        concept: "",
        disabled: true,
        category: {
            label: "",
            value: "",
        },
        subCategory: {
            label: "",
            value: "",
        },
        disabledSubCategory: true,
        listOfExpenseSubCategory: [],
        isEdit: false,
        operation: {
            label: "",
            value: "",
        },
    },
    {
        type: "",
        date: null,
        price: "",
        concept: "",
        disabled: true,
        category: {
            label: "",
            value: "",
        },
        subCategory: {
            label: "",
            value: "",
        },
        disabledSubCategory: true,
        listOfExpenseSubCategory: [],
        isEdit: false,
        operation: {
            label: "",
            value: "",
        },
    },
    {
        type: "",
        date: null,
        price: "",
        concept: "",
        disabled: true,
        category: {
            label: "",
            value: "",
        },
        subCategory: {
            label: "",
            value: "",
        },
        disabledSubCategory: true,
        listOfExpenseSubCategory: [],
        isEdit: false,
        operation: {
            label: "",
            value: "",
        },
    },
    {
        type: "",
        date: null,
        price: "",
        concept: "",
        disabled: true,
        category: {
            label: "",
            value: "",
        },
        subCategory: {
            label: "",
            value: "",
        },
        disabledSubCategory: true,
        listOfExpenseSubCategory: [],
        isEdit: false,
        operation: {
            label: "",
            value: "",
        },
    }
]

export const dataDefaultTableOpl = [
    {
        date: null,
        price: "",
        concept: "",
        disabled: true,
    },
    {
        date: null,
        price: "",
        concept: "",
        disabled: true,
    },
    {
        date: null,
        price: "",
        concept: "",
        disabled: true,
    },
    {
        date: null,
        price: "",
        concept: "",
        disabled: true,
    },
    {
        date: null,
        price: "",
        concept: "",
        disabled: true,
    },
    {
        date: null,
        price: "",
        concept: "",
        disabled: true,
    },
    {
        date: null,
        price: "",
        concept: "",
        disabled: true,
    }
]
export const dataDefaultECreditsOpl = [
    {
        type: "",
        date: null,
        employee:"",
        price: "",
        concept: "",
        disabled: true,
        isEdit: false
    },
    {
        type: "",
        date: null,
        employee:"",
        price: "",
        concept: "",
        disabled: true,
        isEdit: false
    },
    {
        type: "",
        date: null,
        employee:"",
        price: "",
        concept: "",
        disabled: true,
        isEdit: false
    },
    {
        type: "",
        date: null,
        employee:"",
        price: "",
        concept: "",
        disabled: true,
        isEdit: false
    },
    {
        type: "",
        date: null,
        employee : "",
        price: "",
        concept: "",
        disabled: true,
        isEdit: false
    },
    {
        type: "",
        date: null,
        employee : "",
        price: "",
        concept: "",
        disabled: true,
        isEdit: false
    },
    {
        type: "",
        date: null,
        employee : "",
        price: "",
        concept: "",
        disabled: true,
        isEdit: false
    }
]

export const operationSelector = [
    {
        value: 0,
        label: "T2"
    },
    {
        value: 1,
        label: "OL"
    }
]