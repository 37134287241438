//Constants
import SharedEndPoints from "./constants/shared.end-points";
import AdministrationPanelEndPoints from "./constants/administration-panel.end-points";

////Infrastructures
import Http from "./infrastructure/http.infrastructure";

//Services
import { getTokenService } from "./authentication.services";

export async function getAllUsersService(filterValue, order, page) {
    let body = {}
    filterValue && (body.filterValue = filterValue)
    order && (body.order = order)
    page && (body.page = page-1)
    const token = getTokenService()
    try {
        const { data: responseGetAllUsers, error } = await Http.get(AdministrationPanelEndPoints.GET_ALL_USERS, body, token)
        if (responseGetAllUsers && responseGetAllUsers.status) {
            return responseGetAllUsers.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getUserByIdService(id) {
    const token = getTokenService()
    try {
        const urlWithParams = AdministrationPanelEndPoints.GET_USER_BY_ID.replace(":idUser", id)
        const { data: responseGetUserById, error } = await Http.get(urlWithParams, null, token)
        if (responseGetUserById && responseGetUserById.status) {
            return responseGetUserById.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createUserService(body) {
    const token = getTokenService()
    try {
        const { data: responseCreateUser, error } = await Http.post(AdministrationPanelEndPoints.CREATE_USER, body, token)
        if (responseCreateUser && responseCreateUser.status) {
            return responseCreateUser.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateUserService(body) {
    const token = getTokenService()
    try {
        const { data: responseUpdateUser, error } = await Http.put(AdministrationPanelEndPoints.UPDATE_USER, body, token)
        if (responseUpdateUser && responseUpdateUser.status) {
            return responseUpdateUser.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getUserPermissionByIdService(id) {
    const token = getTokenService()
    try {
        const urlWithParams = AdministrationPanelEndPoints.GET_USER_PERMISSION_BY_ID.replace(":idUser", id)
        const { data: responseGetUserPermissionById, error } = await Http.get(urlWithParams, null, token)
        if (responseGetUserPermissionById && responseGetUserPermissionById.status) {
            return responseGetUserPermissionById.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createUserPermissionService(body) {
    const token = getTokenService()
    try {
        const { data: responseCreateUserPermission, error } = await Http.post(AdministrationPanelEndPoints.CREATE_USER_PERMISSION, body, token)
        if (responseCreateUserPermission && responseCreateUserPermission.status) {
            return responseCreateUserPermission.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateUserPermissionService(body) {
    const token = getTokenService()
    try {
        const { data: responseUpdateUserPermission, error } = await Http.delete(AdministrationPanelEndPoints.UPDATE_USER_PERMISSION, body, token)
        if (responseUpdateUserPermission && responseUpdateUserPermission.status) {
            return responseUpdateUserPermission.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getAllHeadquarterService(text,page = 0) {
    try {
        const token = getTokenService()
        let filterValue = text ? {filterValue: text} : null
        const { data: responseGetAllHeadquarter, error } = await Http.get(AdministrationPanelEndPoints.GET_ALL_HEADQUARTER, { ...filterValue, page }, token)
        if (responseGetAllHeadquarter && responseGetAllHeadquarter.status) {
            return responseGetAllHeadquarter.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getHeadquarterByIdService(id) {
    try {
        const token = getTokenService()
        const urlWithParams = AdministrationPanelEndPoints.GET_HEADQUARTER_BY_ID.replace(":idHeadquarter", id)
        const {data: responseGetHeadquarterById, error} = await Http.get(urlWithParams, null ,token)
        if (responseGetHeadquarterById && responseGetHeadquarterById.status) {
            return responseGetHeadquarterById.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createHeadquarterService(body) {
    const token = getTokenService()
    try {
        const { data: responseCreateHeadquarter, error } = await Http.post(AdministrationPanelEndPoints.CREATE_HEADQUARTER, body, token)
        if (responseCreateHeadquarter && responseCreateHeadquarter.status) {
            return responseCreateHeadquarter.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateHeadquarterService(body) {
    const token = getTokenService()
    try {
        const { data: responseUpdateHeadquarter, error } = await Http.put(AdministrationPanelEndPoints.UPDATE_HEADQUARTER, body, token)
        if (responseUpdateHeadquarter && responseUpdateHeadquarter.status) {
            return responseUpdateHeadquarter.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateUserHeadquarterService(body) {
    const token = getTokenService()
    try {
        const { data: responseUpdateUserHeadquarter, error } = await Http.put(AdministrationPanelEndPoints.UPDATE_USER_HEADQUARTER, body, token)
        if (responseUpdateUserHeadquarter && responseUpdateUserHeadquarter.status) {
            return responseUpdateUserHeadquarter.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};


export async function updateStoreHeadquarterService(body) {
    const token = getTokenService()
    try {
        const { data: responseUpdateStorePermission, error } = await Http.delete(AdministrationPanelEndPoints.UPDATE_STORE_HEADQUARTER, body, token)
        if (responseUpdateStorePermission && responseUpdateStorePermission.status) {
            return responseUpdateStorePermission.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getAllConstantsService() {
    try {
        const token = getTokenService()
        const { data: responseGetAllConstants, error } = await Http.get(AdministrationPanelEndPoints.GET_ALL_HISTORY_CONSTANTS, null, token)
        if (responseGetAllConstants && responseGetAllConstants.data) {
            return responseGetAllConstants.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createHistoricalService(url,name,date,id) {
    try {
        const token = getTokenService()
        const urlWithParams = AdministrationPanelEndPoints.CREATE_CONSTANT.replace("/:url", url)
        let data = {
            name: name
        }
        switch (url) {
            case "/historicalConstant":
                data.date = date
                data.id = id
                break;
            case "/domesticConsumptionProduct":
                data.id = id
                break;
            default:
                break;
        }
        const { data: responseGetAllConstants, error } = await Http.post(urlWithParams, data, token)
        if (responseGetAllConstants && responseGetAllConstants.status) {
            return responseGetAllConstants.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateHistoricalService(url,name,id,date) {
    try {
        const token = getTokenService()
        const urlWithParams = AdministrationPanelEndPoints.CREATE_CONSTANT.replace("/:url", url)
        const { data: responseUpdateConstants, error } = await Http.patch(urlWithParams, {name,id,date}, token)
        if (responseUpdateConstants && responseUpdateConstants.status) {
            return responseUpdateConstants.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function deleteHistoricalService(url,id) {
    try {
        const token = getTokenService()
        const urlWithParams = AdministrationPanelEndPoints.CREATE_CONSTANT.replace("/:url", url)
        const { data: responseDeleteConstants, error } = await Http.delete(urlWithParams, {id}, token)
        if (responseDeleteConstants && responseDeleteConstants.status) {
            return responseDeleteConstants.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createCategoryService(body){
    const token = getTokenService()
    try {
        const { data: responseCreateCategory, error } = await Http.post(AdministrationPanelEndPoints.CREATE_CATEGORY, body, token)
        if (responseCreateCategory && responseCreateCategory.status) {
            return responseCreateCategory.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateCategoryService(body){
    const token = getTokenService()
    try {
        const { data: responseUpdateCategory, error } = await Http.patch(AdministrationPanelEndPoints.UPDATE_CATEGORY, body, token)
        if (responseUpdateCategory && responseUpdateCategory.status) {
            return responseUpdateCategory.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createSubCategoryService(body){
    const token = getTokenService()
    try {
        const { data: responseSubCreateCategory, error } = await Http.post(AdministrationPanelEndPoints.CREATE_SUBCATEGORY, body, token)
        if (responseSubCreateCategory && responseSubCreateCategory.status) {
            return responseSubCreateCategory.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateSubCategoryService(body){
    const token = getTokenService()
    try {
        const { data: responseUpdateSubCategory, error } = await Http.patch(AdministrationPanelEndPoints.UPDATE_SUBCATEGORY, body, token)
        if (responseUpdateSubCategory && responseUpdateSubCategory.status) {
            return responseUpdateSubCategory.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getCategoryHeadquarterService(){
    const token = getTokenService()
    try {
        const { data: responseGetCategoryHeadquarter, error } = await Http.get(AdministrationPanelEndPoints.GET_CATEGORIES_HEADQUARTER, null, token)
        if (responseGetCategoryHeadquarter && responseGetCategoryHeadquarter.status) {
            return responseGetCategoryHeadquarter.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createCategoryHeadquarterService(body){
    const token = getTokenService()
    try {
        const { data: responseCategoryHeadquarter, error } = await Http.post(AdministrationPanelEndPoints.CREATE_CATEGORY_HEADQUARTER, body, token)
        if (responseCategoryHeadquarter && responseCategoryHeadquarter.status) {
            return responseCategoryHeadquarter.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateCategoryHeadquarterService(body){
    const token = getTokenService()
    try {
        const { data: responseUpdateCategoryHeadquarter, error } = await Http.put(AdministrationPanelEndPoints.UPDATE_CATEGORY_HEADQUARTER, body, token)
        if (responseUpdateCategoryHeadquarter && responseUpdateCategoryHeadquarter.status) {
            return responseUpdateCategoryHeadquarter.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getHeadquarterByIdCategoryService(idHeadQuarterCategory) {
    try {
        const token = getTokenService()
        const urlWithParams = AdministrationPanelEndPoints.GET_HEADQUARTER_BY_ID_CATEGORY.replace(":idHeadQuarterCategory", idHeadQuarterCategory)
        const {data: responseGetHeadquarterByIdCategory, error} = await Http.get(urlWithParams, null ,token)
        if (responseGetHeadquarterByIdCategory && responseGetHeadquarterByIdCategory.status) {
            return responseGetHeadquarterByIdCategory.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getListManagerHeadquarterService(){
    const token = getTokenService()
    try {
        const { data: responseListManagerHeadquarter, error } = await Http.post(AdministrationPanelEndPoints.GET_LIST_MANAGER_HEADQUARTER, null, token)
        if (responseListManagerHeadquarter && responseListManagerHeadquarter.status) {
            return responseListManagerHeadquarter.data.map(manager => ({
                value: manager.id,
                label: manager.name
            }))
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getCategoryExpenseService() {
    try {
        const token = getTokenService()
        const { data: responseGetAllCategories, error } = await Http.get(AdministrationPanelEndPoints.GET_EXPENSE_CATEGORY, null, token)
        if (responseGetAllCategories && responseGetAllCategories.status) {
            const expenseCategory = responseGetAllCategories.data.expenseCategory.map((category) => ({
                ...category,
                value: category.id,
                label: category.name.toLowerCase().replace(/\b\w/g, c => c.toUpperCase())
            }));
            const expenseCategoryOpl = responseGetAllCategories.data.expenseCategoryOpl.map((category) => ({
                ...category,
                value: category.id,
                label: category.name.toLowerCase().replace(/\b\w/g, c => c.toUpperCase())
            }));
            const expenseCategoryProvision = responseGetAllCategories.data.expenseCategoryProvisionOpl.map((category) => ({
                ...category,
                value: category.id,
                label: category.name.toLowerCase().replace(/\b\w/g, c => c.toUpperCase())
            }))
            return {
                expenseCategory,
                expenseCategoryOpl,
                expenseCategoryProvision
            };
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getExpenseSubCategoryOplService(data) {
    try {
        const token = getTokenService()
        const { data: responseGetExpenseSubCategory, error } = await Http.get(AdministrationPanelEndPoints.GET_EXPENSE_SUB_CATEGORY,data, token)
        if (responseGetExpenseSubCategory && responseGetExpenseSubCategory.status) {
            const response = responseGetExpenseSubCategory.data.map((category) => ({
                ...category,
                value: category.id,
                label: category.name.toLowerCase().replace(/\b\w/g, c => c.toUpperCase())
            })
            );
            return response
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function subCategoryOplService(body){
    try {
        const token = getTokenService()
        const { data: responseUpdateSubCategory, error } = await Http.post(AdministrationPanelEndPoints.CREATE_UPDATE_WE_OWE, body, token)
        if (responseUpdateSubCategory && responseUpdateSubCategory.status) {
            return responseUpdateSubCategory.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function deleteSubCategoryOplService(body){
    try {
        const token = getTokenService()
        const { data: responseUpdateSubCategory, error } = await Http.delete(AdministrationPanelEndPoints.DELETE_WE_OWE, body, token)
        if (responseUpdateSubCategory && responseUpdateSubCategory.status) {
            return responseUpdateSubCategory.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updatePrefixHeadquarterService(body) {
    try {
        const token = getTokenService()
        const { data: responseUpdatePrefixPermission, error } = await Http.post(AdministrationPanelEndPoints.CREATE_PREFIX, body, token)
        if (responseUpdatePrefixPermission && responseUpdatePrefixPermission.status) {
            return responseUpdatePrefixPermission.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getPrefixHeadquarterService(id) {
    try {
        const token = getTokenService()
        const urlWithParams = AdministrationPanelEndPoints.GET_PREFIX.replace(":id", id)
        const {data: responseGetPrefix, error} = await Http.get(urlWithParams, null ,token)
        if (responseGetPrefix && responseGetPrefix.status) {
            return responseGetPrefix.data.map((item) => item.prefix)
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getTypesProvisionService() {
    try {
        const token = getTokenService()
        const { data: responseGetTypes, error } = await Http.get(SharedEndPoints.GET_TYPE_WE_OWE, null, token)
        if (responseGetTypes && responseGetTypes.status) {

            const listType = responseGetTypes.data
                .filter(item => item.typeTable !== null)
                .map(item => ({
                    value: item.typeTable,
                    label: item.typeTable.toString()
                }));

            return listType;
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getOperationProvisionService() {
    try {
        const token = getTokenService();
        const { data: responseGetOperationProvision, error } = await Http.get(SharedEndPoints.GET_OPERATION, null, token)
        if (responseGetOperationProvision && responseGetOperationProvision.status) {
            const response = [
                { value: 0, label: 'N/A' },
                ...responseGetOperationProvision.data.map((operation) => ({
                    value: operation.id,
                    label: operation.name.toLowerCase().replace(/\b\w/g, c => c.toUpperCase())
                }))
            ];
            return response;
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getConstantProvisionService() {
    try {
        const token = getTokenService();
        const { data: responseGetConstantProvision, error } = await Http.get(SharedEndPoints.GET_CONSTANTS_PROVISION, null, token)
        if (responseGetConstantProvision && responseGetConstantProvision.status) {

            const response = [
                { value: 0, label: 'N/A' },
                ...responseGetConstantProvision.data.map((operation) => ({
                    value: operation.id,
                    label: operation.name.toLowerCase().replace(/\b\w/g, c => c.toUpperCase())
                }))
            ];

            return response;
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createRuleProvisionOplService(body) {
    try {
        const token = getTokenService()
        const { data: responseCreateRuleProvision, error } = await Http.post(AdministrationPanelEndPoints.CREATE_RULE_PROVISION, body, token)
        if (responseCreateRuleProvision && responseCreateRuleProvision.status) {
            return responseCreateRuleProvision.data;
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateRuleProvisionOplService(body){
    try {
        const token = getTokenService()
        const { data: responseCreateRuleProvision, error } = await Http.patch(AdministrationPanelEndPoints.UPDATE_RULE_PROVISION, body, token)
        if (responseCreateRuleProvision && responseCreateRuleProvision.status) {
            return responseCreateRuleProvision.data;
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};