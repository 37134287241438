import { combineReducers } from 'redux';

import AuthenticationReducer from '../reducers/authentication/authentication.reducer';
import CashClosingReducer from '../reducers/cash-closing/cash-closing.reducer';
import AuthorizedManagementReducer from '../reducers/authorized-management/authorized-management.reducer';
import HeadquartersReducer from '../reducers/headquarters/headquarters.reducer';
import AdminHeadquarterReducer from '../reducers/admin-headquarter/admin-headquarter.reducer';
import CashClosingWholesaleReducer from '../reducers/cash-closing-wholesale/cash-closing-wholesale.reducer';
import WholesaleFinalBalanceReducer from '../reducers/wholesale-final-balance/wholesale-final-balance.reducer';
import PortfolioReducer from '../reducers/portfolio/portfolio.reducer';
import QuotationOplReducer from '../reducers/quotation-opl/quotation-opl.reducer';
import InventoryReducer from '../reducers/inventory/inventory.reducer';

const rootReducer = combineReducers({
	AuthenticationReducer,
	CashClosingReducer,
	AuthorizedManagementReducer,
	HeadquartersReducer,
	AdminHeadquarterReducer,
	CashClosingWholesaleReducer,
	WholesaleFinalBalanceReducer,
	PortfolioReducer,
	QuotationOplReducer,
	InventoryReducer
});

export default rootReducer;
