// Assets
import { Assets } from '../../../../../../assets';

// Libraries
import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

// Styles
import './valuation-of-cloud.component.scss';

// Utils
import { formattedNumber } from '../../../../../../utils/shared.utils';

const ValuationOfCloudComponent = ({ cloudValuationInfo }) => {

  const INITIAL_STATE = {
    cloudValuationList: [],
    isHoverNameHeadquarter: false
  };

  const [state, setState] = useState(INITIAL_STATE);

  const { cloudValuationList, isHoverNameHeadquarter } = state;

  useEffect(() => {
    setState({
      ...state,
      cloudValuationList: cloudValuationInfo?.data
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloudValuationInfo?.data]);

  const isSortedAscendingByAttribute = (list, attributeName) => {
    return list.every((item, index) => index === 0 || item[attributeName] >= list[index - 1][attributeName]);
  };

  const optionFilter = (nameFilter, cloudValuationParam) => {
    let sortedCloudValuation = [...cloudValuationParam];
    const isSorted = isSortedAscendingByAttribute(cloudValuationParam, nameFilter);
    const sortOrder = isSorted ? 1 : -1;
    sortedCloudValuation.sort((a, b) => (sortOrder * (b.headquarter.localeCompare(a.headquarter))))
    setState((prevState) => ({
      ...prevState,
      cloudValuationList: sortedCloudValuation,
    }));
  };

  return (
    <div className='valuation-of-cloud__container'>
      <div className='grid-x align-middle valuation-of-cloud__title__content'>
        <img
          alt="transfers_selected"
          src={Assets.SharedIcons.transfers_selected}
        />
        <span className='valuation-of-cloud__title'>
          Valorización de TNS NUBE
        </span>
      </div>
      <div className='grid-x align-middle valuation-of-cloud__content-title-items'>
        <div className='valuation-of-cloud__title-items__content'>
          <button
            onClick={() => optionFilter('headquarter', cloudValuationList)}
            onMouseOver={() => setState({ ...state, isHoverNameHeadquarter: true })}
            onMouseOut={() => setState({ ...state, isHoverNameHeadquarter: false })}
            className='grid-x justify-content-center valuation-of-cloud__title-items__button'
          >
            <img
              alt={isHoverNameHeadquarter ? "icon_blue_arrow_down" : "icon_blue_arrow"}
              src={isHoverNameHeadquarter ? Assets.SharedIcons.icon_blue_arrow_down : Assets.SharedIcons.icon_blue_arrow}
              className={`valuation-of-cloud__title-items__button__icon${isSortedAscendingByAttribute(cloudValuationList , 'headquarter') ? '__active' : ''}`}
            />
            <span className={`valuation-of-cloud__title-items${isHoverNameHeadquarter ? '__active' : ''}`}>
              Nombre de la sede
            </span>
          </button>
        </div>
        <span className='valuation-of-cloud__title-items__content__second valuation-of-cloud__title-items'>
          Total de Utilidad
        </span>
        <span className='valuation-of-cloud__title-items__content__second valuation-of-cloud__title-items'>
          Diferencial del IVA
        </span>
        <span className='valuation-of-cloud__title-items__content__second valuation-of-cloud__title-items'>
          Total de Venta
        </span>
      </div>
      <div className='valuation-of-cloud__content-info'>
        {cloudValuationList.map((cloud, index) => {
          return (
            <div key={index} className='grid-x align-middle valuation-of-cloud__info'>
              <span className='valuation-of-cloud__info__text__first-content valuation-of-cloud__info__text'>
                {cloud.headquarter}
              </span>
              <span className='valuation-of-cloud__info__text__second-content valuation-of-cloud__info__text'>
                <strong>{formattedNumber(cloud?.tnsCloudUtility)}</strong>
              </span>
              <span className='valuation-of-cloud__info__text__second-content valuation-of-cloud__info__text'>
                <strong>{formattedNumber(cloud?.tnsCloudDifferential)}</strong>
              </span>
              <span className='valuation-of-cloud__info__text__second-content valuation-of-cloud__info__text'>
                <strong>{formattedNumber(cloud?.tnsCloudSale)}</strong>
              </span>
            </div>
          )
        })}
      </div>
      <div className='grid-x align-middle justify-content-between valuation-of-cloud__totalizers__content'>
        <div className='grid-x align-middle valuation-of-cloud__totalizers__content-box__first'>
          <span className='valuation-of-cloud__totalizers__content-box__first__text'>
            Totales generales
          </span>
        </div>
        <div className='valuation-of-cloud__totalizers__content-box__second'>
          <NumericFormat
            disabled
            type='text'
            prefix={"$ "}
            placeholder='$ 0'
            thousandSeparator=","
            className='input-price'
            thousandsGroupStyle="thousand"
            value={formattedNumber(cloudValuationInfo?.total?.tnsCloudUtility)}
          />
        </div>
        <div className='valuation-of-cloud__totalizers__content-box__second'>
          <NumericFormat
            disabled
            type='text'
            prefix={"$ "}
            placeholder='$ 0'
            thousandSeparator=","
            className='input-price'
            thousandsGroupStyle="thousand"
            value={formattedNumber(cloudValuationInfo?.total?.tnsCloudDifferential)}
          />
        </div>
        <div className='valuation-of-cloud__totalizers__content-box__second'>
          <NumericFormat
            disabled
            type='text'
            prefix={"$ "}
            placeholder='$ 0'
            thousandSeparator=","
            className='input-price'
            thousandsGroupStyle="thousand"
            value={formattedNumber(cloudValuationInfo?.total?.tnsCloudSale)}
          />
        </div>
      </div>
    </div>
  )
}

export default ValuationOfCloudComponent;