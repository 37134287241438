
const WalletOPLEndPoints = {
    GET_ALL_WALLET_OPL: `/api/walletOpl/getAllWalletOpl/:id`,
    CREATE_WALLET_OPL: `/api/walletOpl/createWalletOpl`,
    UPDATE_WALLET_OPL: `/api/walletOpl/updateWalletOpl/:id`,
    DELETE_WALLET_OPL: `/api/walletOpl/deleteWalletOpl`,
    GET_ALL_WALLET: `/api/walletOpl/:date`,
    GET_WALLET_BY_ID: `/api/walletOpl/walletHeadquarter`,
}

export default WalletOPLEndPoints