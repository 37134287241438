import { getTokenService } from "./authentication.services";
import WalletOPLEndPoints from "./constants/wallet-opl.end-points";
import Http from "./infrastructure/http.infrastructure";

export async function getWalletOplService(id) {
    try {
        const token = getTokenService();
        const urlWithParams = WalletOPLEndPoints.GET_ALL_WALLET_OPL.replace(":id", id)
        const { data: getWalletOpl, error } = await Http.get(urlWithParams, null, token)
        if (getWalletOpl && getWalletOpl.status) {
            return getWalletOpl.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function createWalletOplService(data) {
    try {
        const token = getTokenService();
        const { data: createWalletOpl, error } = await Http.post(WalletOPLEndPoints.CREATE_WALLET_OPL, data, token)
        if (createWalletOpl && createWalletOpl.status) {
            return createWalletOpl.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function updateWalletOplService(id,data) {
    try {
        const token = getTokenService();
        const urlWithParams = WalletOPLEndPoints.UPDATE_WALLET_OPL.replace(":id", id)
        const { data: updateWalletOpl, error } = await Http.patch(urlWithParams, data, token)
        if (updateWalletOpl && updateWalletOpl.status) {
            return updateWalletOpl.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function deleteWalletOplService(data) {
    try {
        const token = getTokenService();
        const { data: deleteWalletOpl, error } = await Http.delete(WalletOPLEndPoints.DELETE_WALLET_OPL, data, token)
        if (deleteWalletOpl && deleteWalletOpl.status) {
            return deleteWalletOpl.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getAllWalletOplService(date, data) {
    try {
        const token = getTokenService();
        const urlWithParams = WalletOPLEndPoints.GET_ALL_WALLET.replace(":date", date)
        const { data: getWalletOpl, error } = await Http.get(urlWithParams, data, token)
        if (getWalletOpl && getWalletOpl.status) {
            return getWalletOpl.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getWalletOplByIdService(data) {
    try {
        const token = getTokenService();
        const { data: getWalletOpl, error } = await Http.get(WalletOPLEndPoints.GET_WALLET_BY_ID, data, token)
        if (getWalletOpl && getWalletOpl.status) {
            const responseGetSummary = getWalletOpl.data
            let response = {}
            response.cashReceipt = responseGetSummary.cashReceived.map((data) => ({
                ...data,
                date: data.dailyTransactionTransaction.date,
                conceptDescription: data.concept,
                PaymentMethod:{ name: data.paymentMethodDailyTransactionTransaction.name },
                price: data.price
            }));
            response.cashExpense = responseGetSummary.cashOutflow.map((data) => ({
                ...data,
                date: data.dailyTransactionTransaction.date,
                conceptDescription: data.concept,
                PaymentMethod:{ name: data.paymentMethodDailyTransactionTransaction.name },
                price: data.price
            }));
            response.totals ={
                totalOtherProducts: responseGetSummary.amountMonthCashOutflow,
                subTotal: responseGetSummary.amountMonthCashReceived,
                cashOutflow: responseGetSummary.historicalPortfolios.cashOutflow,
                cashReceived: responseGetSummary.historicalPortfolios.cashReceived,
                total: responseGetSummary.historicalPortfolios.cashOutflow - responseGetSummary.historicalPortfolios.cashReceived,
            }
            return response;
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

