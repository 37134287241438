//Assets
import { Assets } from '../../../../../../assets';

//Libraries
import React, { useState } from 'react';
import { Collapse, Spin, Dropdown, Checkbox } from 'antd';

//Styles
import './table-history-of-sites-and-pubs.component.scss';

//Utils
import { formattedNumberInteger } from '../../../../../../utils/shared.utils';

const TableHistoryOfSitesAnPubsComponent = (props) => {

  const {
    // Variables
    isLoading,
    filterKey,
    isFilterExist,
    isFilterUnitCost,
    isFilterTotalCost,
    isFilterWarehouse,
    isFilterNameProduct,
    headquartersHistoryList,
    listHeadQuarterStoresData,
    isSelectedHeadquarterItem,
    // Functions
    optionFilter,
    setUpdateState,
  } = props;

  const INITIAL_STATE = {
    isHoverExist: false,
    isHoverUnitCost: false,
    isHoverTotalCost: false,
    isHoverWarehouse: false,
    isHoverNameProduct: false
  };

  const [state, setState] = useState(INITIAL_STATE);

  const {
    isHoverExist,
    isHoverUnitCost,
    isHoverTotalCost,
    isHoverWarehouse,
    isHoverNameProduct,
  } = state;

  const handleChange = (index) => {
    setUpdateState({
      isSelectedHeadquarterItem: isSelectedHeadquarterItem === index ? null : index
    })
  };

  const itemsFilter = () => {
    if (!listHeadQuarterStoresData || listHeadQuarterStoresData.length === 0) {
      return [];
    }
  
    const result = listHeadQuarterStoresData.map((store, index) => {
      return {
        key: index.toString(),
        label: (
          <div className='table-history-of-sites-and-pubs__content-selected__checkbox'>
            <Checkbox
              style={{ width: '100%' }}
              checked={filterKey === index.toString()}
              onChange={() => {
                optionFilter(3, 'warehouse', store.storeName)
                setUpdateState({
                  isFilterExist: false,
                  isFilterUnitCost: false,
                  isFilterTotalCost: false,
                  isFilterNameProduct: false,
                  filterKey: index.toString()
                })
              }}
            >
              {store.storeName}
            </Checkbox>
          </div>
        )
      };
    });
    return result;
  };

  const setStateHover = (title) => {
    setState({
      ...state,
      isHoverNameProduct: title === 'nameProduct' ? true : false,
      isHoverExist: title === 'exist' ? true : false,
      isHoverWarehouse: title === 'warehouse' ? true : false,
      isHoverUnitCost: title === 'unitCost' ? true : false,
      isHoverTotalCost: title === 'totalCost' ? true : false,
    })
  };
  
  return (
    <div className='table-history-of-sites-and-pubs__content-box'>
      <div className='grid-x table-history-of-sites-and-pubs__content-titles'>
        <div className='grid-x align-center-middle table-history-of-sites-and-pubs__content-titles__first'>
          <span className='table-history-of-sites-and-pubs__content-titles__title'>Código</span>
        </div>
        <div className='grid-x align-center-middle table-history-of-sites-and-pubs__content-titles__second'>
          <button
            onClick={() => optionFilter(1, 'nameProduct', null)}
            onMouseOver={() => setStateHover('nameProduct')}
            onMouseOut={() => setState({ ...state, isHoverNameProduct: false })}
            className='grid-x align-center-middle table-history-of-sites-and-pubs__content-titles__button'
          >
            <img
              alt={isHoverNameProduct ? "icon_blue_arrow_down" : "icon_blue_arrow"}
              src={isHoverNameProduct ? Assets.SharedIcons.icon_blue_arrow_down : Assets.SharedIcons.icon_blue_arrow}
              className={`table-history-of-sites-and-pubs__content-titles__button__icon${isFilterNameProduct ? '__active' : ''}`}
            />
            <span className={`table-history-of-sites-and-pubs__content-titles__title${isHoverNameProduct ? '__active' : ''}`}>
              Nombre del producto
            </span>
          </button>
        </div>
        <div className='grid-x align-center-middle table-history-of-sites-and-pubs__content-titles__third'>
          <button
            onClick={() => optionFilter(2, 'exist', null)}
            onMouseOver={() => setStateHover('exist')}
            onMouseOut={() => setState({ ...state, isHoverExist: false })}
            className='grid-x align-center-middle table-history-of-sites-and-pubs__content-titles__button'
          >
            <img
              alt={isHoverExist ? "icon_blue_arrow_down" : "icon_blue_arrow"}
              src={isHoverExist ? Assets.SharedIcons.icon_blue_arrow_down : Assets.SharedIcons.icon_blue_arrow}
              className={`table-history-of-sites-and-pubs__content-titles__button__icon${isFilterExist ? '__active' : ''}`}
            />
            <span className={`table-history-of-sites-and-pubs__content-titles__title${isHoverExist ? '__active' : ''}`}>
              Exi.
            </span>
          </button>
        </div>
        <div className='grid-x align-center-middle table-history-of-sites-and-pubs__content-titles__third'>
          <div className='grid-x align-center-middle table-history-of-sites-and-pubs__content-titles__button'>
            <Dropdown
              trigger={['click']}
              className='grid-x small-12'
              menu={{ items: itemsFilter() }}
              onOpenChange={(isSelected) => setUpdateState({ isFilterWarehouse: isSelected })}
            >
              <div
                onMouseOver={() => setStateHover('warehouse')}
                onMouseOut={() => setState({ ...state, isHoverWarehouse: false })}
                className='grid-x align-center-middle table-history-of-sites-and-pubs__content-selected'
              >
                <img
                  alt={isHoverWarehouse ? "icon_blue_arrow_down" : "icon_blue_arrow"}
                  src={isHoverWarehouse ? Assets.SharedIcons.icon_blue_arrow_down : Assets.SharedIcons.icon_blue_arrow}
                  className={`table-history-of-sites-and-pubs__content-titles__button__icon${isFilterWarehouse ? '__active' : ''}`}
                />
                <span className={`table-history-of-sites-and-pubs__content-titles__title${isHoverWarehouse ? '__active' : ''}`}>
                  Bodega
                </span>
              </div>
            </Dropdown>
          </div>
        </div>
        <div className='grid-x align-center-middle table-history-of-sites-and-pubs__content-titles__first'>
          <button
            onClick={() => optionFilter(4, 'unitCost', null)}
            onMouseOver={() => setStateHover('unitCost')}
            onMouseOut={() => setState({ ...state, isHoverUnitCost: false })}
            className='grid-x align-center-middle table-history-of-sites-and-pubs__content-titles__button'
          >
            <img
              alt={isHoverUnitCost ? "icon_blue_arrow_down" : "icon_blue_arrow"}
              src={isHoverUnitCost ? Assets.SharedIcons.icon_blue_arrow_down : Assets.SharedIcons.icon_blue_arrow}
              className={`table-history-of-sites-and-pubs__content-titles__button__icon${isFilterUnitCost ? '__active' : ''}`}
            />
            <span className={`table-history-of-sites-and-pubs__content-titles__title${isHoverUnitCost ? '__active' : ''}`}>
              Costo uni.
            </span>
          </button>
        </div>
        <div className='grid-x align-center-middle table-history-of-sites-and-pubs__content-titles__fourth'>
          <button
            onClick={() => optionFilter(5, 'totalCost', null)}
            onMouseOver={() => setStateHover('totalCost')}
            onMouseOut={() => setState({ ...state, isHoverTotalCost: false })}
            className='grid-x align-center-middle table-history-of-sites-and-pubs__content-titles__button'
          >
            <img
              alt={isHoverTotalCost ? "icon_blue_arrow_down" : "icon_blue_arrow"}
              src={isHoverTotalCost ? Assets.SharedIcons.icon_blue_arrow_down : Assets.SharedIcons.icon_blue_arrow}
              className={`table-history-of-sites-and-pubs__content-titles__button__icon${isFilterTotalCost ? '__active' : ''}`}
            />
            <span className={`table-history-of-sites-and-pubs__content-titles__title${isHoverTotalCost ? '__active' : ''}`}>
              Costo Total
            </span>
          </button>
        </div>
      </div>
      <div className='table-history-of-sites-and-pubs__content-info'>
        {isLoading
          ?
            (<Spin size='large' className='table-history-of-sites-and-pubs__spinner' />)
          :
            (headquartersHistoryList?.map((headQuarterHistory, index) => {
              return (
                <div key={index} className='grid-x align-center-middle table-history-of-sites-and-pubs__content-collapse'>
                  <Collapse
                    accordion={true}
                    onChange={() => handleChange(index)}
                    activeKey={isSelectedHeadquarterItem}
                    className={`${isSelectedHeadquarterItem === index ? 'table-history-of-sites-and-pubs__collapse__active' : 'table-history-of-sites-and-pubs__collapse'}`}
                    items={
                      [{
                        key: index,
                        label: (
                          <div className='grid-x table-history-of-sites-and-pubs__collapse__label__content'>
                            <div className='grid-x align-center-middle table-history-of-sites-and-pubs__collapse__label__first'>
                              <span className='table-history-of-sites-and-pubs__collapse__label__title'>
                                {headQuarterHistory.code}
                              </span>
                            </div>
                            <div className='grid-x align-center-middle table-history-of-sites-and-pubs__collapse__label__second'>
                              <span className='table-history-of-sites-and-pubs__collapse__label__title-aux'>
                                {headQuarterHistory.name}
                              </span>
                            </div>
                            <div className='grid-x align-center-middle table-history-of-sites-and-pubs__collapse__label__third'>
                              <span className='table-history-of-sites-and-pubs__collapse__label__title'>
                                {headQuarterHistory.amount}
                              </span>
                            </div>
                            <div className='grid-x align-center-middle table-history-of-sites-and-pubs__collapse__label__third'>
                              <span className='table-history-of-sites-and-pubs__collapse__label__title'>
                                {headQuarterHistory.store}
                              </span>
                            </div>
                            <div className='grid-x align-center-middle table-history-of-sites-and-pubs__collapse__label__first'>
                              <span className='table-history-of-sites-and-pubs__collapse__label__title'>
                                {formattedNumberInteger(headQuarterHistory.unitCost)}
                              </span>
                            </div>
                            <div className='grid-x align-middle table-history-of-sites-and-pubs__collapse__label__fourth'>
                              <div className='grid-x align-center-middle table-history-of-sites-and-pubs__collapse__label__sub-fourth'>
                                <span className='table-history-of-sites-and-pubs__collapse__label__title'>
                                  {formattedNumberInteger(headQuarterHistory.totalCost)}
                                </span>
                              </div>
                              <div className='grid-x align-center-middle table-history-of-sites-and-pubs__collapse__label__sub-fourth-aux'>
                                <img
                                  alt="icon_arrow"
                                  src={Assets.SharedIcons.icon_arrow}
                                  className={`table-history-of-sites-and-pubs__collapse__label__icon-arrow${isSelectedHeadquarterItem === index ? '__rotate' : ''}`}
                                />
                              </div>
                            </div>
                          </div>
                        ),
                        children: (
                          <div className='grid-x table-history-of-sites-and-pubs__collapse__children__content'>
                            <div className='grid-y table-history-of-sites-and-pubs__collapse__children__content__date'>
                              <span className='table-history-of-sites-and-pubs__collapse__children__title'>Fecha</span>
                              {headQuarterHistory.productHistory.map((date, dateIndex) => (
                                <span key={dateIndex} className='table-history-of-sites-and-pubs__collapse__children__text'>{date?.createdAt}</span>
                              ))}
                            </div>
                            <div className='grid-y table-history-of-sites-and-pubs__collapse__children__content__unit-cost'>
                              <span className='table-history-of-sites-and-pubs__collapse__children__title'>Costo unitario</span>
                              {headQuarterHistory.productHistory.map((unitCost, unitCostIndex) => (
                                <span key={unitCostIndex} className='table-history-of-sites-and-pubs__collapse__children__text'>{formattedNumberInteger(unitCost?.unitCost)}</span>
                              ))}
                            </div>
                          </div>
                        ),
                      }]
                    }
                  />
                </div>
              )
            }))
        }
      </div>
    </div>
  )
}

export default TableHistoryOfSitesAnPubsComponent;