import * as inventoryTypes from './inventory.types';

const INITIAL_STATE = {
    inventoryDataExcelDownload: [],
    transferDataExcelDownload: [],
    utilitiesDataExcelDownload: [],
};

const InventoryReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case inventoryTypes.SET_STATE_INVENTORY:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            };
        default:
            return state;
    }
};

export default InventoryReducer;