// Assets
import { Assets } from '../../../../../../assets';

// Libraries
import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

// Styles
import './valuation-of-cold-point.component.scss';

// Utils
import { formattedNumber } from '../../../../../../utils/shared.utils';

const ValuationOfColdPointComponent = ({ coldPointValuationInfo }) => {

  const INITIAL_STATE = {
    coldPointValuationList: [],
    isFilterNameHeadquarter: false
  };

  const [state, setState] = useState(INITIAL_STATE);

  const { isHoverNameHeadquarter, coldPointValuationList } = state;

  useEffect(() => {
    setState({
      ...state,
      coldPointValuationList: coldPointValuationInfo?.data
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coldPointValuationInfo?.data]);

  const isSortedAscendingByAttribute = (list, attributeName) => {
    return list.every((item, index) => index === 0 || item[attributeName] >= list[index - 1][attributeName]);
  };

  const optionFilter = (nameFilter, coldPointValuationParam) => {
    let sortedColdPointValuation = [...coldPointValuationParam];
    const isSorted = isSortedAscendingByAttribute(coldPointValuationParam, nameFilter);
    const sortOrder = isSorted ? 1 : -1;
    sortedColdPointValuation.sort((a, b) => (sortOrder * (b.headquarter.localeCompare(a.headquarter))))
    setState((prevState) => ({
      ...prevState,
      coldPointValuationList: sortedColdPointValuation,
    }));
  };

  return (
    <div className='valuation-of-cold-point__container'>
      <div className='grid-x align-middle valuation-of-cold-point__title__content'>
        <img
          alt="transfers_selected"
          src={Assets.SharedIcons.transfers_selected}
        />
        <span className='valuation-of-cold-point__title'>
          Valorización de TNS PUNTO FRÍO
        </span>
      </div>
      <div className='grid-x align-middle justify-content-between valuation-of-cold-point__content-title-items'>
        <div className='valuation-of-cold-point__title-items__content'>
          <button
            onClick={() => optionFilter('headquarter', coldPointValuationList)}
            onMouseOver={() => setState({ ...state, isHoverNameHeadquarter: true })}
            onMouseOut={() => setState({ ...state, isHoverNameHeadquarter: false })}
            className='grid-x justify-content-center valuation-of-cold-point__title-items__button'
          >
            <img
              alt={isHoverNameHeadquarter ? "icon_blue_arrow_down" : "icon_blue_arrow"}
              src={isHoverNameHeadquarter ? Assets.SharedIcons.icon_blue_arrow_down : Assets.SharedIcons.icon_blue_arrow}
              className={`valuation-of-cold-point__title-items__button__icon${isSortedAscendingByAttribute(coldPointValuationList, 'headquarter') ? '__active' : ''}`}
            />
            <span className={`valuation-of-cold-point__title-items${isHoverNameHeadquarter ? '__active' : ''}`}>
              Nombre de la sede
            </span>
          </button>
        </div>
        <span className='valuation-of-cold-point__title-items__content valuation-of-cold-point__title-items'>
          Total de Utilidad
        </span>
        <span className='valuation-of-cold-point__title-items__content valuation-of-cold-point__title-items'>
          Total de Venta
        </span>
      </div>
      <div className='valuation-of-cold-point__content-info'>
        {coldPointValuationList.map((coldPoint, index) => {
          return (
            <div key={index} className='grid-x align-middle justify-content-between valuation-of-cold-point__info'>
              <span className='valuation-of-cold-point__info__text'>
                {coldPoint.headquarter}
              </span>
              <span className='valuation-of-cold-point__info__text'>
                <strong>{formattedNumber(coldPoint.tnsPPUtility)}</strong>
              </span>
              <span className='valuation-of-cold-point__info__text'>
                <strong>{formattedNumber(coldPoint.tnsPPSale)}</strong>
              </span>
            </div>
          )
        })}
      </div>
      <div className='grid-x align-middle justify-content-between valuation-of-cold-point__totalizers__content'>

        <div className='grid-x align-middle valuation-of-cold-point__totalizers__content-box'>
          <span className='valuation-of-cold-point__totalizers__content-box__text'>
            Totales generales
          </span>
        </div>

        <div className='valuation-of-cold-point__totalizers__content-box'>
          <NumericFormat
            disabled
            type='text'
            prefix={"$ "}
            placeholder='$ 0'
            thousandSeparator=","
            className='input-price'
            thousandsGroupStyle="thousand"
            value={formattedNumber(coldPointValuationInfo?.total?.tnsPPUtility)}
          />
        </div>
        <div className='valuation-of-cold-point__totalizers__content-box'>
          <NumericFormat
            disabled
            type='text'
            prefix={"$ "}
            placeholder='$ 0'
            thousandSeparator=","
            className='input-price'
            thousandsGroupStyle="thousand"
            value={formattedNumber(coldPointValuationInfo?.total?.tnsPPSale)}
          />
        </div>
      </div>
    </div>
  )
};

export default ValuationOfColdPointComponent;