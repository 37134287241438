import * as quotationOplTypes from './quotation-opl.types';

const INITIAL_STATE = {
	isViewTotalizerReducer: false,
};

/**
 *
 * @param {[boolean]} isViewTotalizerReducer This variable fulfills the function of viewing the totalizers box in general adjustment.
 *
 */

const QuotationOplReducer = (state = INITIAL_STATE, action) => {

	switch (action.type) {
		case quotationOplTypes.SET_STATE_QUOTATION_OPL:
			return {
				...state,
				[action.payload.prop]: action.payload.value
			};

		default:
			return state;
	}

};

export default QuotationOplReducer;