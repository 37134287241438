// Assets
import { Assets } from "../../../assets";

// Components
import { TreasuryPortfoliosComponents } from "../components/treasury-portfolios/treasury-portfolios.components";
import { OtherWalletOplComponent } from "../components/other-wallet-opl/other-wallet-opl.component";

// Libraries
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

// Services
import { getAllPortfolioService } from "../../../services/portfolio.services";

// Styles
import "./opl-wallet-detail.page.scss";

export const WalletOplDetailPage = () => {

  let history = useNavigate()
  const { id } = useParams();

  const INITIAL_STATE = {
    optionSelected: 0,
    hoverIndex: null,
    headquarters: {}
  }

  const [state, setState] = useState(INITIAL_STATE)
  const { optionSelected, hoverIndex, headquarters } = state;

  const tabs = [
    {
      label: 'Carteras tesorería',
      iconAlt: { active: 'icon_summary', inactive: 'icon_summary_inactive', hover: 'icon_summary_active' },
      iconSrc: { active: Assets.SharedIcons.icon_summary, inactive: Assets.SharedIcons.icon_summary_inactive, hover: Assets.SharedIcons.icon_summary_active },
    },
    {
      label: 'Otros',
      iconAlt: { active: 'icon_projections_selected', inactive: 'icon_projections_inactive', hover: 'icon_projections_active' },
      iconSrc: { active: Assets.SharedIcons.icon_projections_selected, inactive: Assets.SharedIcons.icon_projections_inactive, hover: Assets.SharedIcons.icon_projections_active },
    }
  ];

  const validatePropsHoverTab = (index, typeIcon, typeAlt) => {
    let alt, src, typeText;

    if (hoverIndex === index) {
      alt = typeAlt.hover;
      src = typeIcon.hover;
      typeText = '__hover';
    } else if (optionSelected === index) {
      alt = typeAlt.active;
      src = typeIcon.active;
      typeText = '__active';
    } else {
      alt = typeAlt.inactive;
      src = typeIcon.inactive;
      typeText = '__inactive';
    }

    return { alt, src, typeText };
  };

  const validateData = (index) => {
    setState({ ...state, optionSelected: index })
  };

  useEffect(() => {
    getAllHeadQuarters()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAllHeadQuarters = async () => {
    try {
      const headquartersList  = await getAllPortfolioService()
      const headquarters = headquartersList?.find((headquarters) => headquarters.id === id);
      setState({ ...state, headquarters: headquarters })
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  }
  return (
    <div className='opl-wallet-detail__container'>
      <div className='grid-x small-12 opl-wallet-detail__content-header'>
        <div className='grid-x align-middle'>
          <button
            onClick={() => history(-1)}
            className="grid-x opl-wallet-detail__content-header__link"
          >
            Histórico de carteras
            <img
              alt='icon_arrow'
              src={Assets.SharedIcons.icon_arrow}
              style={{ marginLeft: '10px' }}
            />
          </button>
          <span className='opl-wallet-detail__name'>
            {headquarters?.name}
          </span>
        </div>
      </div>
      <div className='grid-x align-middle justify-content-between opl-wallet-detail__content-options'>
        <div className='grid-x align-middle justify-content-between opl-wallet-detail__content-options__content-buttons'>
          {tabs.map((tab, index) => {
            return (
              <button
                key={index}
                onClick={() => validateData(index)}
                className={`opl-wallet-detail__content-options__buttons${optionSelected === index ? '__active' : ''}`}
              >
                <img
                  alt={validatePropsHoverTab(index, tab.iconSrc, tab.iconAlt).alt}
                  src={validatePropsHoverTab(index, tab.iconSrc, tab.iconAlt).src}
                  className='opl-wallet-detail__content-options__icon-button'
                />
                <span className={`opl-wallet-detail__content-options__buttons__text${validatePropsHoverTab(index, tab.iconSrc, tab.iconAlt).typeText}`}>
                  {tab.label}
                </span>
              </button>
            )
          })}
        </div>
      </div>
      <div className='opl-wallet-detail__content-info'>
        {optionSelected === 0 && <TreasuryPortfoliosComponents />}
        {optionSelected === 1 && <OtherWalletOplComponent />}
      </div>
    </div>
  )
};
