// Components
import { TableOtherOplComponent } from "../table-other-opl/table-other-opl.component";

// Components - Shared
import ErrorAlertComponent from "../../../../shared/components/alerts/error-alert/error-alert.component";

// Libraries
import dayjs from 'dayjs';
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// Services
import { getExpenseOperationQuotationService, getOplOthersService } from "../../../../services/quotation-opl.services";

// Styles
import "./other-opl.component.scss";

export const OtherOplComponent = ({ selectedMonthSummaryProp }) => {

  const { state: locationState } = useLocation();

  const INITIAL_STATE = {
    listOtherOpl: [],
    listOperation: []
  };
  const [state, setState] = useState(INITIAL_STATE);

  const { listOtherOpl, listOperation } = state;

  useEffect(() => {
    if (selectedMonthSummaryProp) {
      getTableOtherOpl();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonthSummaryProp]);

  useEffect(() => {
    getOperation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOperation = async () => {
    try {
      const resp = await getExpenseOperationQuotationService()
      setState((prevState) => ({
        ...prevState,
        listOperation: resp
      }))
    } catch (error) {
      ErrorAlertComponent(undefined, "Error al visualizar los registro de OPL")
    }
  }

  const getTableOtherOpl = async () => {
    try {
      const listOtherOpl = await getOplOthersService(locationState?.opl?.id, dayjs(selectedMonthSummaryProp)?.format("YYYY-MM"));
      setState((prevState) => ({
        ...prevState,
        listOtherOpl
      }));
    } catch (error) {
      ErrorAlertComponent(undefined, "Error al visualizar los registro de OPL")
    }
  };

  return (
    <div className="other-opl__content">
      {listOtherOpl.map((other, index) => {
        return (
          <TableOtherOplComponent
            key={index}
            name={other.name}
            idExpenseSubcategory={other.id}
            getTableOtherOpl={() => getTableOtherOpl()}
            data={
              other.data.map(
                ({ employee, typeConcept, operationOPLCashExpenseOpl, ...value }) => (
                  {
                    ...value,
                    operation:{
                      label: operationOPLCashExpenseOpl?.name,
                      value: operationOPLCashExpenseOpl?.id
                    }
                  }
                )
              )}
            typeFunctionality={other?.typeFunctionality}
            listOperation={listOperation}
          />
        );
      })}
    </div>
  );
};
