//Actions
import { setStateInventoryReducer } from '../../../../storage/reducers/inventory/inventory.actions';

// Components
import FileUploadToInventoryComponent from './components/file-upload-to-Inventory/file-upload-to-Inventory.component';
import InventoryListByHeadquartersComponent from './components/inventory-list-by-headquarters/inventory-list-by-headquarters.component';
import ErrorAlertComponent from '../../../../shared/components/alerts/error-alert/error-alert.component';

// Libraries
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// Services
import { getHeadQuartersService, getDatesInventoryService } from '../../../../services/inventory.services';

// Styles
import './general-inventory.component.scss';

const GeneralInventoryComponent = (props) => {

  const {
    // Props
    isUpdated,
    isUpdateStateReports,
    dateSelected,
    // Actions
    setStateInventoryReducer
  } = props;

  const INITIAL_STATE = {
    isLoading: false,
    isLoadingView: true,
    headQuartersAndPubs: {
      structuredByHeadQuartes: [],
      totalInInventory: 0,
    },
    isInventoryViewByHeadquarters: false,
    isFirstLoad: true
  };

  const [state, setState] = useState(INITIAL_STATE);

  const {
    isLoading,
    isLoadingView,
    headQuartersAndPubs,
    isInventoryViewByHeadquarters,
    isFirstLoad
  } = state;

  useEffect(() => {
    if (isFirstLoad) {
      getHeadQuarters(true);
    } else {
      getHeadQuarters(false,true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSelected?.value]);

  const getHeadQuarters = async (isGetInitial, isDate) => {
    try {
      isGetInitial && setState((prevState) => ({ ...prevState, isLoadingView: true }));
      let listDates = [];
      if(!isDate){
        listDates = await getDatesInventoryService();
      } 
      const { structuredByHeadQuartes, totalInInventory } = await getHeadQuartersService(dateSelected?.value);
      if (isGetInitial) {
        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            headQuartersAndPubs: {
              structuredByHeadQuartes,
              totalInInventory
            },
            isLoadingView: false,
            isInventoryViewByHeadquarters: listDates?.length ? true : false,
            isFirstLoad: false
          }));
          isUpdateStateReports({
            isUpdated: false,
            isGeneralInventory: listDates?.length ? true : false,
            lastUpdate: listDates,
            lastUpdateSelected: listDates?.length ? listDates[0] : null
          });
        }, 500);
      } else {
        let itemSetState = {
          isLoading: false,
          headQuartersAndPubs: {
            structuredByHeadQuartes,
            totalInInventory
          },
          isFirstLoad: false
        }

        if (!isDate) {
          itemSetState.isInventoryViewByHeadquarters = listDates?.length ? true : false
        }

        setState((prevState) => ({
          ...prevState,
          ...itemSetState
        }));

        let itemUpdateSetState = {
          isUpdated: false,
        }
        
        if (!isDate) {
          itemUpdateSetState = {
            isUpdated: false,
            isGeneralInventory: listDates?.length ? true : false,
            lastUpdate: listDates,
            lastUpdateSelected: listDates?.length ? listDates[0] : null
          }
        }
        isUpdateStateReports(itemUpdateSetState);
      };
      setStateInventoryReducer('inventoryDataExcelDownload', {structuredByHeadQuartes, totalInInventory});
    } catch (error) {
      setTimeout(() => {
        if (error.code !== 'INVY_009' && error.message !== 'Not exist inventory') {
          ErrorAlertComponent();
        }
        setState((prevState) => ({
          ...prevState,
          isLoadingView: false,
          isInventoryViewByHeadquarters: false,
          isFirstLoad: false
        }));
      }, 500);
    }
  };

  return (
    (<>
      {isLoadingView
        ?
          (<div className='grid-y align-center-middle general-inventory__content-loading-view'>
            <Spin size='large' />
            <span className='general-inventory__content-loading-view__text'>Cargando...</span>
          </div>)
        :
          (<div className='general-inventory__container'>
            {isLoading &&
              (<>
                <div className='general-inventory__spinner__container' />
                <Spin size='large' className='general-inventory__spinner' />
              </>)
            }
            {isInventoryViewByHeadquarters && !isUpdated
              ? 
                (<InventoryListByHeadquartersComponent
                  headQuartersAndPubs={headQuartersAndPubs}
                  updateStateGeneralInventory={(newState) => setState((prevState) => ({ ...prevState, ...newState }))}
                  dateSelected={dateSelected}
                />)
              :
                (<FileUploadToInventoryComponent
                  getHeadQuarters={() => getHeadQuarters(false)}
                  updateStateGeneralInventory={(newState) => setState((prevState) => ({ ...prevState, ...newState }))}
                  dateActuality={dateSelected}
                />)
            }
          </div>)
      }
    </>)
  )
};

const mapStateToPropsActions = {
  setStateInventoryReducer
};

export default connect(null, mapStateToPropsActions)(GeneralInventoryComponent);