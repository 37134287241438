// Assets
import { Assets } from "../../../../assets";

// Components
import SuccessAlertComponent from "../../../../shared/components/alerts/success-alert/success-alert.component";
import ErrorAlertComponent from "../../../../shared/components/alerts/error-alert/error-alert.component";

// Libraries
import React, { useEffect, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import { DatePicker, Input } from "antd";
import dayjs from 'dayjs';
import {  useParams } from "react-router-dom";

// Services
import { 
    createWalletOplService, deleteWalletOplService, updateWalletOplService 
} from "../../../../services/wallet-opl.services";

// Styles
import "./wallet-opl-table.component.scss"

// Utils
import { dataDefaultTableOpl } from "../../../../utils/quotation-opl.utils";

export const WalletOplTableComponent = (props) => {

    const { id } = useParams();
    const {
        data,
        name,
        getTableOtherOpl,
        idExpenseSubcategory
    } = props;

    const INITIAL_STATE = {
        scrollEnd: false,
        other: dataDefaultTableOpl,
    };

    const [state, setState] = useState(INITIAL_STATE);

    const { other, scrollEnd } = state;

    const formRef = useRef(null);

    useEffect(() => {
        if (formRef.current && scrollEnd) {
            const miDiv = formRef.current;
            miDiv.scrollTop = miDiv.scrollHeight;
        }
    }, [scrollEnd, other.length]);

    useEffect(() => {
        if (data) {
            try {
                let otherArray = [...INITIAL_STATE.other];
                let oplOtherList = data.map(async (value) => ({
                    ...value, 
                    disabled: false,
                    isEdit: false
                }));

                Promise.all(oplOtherList).then((otherList) => {
                    const minLen = Math.min(otherArray.length, otherList.length);
    
                    for (let i = 0; i < minLen; i++){
                        if (otherArray[i].id !== otherList[i].id){ 
                            otherArray[i] = otherList[i]
                        };
                    }

                    if (otherArray.length < otherList.length){
                        for (let i = minLen; i < otherList.length; i++){ 
                            otherArray.push(otherList[i])
                        };

                    }

                    setState((prevState) => ({ 
                        ...prevState, 
                        other: otherArray, 
                        scrollEnd: false 
                    }));
                });
            } catch (error) {
                // TODO: Implement error alert with code error.
            }
        } else {
            setState(INITIAL_STATE)
        }
        formRef.current.scrollTop = 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleTotal = (data) => {
        let totalPrice = 0;
        data.forEach(object => {
            totalPrice += (parseFloat(object.price) ? parseFloat(object.price) : 0);
        });
        totalPrice = parseFloat(totalPrice.toFixed(2));
        return totalPrice;
    };

    const handleChange = async (e, index, name) => {
        let value = e && e.target ? e.target.value || '' : e;
        const newArray = JSON.parse(JSON.stringify(other));
        newArray[index][name] = value
        setState((prevState) => ({
            ...prevState,
            other: newArray
        }))
    };

    const handleEdit = async (item) => {
        try {
            const validateInfoNonEmpty = Object.values(item).every(value =>  value !== null && value !== undefined && value !== "");
            if(validateInfoNonEmpty) {
                await updateWalletOplService(
                    item.id, 
                    { 
                        date: item.date, 
                        concept: item.concept, 
                        price: item.price 
                    }
                );
                await getTableOtherOpl();
                SuccessAlertComponent(undefined, "Registro actualizado exitosamente");
            } else { 
                ErrorAlertComponent(undefined, "Error al crear el registro, los datos deben ser completados")
            }
        } catch (error) {
            ErrorAlertComponent(undefined, "Error al editar el registro")
        }
    }

    const handleCreate = async (item) => {
        const validateInfoNonEmpty = Object.values(item).every(value =>  value !== null && value !== undefined && value !== "");
        try {
            if(validateInfoNonEmpty) {
                await createWalletOplService({
                    date: item.date,
                    concept: item.concept,
                    price: item.price,
                    idHeadQuarter: id,
                    idExpenseSubcategory: idExpenseSubcategory
                });
                await getTableOtherOpl();
                SuccessAlertComponent(undefined, "Registro creado exitosamente");
            } else  ErrorAlertComponent(undefined, "Error al crear el registro, los datos deben ser completados")
        } catch (error) {
            ErrorAlertComponent(undefined, "Error al crear el registro")
        }
    };

    const removeDataExpenses = async (item, index) => {
        const initialItem = {
            price: "",
            concept: "",
            disabled: true
        };
        const newArray = JSON.parse(JSON.stringify(other));
        newArray[index] = initialItem;
        try {
            if (item.id) {
                await deleteWalletOplService({
                    id:item.id
                });
            }
            SuccessAlertComponent(undefined, "Registro eliminado exitosamente");
            newArray.sort((a, b) => (a.disabled === b.disabled) ? 0 : a.disabled ? 1 : -1);
            setState({ ...state, other: newArray });
        } catch (error) {
            ErrorAlertComponent(undefined, "Error al eliminar el registro")
        }
    };

    const addExpenses = () => {
        const objectInputDisabled = other.find(object => object.hasOwnProperty("disabled") && object.disabled === true);

        if (!objectInputDisabled) {
            const newArray = [
                ...other,
                {
                    date: null,
                    price: "",
                    concept: "",
                    disabled: false,
                    isEdit: true
                }
            ];
            setState((prevState) => ({
                ...prevState,
                other: newArray,
                scrollEnd: true
            }))
        } else {
            const newArray = other.map(object => (object === objectInputDisabled ? { ...object, disabled: false, isEdit: true } : object));
            setState((prevState) => ({
                ...prevState,
                other: newArray
            }))
        }
    };

    return (
        <div className='wallet-opl-table__payments__container'>
            <div className='wallet-opl-table__payments__content'>
                <div className='grid-y'>
                    <div className='grid-x wallet-opl-table__payments__content-titles__fist'>
                        <div className='grid-x align-middle small-6'>
                            <img src={Assets.SharedIcons.icon_information} alt="icon_information" />
                            <span className='wallet-opl-table__payments__titles wallet-opl-table__payments__titles__space'>{name}</span>
                        </div>
                        <div className='grid-x align-middle small-6 justify-content-end'>
                            <span className='wallet-opl-table__payments__titles'>Total:</span>
                            <div className='grid-x align-middle wallet-opl-table__summary__details'>
                                <div className='wallet-opl-table__payments__table__content-icon-price'>
                                    <img
                                        alt="icon_income_blue"
                                        src={Assets.SharedIcons.icon_income_blue}
                                        className='wallet-opl-table__summary__price__icon'
                                    />
                                </div>
                                <NumericFormat
                                    type='text'
                                    prefix={"$ "}
                                    disabled
                                    placeholder='$ 0'
                                    thousandSeparator=","
                                    className='input-price'
                                    value={handleTotal(other)}
                                    thousandsGroupStyle="thousand"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='grid-x justify-content-between wallet-opl-table__payments__content-titles__second'>
                        <span className='grid-x wallet-opl-table__payments__titles wallet-opl-table__payments__titles__space--mod small-3'>Fecha</span>
                        <span className='grid-x wallet-opl-table__payments__titles wallet-opl-table__payments__titles__space--mod small-4'>Concepto</span>
                        <span className='grid-x wallet-opl-table__payments__titles wallet-opl-table__payments__titles__space--mod small-4'>Valor</span>
                    </div>
                </div>
                <div ref={formRef} className='wallet-opl-table__payments__info'>
                    {other?.map((item, index) => {
                        return (
                            <div key={index} className='grid-x justify-content-between wallet-opl-table__payments__table'>
                                <div className='small-3'>
                                    <DatePicker
                                        placeholder='Fecha'
                                        format="YYYY/MM/DD"
                                        allowClear={false}
                                        style={{ width: "90%" }}
                                        disabled={item?.disabled || !item.isEdit}
                                        className="wallet-opl-table__payments__date"
                                        value={item?.date && dayjs(item?.date, "YYYY/MM/DD")}
                                        onChange={(e) => handleChange(e.format('YYYY-MM-DD'), index, "date")}
                                    />
                                </div>
                                <div className='grid-x small-4'>
                                    <Input
                                        placeholder='Concepto'
                                        disabled={item?.disabled || !item.isEdit}
                                        value={item?.concept}
                                        className='wallet-opl-table__payments__input'
                                        onChange={(e) => handleChange(e, index, "concept")}
                                        prefix={<img src={item.disabled || !item.isEdit ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit} alt="icon_add" />}
                                    />
                                </div>
                                <div className='grid-x justify-content-between small-5'>
                                    <div className='grid-x small-7 wallet-opl-table__payments__table__content-inputs'>
                                        <NumericFormat
                                            decimalScale={2}
                                            type='text'
                                            prefix={"$ "}
                                            placeholder='$ 0'
                                            value={item?.price}
                                            thousandSeparator=","
                                            className='input-price'
                                            disabled={item.disabled || !item.isEdit}
                                            thousandsGroupStyle="thousand"
                                            onValueChange={({ floatValue }) => handleChange(floatValue, index, 'price')}
                                        />
                                        <div className='wallet-opl-table__payments__table__content-icon-price'>
                                            <img
                                                alt="icon_add"
                                                src={item.disabled || !item.isEdit ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                                            />
                                        </div>
                                    </div>
                                    <div className='grid-x small-2 justify-content-end'>
                                        <button
                                            onClick={() => {
                                                if (item?.id) {
                                                    handleChange(!item?.isEdit, index, 'isEdit')
                                                    if (item?.isEdit) {
                                                        handleEdit(item, index)
                                                    }
                                                } else {
                                                    handleCreate(item, index)
                                                }
                                            }}
                                            className='wallet-opl-table__payments__button-delete'
                                            disabled={item?.disabled}
                                        >
                                            <img
                                                className='wallet-opl-table__payments__button-delete__icon'
                                                alt={item?.disabled ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                                                src={item?.disabled ? Assets.SharedIcons.icon_edit_disabled : (item?.isEdit ? Assets.SharedIcons.icon_save : Assets.SharedIcons.icon_edit)}
                                            />
                                        </button>
                                    </div>
                                    <div className='grid-x small-2 justify-content-end'>
                                        <button
                                            onClick={() => removeDataExpenses(item, index)}
                                            className='wallet-opl-table__payments__button-delete'
                                            disabled={item?.disabled}
                                        >
                                            <img
                                                className='wallet-opl-table__payments__button-delete__icon'
                                                alt={item?.disabled ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                                                src={item?.disabled ? Assets.SharedIcons.icon_deleted_disabled : Assets.SharedIcons.icon_deleted_active}
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className='grid-x'>
                    <div className='wallet-opl-table__payments__line'></div>
                    <button
                        onClick={() => addExpenses()}
                        className='grid-x align-middle wallet-opl-table__payments__type'
                    >
                        <img
                            src={Assets.SharedIcons.icon_income_blue}
                            alt='icon_income_blue'
                        />
                        <span className='wallet-opl-table__payments__type__name'>Agregar {name?.toLowerCase()}</span>
                    </button>
                </div>
            </div>
        </div>
    )
};
