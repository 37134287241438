// Assets
import { Assets } from "../../../../assets";

// Components - Shared
import ErrorAlertComponent from "../../../../shared/components/alerts/error-alert/error-alert.component";
import SuccessAlertComponent from "../../../../shared/components/alerts/success-alert/success-alert.component";
import InformationAlertComponent from "../../../../shared/components/alerts/information-alert/information-alert.component";

//  Libraries
import dayjs from 'dayjs';
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { Checkbox, DatePicker, Input, Select } from "antd";

// Styles
import "./provisions-opl.component.scss";

// Services
import {
    getProvisionsOplService,
    createProvisionOplService,
    deleteProvisionOplService,
    updateProvisionOplService,
    getExpenseOperationQuotationService,
} from "../../../../services/quotation-opl.services";

export const ProvisionsOplComponent = ({ selectedMonthSummaryProp }) => {

    const tableListingStructure = {
        operation: null,
        date: '',
        value: '',
        percentage: '',
        description: '',
        total: '',
        disabled: true,
        isEdit: false,
        isChecked: false
    };

    const propertiesToRemove = {
        1: ["total", "percentage", "disabled", "isEdit"],
        2: ["total", "percentage", "description", "disabled", "isEdit"],
        3: ["total", "percentage", "operation", "disabled", "isEdit"],
        4: ["total", "percentage", "operation", "description", "disabled", "isEdit"],
        5: ["total", "percentage", "operation", "disabled", "isEdit"],
    };

    const { state: locationState } = useLocation();

    const INITIAL_STATE = {
        listOperationState: [],
        provisionTablesState: [],
    };

    const [state, setState] = useState(INITIAL_STATE);

    const {
        listOperationState, provisionTablesState
    } = state;

    useEffect(() => {
        if (selectedMonthSummaryProp) {
            getInfoProvisionsOpl();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMonthSummaryProp]);

    useEffect(() => {
        getListOperation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const getInfoProvisionsOpl = async () => {
        try {
            const respProvisions = await getProvisionsOplService(locationState?.opl?.id, dayjs(selectedMonthSummaryProp)?.format("YYYY-MM"));
    
            const tablesStructureProvisions = respProvisions.map((item) => {
                let tableStructure = item.data.map((dataItem) => {
                    const total = dataItem.percentage
                        ? dataItem.value * (dataItem.percentage / 100)
                        : dataItem.value;
    
                    return {
                        ...tableListingStructure,
                        ...dataItem,
                        total,
                        disabled: false,
                    };
                });

                if (tableStructure.length < 7) {
                    const emptySlots = Array(7 - tableStructure.length).fill(tableListingStructure);
                    tableStructure = [...tableStructure, ...emptySlots];
                }
                
                return {
                    ...item,
                    data: tableStructure,
                };
            });
    
            setState((prevState) => ({
                ...prevState,
                provisionTablesState: tablesStructureProvisions,
            }));
        } catch (error) {
            ErrorAlertComponent();
        }
    };

    const getListOperation = async () => {
        try {
            const respListOperation = await getExpenseOperationQuotationService();

            setState((prevState) => ({
                ...prevState,
                listOperationState: respListOperation,
            }));
        } catch (error) {
            ErrorAlertComponent();
        }
    };

    const getTypeClassContainerByType = (type) => {

        const containerByType = {
            1: '__first',
            2: '__second',
            3: '__third',
            4: '__fourth',
            5: '__fifth',
        };
        return containerByType[type];
    };

    const addProvision = async (indexTable) => {
        const tableProvisions = [...provisionTablesState[indexTable].data];
        const objectInputDisabled = tableProvisions.find(object => object.hasOwnProperty("disabled") && object.disabled === true);

        if (!objectInputDisabled) {

            const newItemForListProvision = [
                ...tableProvisions,
                {
                    operation: null,
                    date: '',
                    value: '',
                    percentage: '',
                    description: '',
                    total: '',
                    disabled: false,
                    isEdit: true
                }
            ];

            const updatedProvisionTables = [...provisionTablesState];
            updatedProvisionTables[indexTable] = {
                ...provisionTablesState[indexTable],
                data: newItemForListProvision,
            };

            setState((prevState) => ({
                ...prevState,
                provisionTablesState: updatedProvisionTables
            }))

        } else {
            const indexToUpdate = tableProvisions.findIndex((object) => object === objectInputDisabled);

            if (indexToUpdate !== -1) {
                tableProvisions[indexToUpdate] = {
                    ...tableProvisions[indexToUpdate],
                    disabled: false,
                    isEdit: true,
                };

                const updatedProvisionTables = [...provisionTablesState];
                updatedProvisionTables[indexTable] = {
                    ...provisionTablesState[indexTable],
                    data: tableProvisions,
                };

                setState((prevState) => ({
                    ...prevState,
                    provisionTablesState: updatedProvisionTables
                }))
            }
        }
    };

    const handleChange = (event, indexTable, indexItem, target) => {
        let value = event && event.target ? event.target.value || '' : event;
        const tableProvisionsCopy = [...provisionTablesState];

        if (target === "isChecked" && tableProvisionsCopy[indexTable]?.data[indexItem]?.operation) {
            tableProvisionsCopy[indexTable].data[indexItem].operation = null;
        };

        tableProvisionsCopy[indexTable].data[indexItem][target] = value;

        setState((prevState) => ({
            ...prevState,
            provisionTablesState: tableProvisionsCopy
        }));
    };

    const handleCreateProvision = async (tableType, tableId, item) => {
        try {
            const sanitizedItem = { ...item };
            (propertiesToRemove[tableType] || []).forEach((prop) => delete sanitizedItem[prop]);

            const missingFields = Object.keys(sanitizedItem).filter((key) => sanitizedItem[key] === '' || sanitizedItem[key] === undefined);

            if (missingFields.length > 0) {
                InformationAlertComponent(undefined, "Todos los campos son obligatorios");
                return;
            };
            
            if (tableType === 5) {
                sanitizedItem.isChecked = true; 
            };
            sanitizedItem.idExpenseSubcategory = tableId;
            sanitizedItem.idHeadQuarter = locationState?.opl?.id;
            sanitizedItem.idOperationOPL = sanitizedItem?.operation?.value || null;

            delete sanitizedItem?.operation;

            await createProvisionOplService(sanitizedItem);
            SuccessAlertComponent(undefined, "Provisión creada exitosamente");
            getInfoProvisionsOpl();
        } catch (error) {
            ErrorAlertComponent(undefined, error.code === "OPAD001" ? "No existe una constante enlazada a la operación asignada" : undefined);
        }
    };

    const handleUpdateProvision = async (tableType, tableId, item) => {
        try {
            const sanitizedItem = { ...item };
            (propertiesToRemove[tableType] || []).forEach((prop) => delete sanitizedItem[prop]);

            const missingFields = Object.keys(sanitizedItem).filter((key) => sanitizedItem[key] === '' || sanitizedItem[key] === undefined);

            if (missingFields.length > 0) {
                InformationAlertComponent(undefined, "Todos los campos son obligatorios");
                return;
            };

            sanitizedItem.idHeadQuarter = locationState?.opl?.id;
            sanitizedItem.idOperationOPL = sanitizedItem?.operation?.value || null;
            sanitizedItem.idExpenseSubcategory = tableId;

            delete sanitizedItem?.operation;

            await updateProvisionOplService(sanitizedItem?.id, sanitizedItem);
            SuccessAlertComponent(undefined, "Provisión actualizada exitosamente");
            getInfoProvisionsOpl();
        } catch (error) {
            ErrorAlertComponent();
        }
    };

    const handleDeleteProvision = async (item) => {
        try {
            await deleteProvisionOplService({ id: item.id });
            SuccessAlertComponent(undefined, "Provisión eliminada exitosamente");
            getInfoProvisionsOpl();
        } catch (error) {
            ErrorAlertComponent();
        }
    };

    const handleResetProvision = (indexTable, indexItem, item) => {
        if (!item.id) {
            const tableProvisionsCopy = [...provisionTablesState];
            tableProvisionsCopy[indexTable].data[indexItem] = tableListingStructure;

            setState((prevState) => ({
                ...prevState,
                provisionTablesState: tableProvisionsCopy
            }));
        }
    };

    const handleTotal = (data) => {
        let totalPrice = 0;
        data.forEach(object => {
            totalPrice += (parseFloat(object.total) ? parseFloat(object.total) : 0);
        });
        totalPrice = parseFloat(totalPrice.toFixed(2));
        return totalPrice;
    };

    const disabledDate = (current) => {
        return current && current.isAfter(dayjs(), 'day');
    };

    return (
        <div className="provisions-opl__container">
            {provisionTablesState.map((table, indexTable) => (
                <div key={indexTable} className="provisions-opl__container-table">
                    <div className="provisions-opl__content-header-table">
                        <div>
                            <img
                                alt="icon_information"
                                src={Assets.SharedIcons.icon_information}
                            />
                            <span className="provisions-opl__text-table">
                                {table.name}
                            </span>
                        </div>
                        <div className="provisions-opl__content-total-table">
                            <span className="provisions-opl__text-table">
                                Total:
                            </span>
                            <div className="provisions-opl__total-table">
                                <NumericFormat
                                    disabled
                                    type='text'
                                    prefix={"$ "}
                                    decimalScale={2}
                                    placeholder='$ 0'
                                    thousandSeparator=","
                                    className='input-price'
                                    fixedDecimalScale={true}
                                    thousandsGroupStyle="thousand"
                                    value={handleTotal(table.data)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="provisions-opl__titles-table__container">
                        {!(table.type === 5) && (
                            <div className={`provisions-opl__container-by-item${getTypeClassContainerByType(table.type)}--isChecked`}/>
                        )}
                        {!(table.type === 3 || table.type === 4 || table.type === 5) && (
                            <div className={`provisions-opl__container-by-item${getTypeClassContainerByType(table.type)}--operation`}>
                                <span className="provisions-opl__titles-table__text">Operación</span>
                            </div>
                        )}
                        <div className={`provisions-opl__container-by-item${getTypeClassContainerByType(table.type)}--date`}>
                            <span className="provisions-opl__titles-table__text">Fecha</span>
                        </div>
                        {!(table.type === 5) && (
                            <div className={`provisions-opl__container-by-item${getTypeClassContainerByType(table.type)}--base`}>
                                <span className="provisions-opl__titles-table__text">Base</span>
                            </div>
                        )}
                        {!(table.type === 2 || table.type === 4) && (
                            <div className={`provisions-opl__container-by-item${getTypeClassContainerByType(table.type)}--description`}>
                                <span className="provisions-opl__titles-table__text">Descripción</span>
                            </div>
                        )}
                        {!(table.type === 5) && (
                            <div className={`provisions-opl__container-by-item${getTypeClassContainerByType(table.type)}--percentage`}>
                                <span className="provisions-opl__titles-table__text">%</span>
                            </div>
                        )}
                        <div className={`provisions-opl__container-by-item${getTypeClassContainerByType(table.type)}--total`} style={{ paddingRight: '80px' }}>
                            <span className="provisions-opl__titles-table__text">{table.type === 5 ? 'Base' : 'Total'}</span>
                        </div>
                    </div>
                    <div className="provisions-opl__container-info">
                        {table.data.map((item, indexForm) => (
                            <div key={indexForm} className="provisions-opl__container-info__content-item-row">
                                {!(table.type === 5) && (
                                    <div className={`provisions-opl__container-by-item${getTypeClassContainerByType(table.type)}--isChecked`}>
                                        <div className="provisions-opl__container-info__check">
                                            <Checkbox
                                                checked={item.isChecked}
                                                disabled={(item?.disabled || !item.isEdit)}
                                                onChange={(e) => handleChange(e.target.checked, indexTable, indexForm, 'isChecked')}
                                            />
                                        </div>
                                    </div>
                                )}
                                {!(table.type === 3 || table.type === 4 || table.type === 5) && (
                                    <div className={`provisions-opl__container-by-item${getTypeClassContainerByType(table.type)}--operation`}>
                                        <Select
                                            value={item.operation}
                                            optionLabelProp='label'
                                            placeholder='Operación'
                                            options={listOperationState}
                                            popupMatchSelectWidth={false}
                                            className='provisions-opl__container-info__select'
                                            disabled={(item?.disabled || !item.isEdit || item.isChecked)}
                                            popupClassName='provisions-opl__container-info__select__popup'
                                            onChange={(e, event) => handleChange(event, indexTable, indexForm, 'operation')}
                                            suffixIcon={
                                                <div className='provisions-opl__container-info__select__icon'>
                                                    <img
                                                        alt='icon_blue_arrow'
                                                        src={(item?.disabled || !item.isEdit || item.isChecked) ? Assets.SharedIcons.icon_disabled_arrow : Assets.SharedIcons.icon_blue_arrow}
                                                    />
                                                </div>
                                            }
                                        />
                                    </div>
                                )}
                                <div className={`provisions-opl__container-by-item${getTypeClassContainerByType(table.type)}--date`}>
                                    <DatePicker
                                        allowClear={false}
                                        suffixIcon={null}
                                        placeholder='Fecha'
                                        format='YYYY-MM-DD'
                                        disabled={(item?.disabled || !item.isEdit)}
                                        value={item?.date && dayjs(item?.date).utc()}
                                        className='provisions-opl__container-info__date'
                                        disabledDate={(current) => disabledDate(current)}
                                        onChange={(e) => handleChange(dayjs(e).format('YYYY-MM-DD'), indexTable, indexForm, 'date')}
                                    />
                                </div>
                                {!(table.type === 5) && (
                                    <div className={`provisions-opl__container-by-item${getTypeClassContainerByType(table.type)}--base`}>
                                        <div className='provisions-opl__container-info__number'>
                                            <NumericFormat
                                                type='text'
                                                prefix={"$ "}
                                                decimalScale={2}
                                                placeholder='$ 0'
                                                value={item.value}
                                                thousandSeparator=","
                                                className='input-price'
                                                fixedDecimalScale={true}
                                                thousandsGroupStyle="thousand"
                                                disabled={(item?.disabled || !item.isEdit)}
                                                onValueChange={({ floatValue }) => handleChange(floatValue, indexTable, indexForm, 'value')}
                                            />
                                            <div className='provisions-opl__container-info__number__icon-price'>
                                                <img
                                                    alt="icon_add"
                                                    src={(item?.disabled || !item.isEdit) ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {!(table.type === 2 || table.type === 4) && (
                                    <div className={`provisions-opl__container-by-item${getTypeClassContainerByType(table.type)}--description`}>
                                        <Input
                                            value={item.description}
                                            placeholder='Descripción'
                                            disabled={(item?.disabled || !item.isEdit)}
                                            className='provisions-opl__container-info__input'
                                            onChange={(e) => handleChange(e, indexTable, indexForm, 'description')}
                                            prefix={<img src={(item?.disabled || !item.isEdit) ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit} alt="icon_add" />}
                                        />
                                    </div>
                                )}
                                {!(table.type === 5) && (
                                    <div className={`provisions-opl__container-by-item${getTypeClassContainerByType(table.type)}--percentage`}>
                                        <Input
                                            disabled
                                            prefix={<></>}
                                            placeholder='%'
                                            value={item.percentage}
                                            className='provisions-opl__container-info__input'
                                        />
                                    </div>
                                )}
                                <div className={`provisions-opl__container-by-item${getTypeClassContainerByType(table.type)}--total`}>
                                    <div className='provisions-opl__container-info__number-total'>
                                        <NumericFormat
                                            type='text'
                                            prefix={"$ "}
                                            decimalScale={2}
                                            placeholder='$ 0'
                                            thousandSeparator=","
                                            className='input-price'
                                            fixedDecimalScale={true}
                                            thousandsGroupStyle="thousand"
                                            value={table.type === 5 ? item.value : item.total}
                                            disabled={table.type === 5 ? (item?.disabled || !item.isEdit) : true}
                                            onValueChange={({ floatValue }) => table.type === 5 ? handleChange(floatValue, indexTable, indexForm, 'value') : null}
                                        />
                                        <div className='provisions-opl__container-info__number-total__icon-price'>
                                            <img
                                                alt="icon_add"
                                                src={Assets.SharedIcons.icon_add}
                                            />
                                        </div>
                                        <button
                                            disabled={item?.disabled}
                                            className='provisions-opl__container-info__button'
                                            onClick={() => {
                                                if (item?.id) {
                                                    if (item.isEdit) {
                                                        handleUpdateProvision(table.type, table.id, item);
                                                    } else {
                                                        handleChange(true, indexTable, indexForm, 'isEdit');
                                                    }
                                                } else {
                                                    handleCreateProvision(table.type, table.id, item);
                                                }
                                            }}
                                        >
                                            <img
                                                alt='icon_edit'
                                                className='provisions-opl__container-info__button__icon'
                                                src={item.disabled ? Assets.SharedIcons.icon_edit_disabled : (item.isEdit ? Assets.SharedIcons.icon_save : Assets.SharedIcons.icon_edit)}
                                            />
                                        </button>
                                        <button
                                            disabled={item?.disabled}
                                            className='provisions-opl__container-info__button'
                                            onClick={() => {
                                                if (item?.isEdit) {
                                                    handleChange(!item?.isEdit, indexTable, indexForm, 'isEdit')
                                                    handleResetProvision(indexTable, indexForm, item);
                                                } else {
                                                    handleDeleteProvision(item);
                                                }
                                            }}
                                        >
                                            <img
                                                alt='icon_deleted'
                                                className='provisions-opl__container-info__button__icon'
                                                src={item?.disabled ? Assets.SharedIcons.icon_deleted_disabled : (item?.isEdit ? Assets.SharedIcons.icon_deleted_active : Assets.SharedIcons.icon_trash_bin)}
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="provisions-opl__container-button">
                        <button
                            className="provisions-opl__button-add"
                            onClick={() => addProvision(indexTable)}
                        >
                            Agregar
                        </button>
                    </div>
                </div>
            ))}
        </div>
    )
}