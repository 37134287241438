// Assets
import { Assets } from "../../../../assets";

// Components - Shared
import SuccessAlertComponent from "../../../../shared/components/alerts/success-alert/success-alert.component";
import ErrorAlertComponent from "../../../../shared/components/alerts/error-alert/error-alert.component";
import InformationAlertComponent from "../../../../shared/components/alerts/information-alert/information-alert.component";

// Libraries
import React, { useEffect, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import { DatePicker, Input, Select } from "antd";
import dayjs from 'dayjs';
import { useLocation } from "react-router-dom";

// Services
import { createOthersOplService, deleteQuotationService, updateQuotationService } from "../../../../services/quotation-opl.services";

// Styles
import "./table-other-opl.component.scss";

// Utils
import { dataDefaultTableOpl } from "../../../../utils/quotation-opl.utils";

export const TableOtherOplComponent = (props) => {

    const { state: locationState } = useLocation();
    const { 
        data, name, idExpenseSubcategory, 
        getTableOtherOpl, typeFunctionality,
        listOperation
    } = props;

    const INITIAL_STATE = { scrollEnd: false, expenses: dataDefaultTableOpl };
    const [state, setState] = useState(INITIAL_STATE);
    const { expenses, scrollEnd } = state;

    const formRef = useRef(null);

    useEffect(() => {
        if (formRef.current && scrollEnd) formRef.current.scrollTop = formRef.current.scrollHeight;
    }, [scrollEnd, expenses.length]);

    useEffect(() => {
        if (data) {
            try {
                let expenseOPL = [...INITIAL_STATE.expenses];
                let oplExpenseList = data.map(async (value) => ({ ...value, disabled: false, isEdit: false }));
                Promise.all(oplExpenseList).then((expenseList) => {
                    const minLen = Math.min(expenseOPL.length, expenseList.length);
    
                    for (let i = 0; i < minLen; i++) if (expenseOPL[i].id !== expenseList[i].id) expenseOPL[i] = expenseList[i];
                    if (expenseOPL.length < expenseList.length) 
                        for (let i = minLen; i < expenseList.length; i++) expenseOPL.push(expenseList[i]);

                    setState((prevState) => ({ ...prevState, expenses: expenseOPL, scrollEnd: false }));
                });
            } catch (error) {
                ErrorAlertComponent(undefined, "Error al visualizar los registro de OPL")
            }
        } else {
            setState(INITIAL_STATE)
        }
        formRef.current.scrollTop = 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleTotal = (data) => {
        let totalPrice = 0;
        data.forEach(object => totalPrice += (parseFloat(object.price) ? parseFloat(object.price) : 0));
        return parseFloat(totalPrice.toFixed(2));
    };

    const handleChange = async (e, index, name) => {
        let value = e && e.target ? e.target.value || '' : e;
        const newArray = JSON.parse(JSON.stringify(expenses));
        if (name === "operation") {
            newArray[index][name] = {
                label: value.label,
                value: value.value
            }
        }else{
            newArray[index][name] = value
        }
        setState({ ...state, expenses: newArray })
    };

    const handleEdit = async (item, index) => {
        let validation = {
            price: item.price, 
            date: item.date, 
            concept: item.concept
        }
        if (typeFunctionality) {
            validation.operation = item?.operation?.value
        }
        const validateInfoNonEmpty = Object.values(validation).every(value =>  value !== null && value !== undefined && value !== "");
        try {
            if(validateInfoNonEmpty) {
                handleChange(false, index, 'isEdit')
                let dataUpdate = {
                    date: item.date, 
                    concept: item.concept, 
                    price: item.price,
                }
                if (typeFunctionality) {
                    dataUpdate.idOperationOPL = item?.operation?.value
                }
                await updateQuotationService(
                    item.id, 
                    dataUpdate
                );
                SuccessAlertComponent(undefined, "Gasto actualizado exitosamente");
            } else {
                handleChange(true, index, 'isEdit')
                InformationAlertComponent(undefined, "Error al editar el registro, los datos deben ser completados")
            }
        } catch (error) {
            ErrorAlertComponent(undefined, "Error al editar el registro")
        }
    }

    const handleCreate = async (item) => {
        let validation = {
            price: item.price, 
            date: item.date, 
            concept: item.concept
        }

        if (typeFunctionality) {
            validation.operation = item?.operation?.value
        }
        const validateInfoNonEmpty = Object.values(validation).every(value =>  value !== null && value !== undefined && value !== "");
        try {
            if(validateInfoNonEmpty) {
                let dataCreate = {
                    date: item.date,
                    concept: item.concept,
                    price: item.price,
                    idHeadQuarter: locationState?.opl?.id,
                    idExpenseSubcategory,
                }
                if (typeFunctionality) {
                    dataCreate.idOperationOPL = item?.operation?.value
                }
                await createOthersOplService(dataCreate);
                await getTableOtherOpl();
                SuccessAlertComponent(undefined, "Gasto creado exitosamente");
            } else  InformationAlertComponent(undefined, "Error al crear el registro, los datos deben ser completados")
        } catch (error) {
            ErrorAlertComponent(undefined, "Error al crear el registro")
        }
    };

    const removeDataExpenses = async (item, index) => {
        const initialItem = { price: "", concept: "", disabled: true };
        const newArray = JSON.parse(JSON.stringify(expenses));
        newArray[index] = initialItem;
        try {
            if (item?.id) {
                await deleteQuotationService({id:item.id});
                SuccessAlertComponent(undefined, "Gasto eliminado exitosamente");
            };
            newArray.sort((a, b) => (a.disabled === b.disabled) ? 0 : a.disabled ? 1 : -1);
            setState({ ...state, expenses: newArray });
        } catch (error) {
            ErrorAlertComponent(undefined, "Error al eliminar el registro")
        }
    };

    const addExpenses = () => {
        const objectInputDisabled = expenses.find(object => object.hasOwnProperty("disabled") && object.disabled === true);
        if (!objectInputDisabled) {
            const newArray = [ 
                ...expenses, 
                { 
                    date: null, 
                    price: "", 
                    concept: "", 
                    isEdit: true, 
                    disabled: false,
                    operation: { label: "", value: "" } 
                } 
            ];
            setState((prevState) => ({ ...prevState, expenses: newArray, scrollEnd: true }))
        } else {
            const newArray = expenses.map(object => (object === objectInputDisabled ? { ...object, disabled: false, isEdit: true } : object));
            setState((prevState) => ({ ...prevState, expenses: newArray }))
        }
    };

    const disabledDate = (current) => {
        return current && current.isAfter(dayjs(), 'day');
    };

    return (
        <div className='table-other-opl__payments__container'>
            <div className='table-other-opl__payments__content'>
                <div className='grid-y'>
                    <div className='grid-x table-other-opl__payments__content-titles__fist'>
                        <div className='grid-x align-middle small-6'>
                            <img src={Assets.SharedIcons.icon_information} alt="icon_information" />
                            <span className='table-other-opl__payments__titles table-other-opl__payments__titles__space'>{name}</span>
                        </div>
                        <div className='grid-x align-middle small-6 justify-content-end'>
                            <span className='table-other-opl__payments__titles'>Total:</span>
                            <div className='grid-x align-middle table-other-opl__summary__details'>
                                <div className='table-other-opl__payments__table__content-icon-price'>
                                    <img
                                        alt="icon_income_blue"
                                        src={Assets.SharedIcons.icon_income_blue}
                                        className='table-other-opl__summary__price__icon'
                                    />
                                </div>
                                <NumericFormat
                                    type='text'
                                    prefix={"$ "}
                                    disabled
                                    placeholder='$ 0'
                                    thousandSeparator=","
                                    className='input-price'
                                    value={handleTotal(expenses)}
                                    thousandsGroupStyle="thousand"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='grid-x justify-content-between table-other-opl__payments__content-titles__second'>
                        <span className={`grid-x table-other-opl__payments__titles table-other-opl__payments__titles__space--mod ${typeFunctionality ? "table-other-opl__content":"small-3"}`}>Fecha</span>
                        {typeFunctionality ? 
                            <span className='grid-x table-other-opl__payments__titles table-other-opl__payments__titles__space--mod table-other-opl__content'>Operación</span>
                            : null
                        }
                        <span className={`grid-x table-other-opl__payments__titles table-other-opl__payments__titles__space--mod ${typeFunctionality ? "table-other-opl__content":"small-4"}`}>Concepto</span>
                        <span className={`grid-x table-other-opl__payments__titles table-other-opl__payments__titles__space--mod ${typeFunctionality ? "table-other-opl__content__buttons":"small-4"}`}>Valor</span>
                    </div>
                </div>
                <div ref={formRef} className='table-other-opl__payments__info'>
                    {expenses?.map((item, index) => {
                            return (
                                <div key={index} className='grid-x justify-content-between table-other-opl__payments__table'>
                                <div className={typeFunctionality ? "table-other-opl__content" : 'small-3'}>
                                    <DatePicker
                                        placeholder='Fecha'
                                        format="YYYY-MM-DD"
                                        allowClear={false}
                                        style={{ width: "90%" }}
                                        disabledDate={disabledDate}
                                        className='credit-opl__payments__date'
                                        disabled={(item?.disabled || !item.isEdit)}
                                        value={item?.date && dayjs(item?.date,"YYYY-MM-DD")}
                                        onChange={(e) => handleChange(e.format('YYYY-MM-DD'), index, "date")}
                                    />
                                </div>
                                { typeFunctionality ?
                                    <div className='table-other-opl__content'>
                                        <Select
                                            optionLabelProp="label"
                                            placeholder='Operación'
                                            disabled={(item?.disabled || !item.isEdit)}
                                            popupMatchSelectWidth={false}
                                            options={listOperation}
                                            className='expenses-opl__payments__select'
                                            popupClassName='expenses-opl__payments__select__popup'
                                            value={item?.operation?.value ? item?.operation : undefined}
                                            onChange={(e, event) => handleChange(event, index, 'operation')}
                                            suffixIcon={
                                                <div className='expenses-opl__payments__select__icon'>
                                                    <img
                                                        alt={(item?.disabled || !item.isEdit) ? "icon_disabled_arrow" : "icon_blue_arrow"}
                                                        src={(item?.disabled || !item.isEdit) ? Assets.SharedIcons.icon_disabled_arrow : Assets.SharedIcons.icon_blue_arrow}
                                                    />
                                                </div>
                                            }
                                        />
                                    </div>
                                    : null
                                }
                                <div className={typeFunctionality ? "table-other-opl__content" :'grid-x small-4'}>
                                    <Input
                                        placeholder='Concepto'
                                        disabled={(item?.disabled || !item.isEdit)}
                                        value={item?.concept}
                                        className='table-other-opl__payments__input'
                                        onChange={(e) => handleChange(e, index, "concept")}
                                        prefix={<img src={item?.disabled || !item.isEdit? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit} alt="icon_add" />}
                                    />
                                </div>
                                <div className={typeFunctionality ? "grid-x table-other-opl__content__buttons" : 'grid-x justify-content-between small-5'}>
                                    <div className='grid-x small-7 table-other-opl__payments__table__content-inputs'>
                                        <NumericFormat
                                            type='text'
                                            prefix={"$ "}
                                            decimalScale={2}
                                            placeholder='$ 0'
                                            value={item?.price}
                                            thousandSeparator=","
                                            className='input-price'
                                            fixedDecimalScale={true}
                                            thousandsGroupStyle="thousand"
                                            disabled={(item?.disabled || !item.isEdit)}
                                            onValueChange={({ floatValue }) => handleChange(floatValue, index, 'price')}
                                        />
                                        <div className='table-other-opl__payments__table__content-icon-price'>
                                            <img
                                                alt="icon_add"
                                                src={item?.disabled || !item.isEdit? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                                            />
                                        </div>
                                    </div>
                                    <div className='grid-x small-2 justify-content-end'>
                                         <button
                                            onClick={() => {
                                                if (item?.id) {
                                                    handleChange(!item?.isEdit, index, 'isEdit')
                                                    if (item?.isEdit) handleEdit(item, index)
                                                } else handleCreate(item, index)
                                            }}
                                            className='credit-opl__payments__button-delete'
                                            disabled={item?.disabled}
                                        >
                                            <img
                                                className='table-other-opl__payments__button-delete__icon'
                                                alt={item?.disabled ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                                                src={item?.disabled ? Assets.SharedIcons.icon_edit_disabled : (item?.isEdit ? Assets.SharedIcons.icon_save : Assets.SharedIcons.icon_edit)}
                                            />
                                        </button>
                                    </div>
                                    <div className='grid-x small-2 justify-content-end'>
                                        <button
                                            onClick={() => removeDataExpenses(item, index)}
                                            className='table-other-opl__payments__button-delete'
                                            disabled={item?.disabled}
                                        >
                                            <img
                                                className='table-other-opl__payments__button-delete__icon'
                                                alt={item?.disabled ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                                                src={item?.disabled ? Assets.SharedIcons.icon_deleted_disabled : Assets.SharedIcons.icon_deleted_active}
                                            />
                                        </button>
                                    </div>
                                </div>
                                </div>
                            )
                        }
                    )}
                </div>
                <div className='grid-x'>
                    <div className='table-other-opl__payments__line'></div>
                    <button
                        onClick={() => addExpenses()}
                        className='grid-x align-middle table-other-opl__payments__type'
                    >
                        <img
                            src={Assets.SharedIcons.icon_income_blue}
                            alt='icon_income_blue'
                        />
                        <span className='table-other-opl__payments__type__name'>Agregar créditos</span>
                    </button>
                </div>
            </div>
        </div>
    )
}
