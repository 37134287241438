
const QuotationOPLEndPoints = {
    GET_ALL_OPL: `/api/oplAdjustment/getAllHeadQuarterOpl`,
    GET_OPL_EXPENSES: `/api/oplAdjustment/getAllCashExpenseOpl/:id/:date`,
    GET_OPL_CREDIT: `/api/oplAdjustment/getAllCreditOpl/:id/:date`,
    GET_OPL_OTHERS: `/api/oplAdjustment/getAllOthersOpl/:id/:date`,
    GET_OPL_CREDIT_CONCEPT_TYPES:`/api/oplAdjustment/getTypeConceptCreditOpl`,
    GET_OPL_CREDIT_EMPLOYEES: `/api/oplAdjustment/getEmployeeCreditOpl`,
    CREATE_OPL_CREDIT: `/api/oplAdjustment/createCreditOpl`,
    CREATE_OPL_OTHERS: `/api/oplAdjustment/createOthersOpl`,
    CREATE_OPL: `/api/oplAdjustment/createCashExpenseOpl`,
    UPDATE_OPL_EXPENSES: `/api/oplAdjustment/updateCashExpenseOpl/:id`,
    DELETE_OPL_EXPENSES: `/api/oplAdjustment/deleteCashExpenseOpl`,
    GET_GENERAL_ADJUSTMENT_OPL: `/api/oplAdjustment/getTotalCashExpenseOpl/:id/:date`,
    GENERAL_ADJUSTMENT_OPL_CLOSING: `/api/oplAdjustment/reportClosingOperationOpl/create/:idHeadquarter/:date`,
    GET_TOTALIZER_OF_GENERAL_ADJUSTMENT_OPL: `/api/oplAdjustment/reportClosingOperationOpl/getAll/:idHeadquarter/:date`,
    GET_MONTHS_CLOSING_GENERAL_ADJUSTMENT_OPL: `/api/oplAdjustment/currentMonthEndClosingOpl/:idHeadquarter/:date`,
    GET_TYPE_CONCEPT: `/api/oplAdjustment/getTypeConceptCashExpenseOpl`,
    CREATE_OPL_ClOSING_WE_OWE: `/api/oplAdjustment/createClosingCashRegisterOpl/weOwe`,
    CREATE_OPL_ClOSING_OWE_US: `/api/oplAdjustment/createClosingCashRegisterOpl/theyOweUs`,
    DELETE_CASH_CLOSING_OPL: `/api/oplAdjustment/deleteClosingCashRegisterOpl`,
    EDIT_CASH_CLOSING_OPL: `/api/oplAdjustment/updateClosingCashRegisterOpl/:id`,

    GET_ALL_PROVISIONS_OPL: `/api/oplAdjustment/getAllProvisionOPL/:idHeadQuarter`,
    CREATE_PROVISION_OPL: `/api/oplAdjustment/createProvisionOpl`,
    UPDATE_PROVISION_OPL: `/api/oplAdjustment/updateProvisionOpl/:id`,
    DELETE_PROVISION_OPL: `/api/oplAdjustment/deleteProvisionOpl`,
}

export default QuotationOPLEndPoints